/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import Tooltip from "rc-tooltip";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CSVLink } from "react-csv";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DAYS_EXPIRE_BID } from "src/actions/commonActionsConf";
import { writeLog } from "src/actions/utilsActions";
import { AuctionQuestionWithSelected } from "src/api/ExtendedTypes";
import {
  InfluencerInManageAuctionData,
  ListSummary,
  ManageAuctionData,
  ManageAuctionState,
  ShareNotesSummary,
} from "src/api/types";
import {
  SocialIcon,
  SocialIconsT,
  SocialNetworkEnum,
} from "src/helpers/SocialNetworksHelper";
import { HumanzActionsMenu } from "src/ui/humanz/HumanzActionsMenu";
import HumanzImageIcon from "src/ui/humanz/HumanzImageIcon";
import HumanzPage from "src/ui/humanz/HumanzPage";
import { SearchBar } from "src/ui/humanz/SearchBar";
import EditTasksDialog from "../../dialogs/EditTasksDialog/EditTasksDialog";
import { InfoToolTip } from "../../general_components/InfoToolTip";
import { PageBlocker } from "../../general_components/PageBlocker";
import SearchFilter from "../../search_components/SearchFilter";
import CampaignCouponsSide from "../../side_menus/CampaignCouponsSide";
import CampaignLinksSide from "../../side_menus/CampaignLinksSide";
import InviteToCampaign from "../../side_menus/InviteToCampaign";
import SendMultiMessageSide from "../../side_menus/SendMultiMessageSide";
import {
  HoursToAlertSalesEnds,
  POST_TYPE_TO_SOCIAL,
  campaignHoursLeftMesg,
  formatBigNumbers,
} from "../../utilities/general";
import { PhasesDialog } from "../CampaignPhases/PhasesDialog";
import { AttachLinksDialogWithManageData } from "../dialogs/AttachLinksDialog/AttachLinksDialogWithManageData";
import CampaignPreviewDialog from "../dialogs/CampaignPreviewDialog";
import RateInfluencerDialog from "../dialogs/RateInfluencerDialog";
import CampaignReminderMenu from "../menus/CampaignReminderMenu";
import AttachTasks from "./AttachTasks/AttachTasks";
import { CampaignInfluencerTags } from "./CampaignInfluencerTags";
import { CampaignManagerApis } from "./CampaignManagerApis";
import { DownloadExcelButton } from "./CampaignManagerCsvHelper";
import CampaignManagerFeedbackModal from "./CampaignManagerFeedbackModal";
import { CampaignManagerFilters } from "./CampaignManagerFilters";
import { CampaignManagerInfluencers } from "./CampaignManagerInfluencers";
import {
  CampaignFilter,
  CampaignManagerProps,
  Columns,
  SingleInfoBox,
  campaignFilters,
  manageColumns,
  starterBoxes,
} from "./CampaignManagerInterfaces";
import CampaignMonthSelector from "./CampaignMonthSelector";
import CampaignPaymentDialog from "./CampaignPaymentDialog";
import CampaignTrashDialog from "./CampaignTrashDialog";
import PlanNextMonthDialog from "./PlanNextMonthDialog";
import ResponseToInfluencerBidDialog from "./ResponseToInfluencerBidDialog";
import CampaignTargetsModalRenderer from "./Targets/CampaignTargetsModalRenderer";

const TIMES_TO_AUTO_REFRESH_BOARD = 360;
const TIME_TO_UPDATE_BOARD = 10000;
const MAX_INFLUENCERS_TASKS = 50;

const CampaignManager = (props: CampaignManagerProps) => {
  // Vars
  const boardId = props.id;
  const { share, board } = props;
  let RefreshTimes = 0;
  let refreshBoardTimer: any = null;
  const editTasksCallback: any = null;

  // States
  const [csvData, setCsvData] = useState(null);
  const [filteredResult, setFilteredResult] = useState(null);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [filters, setFilters] = useState<CampaignFilter[]>(campaignFilters);
  const [influencersSelected, setInfluencersSelected] = useState([]);
  const [infoBoxes, setInfoBoxes] = useState<SingleInfoBox[]>([]);
  const [list, setList] = useState(null);
  const [newSort, setNewSort] = useState(false);
  const [sortKey, setSortKey] = useState(null);
  const [editTasksData, setEditTasksData] = useState(null);
  const [questions, setQuestions] = useState<AuctionQuestionWithSelected[]>([]);
  const [search, setSearch] = useState(null);
  const [isAttachTasks, setAttachTasks] = useState(false);
  const [showPlanNextMonthDialog, setShowPlanNextMonthDialog] = useState(false);
  const [negotiate, setNegotiate] = useState<
    InfluencerInManageAuctionData | null | any
  >({});
  const [selectedColumn, setSelectedColumn] = useState<Columns>(
    Columns.publish,
  );

  const [selectedSocial, setSelectedSocial] = useState(null);
  const [lastMonth, setLastMonth] = useState(false);
  const [isCurrentMonth, setIsCurrentMonth] = useState(false);
  const [columns, setColumns] = useState<ManageAuctionState[]>([]);
  const [openProposals, setOpenProposals] = useState(null);
  const [waitingNotes, setWaitingNotes] = useState<ShareNotesSummary>({});
  const [sort, setSort] = useState<any>({
    date: -1,
    followers: -1,
    name: -1,
    price: -1,
  });
  const [showPhases, setShowPhases]: any = useState(false);
  const [inviteToCampaignOpen, setInviteToCampaignOpen] = useState<any>(null);
  const [openCampaignLinks, setOpenCampaignLinks] = useState(false);
  const [openTrackingCoupons, setOpenTrackingCoupons] = useState(false);
  const [openSendMultiMessages, setOpenSendMultiMessages] = useState<any>(null);
  const [openAttachLinks, setOpenAttachLinks] = useState<any>(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [openTrashDialog, setOpenTrashDialog] = useState<any>();
  const [showPaymentsDialog, setShowPaymentsDialog] = useState<any>();
  const [selectedBid, setSelectedBid] =
    useState<InfluencerInManageAuctionData>();
  const [showPageBlocker, setShowPageBlocker] = useState(false);
  const [selectedInfluencerForTargets, setSelectedInfluencerForTargets] =
    useState<InfluencerInManageAuctionData>();
  const [targetsEditMode, setTargetsEditMode] = useState(false);
  const sideSearchRef = useRef<any>();
  const reminderMenu: any = useRef<any>();
  const influencerRating = useRef<any>();
  const firstRun = useRef<any>(true);
  const campaignHoursLeft = moment(board.sales_end_time).diff(
    moment(moment().utc().toISOString()),
    "hours",
  );

  const filterByStars = (
    stars: any,
    influencer: InfluencerInManageAuctionData,
  ) => {
    if (!stars) return true;
    if (stars <= influencer.avg_review) {
      return true;
    }
    return false;
  };

  const filterInfluencer = useCallback(
    (influencer: InfluencerInManageAuctionData) => {
      if (!influencer) {
        return false;
      }
      if (
        filteredResult &&
        !filteredResult.includes(influencer.influencer_id)
      ) {
        return false;
      }

      let hasTags = true;
      if (tagsFilter?.length > 0) {
        hasTags = false;
        const influTags =
          board && board.tags
            ? board.tags.filter((x: any) =>
                x.influencers.includes(influencer.influencer_id),
              )
            : [];
        if (!influTags || influTags.length === 0) return false;
        for (const tag of influTags) {
          if (tagsFilter?.includes(tag.label)) {
            hasTags = true;
            break;
          }
        }
      }

      if (!hasTags) return false;

      if (list) {
        return list.influencer_ids.includes(influencer.influencer_id);
      }

      return true;
    },
    [filteredResult, tagsFilter, list, board],
  );

  const payToInfluencer = () => {
    const { influencer } = showPaymentsDialog;
    setShowPageBlocker(true);

    influencer.loadingNegotiate = true;
    props.payInfluencer(
      boardId,
      influencer.influencer_id,
      showPaymentsDialog.column,
      () => {
        influencer.loadingNegotiate = false;
        setShowPageBlocker(false);
      },
    );
    setShowPaymentsDialog(null);
  };

  const sortInfluencers = (
    influencers: Array<InfluencerInManageAuctionData>,
    key: null | "price" | "name" | "followers" | "date",
  ) => {
    if (!key) return;
    let multiple = sort[key];
    if (newSort) {
      multiple = sort[key] * -1;
    }
    let sortedInfluencers: Array<InfluencerInManageAuctionData> = [];
    if (influencers && influencers.length > 0) {
      sortedInfluencers = influencers?.sort((a, b) => {
        if (!a || !b) {
          return 0;
        }
        let diff = 0;
        switch (key) {
          case "price":
            const aPrice = a.bid_status
              ? a.bid_status.bid
              : a.invite_status
              ? a.invite_status.price_offered
              : 0;
            const bPrice = b.bid_status
              ? b.bid_status.bid
              : b.invite_status
              ? b.invite_status.price_offered
              : 0;
            diff = aPrice - bPrice;
            break;
          case "name":
            diff = a.name.localeCompare(b.name);
            break;
          case "followers":
            const aSocial = a.social_network_data;
            const aFollowers = Math.max(
              aSocial.instagram ? aSocial.instagram.followers : 0,
              aSocial.twitter ? aSocial.twitter.followers : 0,
              aSocial.youtube ? aSocial.youtube.followers : 0,
            );
            const bSocial = b.social_network_data;
            const bFollowers = Math.max(
              bSocial.instagram ? bSocial.instagram.followers : 0,
              bSocial.twitter ? bSocial.twitter.followers : 0,
              bSocial.youtube ? bSocial.youtube.followers : 0,
            );
            diff = aFollowers - bFollowers;
            break;
          case "date":
            // need to think cause this is the default sorting.. changed from column to column?
            diff = 0;
            break;
          default:
            break;
        }

        return multiple * diff;
      });
      if (newSort) {
        const tmpSort = { ...sort };
        if (tmpSort[key] === 0) {
          tmpSort[key] = -1;
        } else {
          tmpSort[key] *= -1;
        }
        // to prevent resorting everytime clicked
        setNewSort(false);
        setSort(tmpSort);
      }
    }

    // eslint-disable-next-line consistent-return
    return sortedInfluencers;
  };

  const filterBySearch = useCallback(
    (_search: string, influencer: InfluencerInManageAuctionData) => {
      // Data filters
      if (!filterInfluencer(influencer)) return false;

      if (!_search) return true;

      // Search name/tag filters
      const searchKey = _search?.replace("tag:", "")?.trim();
      if (influencer.tags) {
        for (const tag of influencer.tags) {
          if (tag.toLowerCase().indexOf(searchKey) > -1) {
            return true;
          }
        }
      }

      if (search.includes("tag:")) return false;

      if (!search || search === "") {
        return true;
      }
      if (search === "need_payment") {
        return influencer.payment_status !== "paid";
      }
      if (
        influencer.name &&
        influencer.name.toLowerCase().indexOf(search) > -1
      ) {
        return true;
      }
      const social: any = influencer.social_network_data;
      for (const socialNetwork of ["instagram", "twitter", "youtube"]) {
        const currentNetworkData = social[socialNetwork];
        if (
          currentNetworkData &&
          currentNetworkData.username &&
          currentNetworkData.username.toLowerCase().indexOf(search) > -1
        ) {
          return true;
        }
      }
      return false;
    },
    [filterInfluencer, search],
  );

  const getConcatColumn = (data: any, columnId: number, filter?: any) => {
    const column: ManageAuctionState = {
      notifications: 0,
      influencers: [],
      count: 0,
    };
    const columndata = data ? data.states[manageColumns[columnId].name] : null;
    if (column && columndata) {
      column.influencers = columndata.influencers;
    }
    if (columnId === Columns.invite) {
      column.influencers = column.influencers.sort(function (a: any, b: any) {
        const tasksA = a.tasks
          ? a.tasks.filter(
              (x: any) => !x.completed && !(x.proposal && x.proposal.approved),
            ).length > 0
          : false;
        const tasksB = b.tasks
          ? b.tasks.filter(
              (x: any) => !x.completed && !(x.proposal && x.proposal.approved),
            ).length > 0
          : false;

        if (tasksA === tasksB) {
          if (a.name > b.name) {
            return 1;
          }
          return -1;
        }
        if (tasksB > tasksA) {
          return 1;
        }
        return -1;
      });
    } else if (columnId === Columns.publish) {
      column.influencers = column.influencers.concat(
        data && data.states[manageColumns[Columns.approval].name]
          ? data.states[manageColumns[Columns.approval].name].influencers
          : [],
      );
      column.influencers = column.influencers.sort(function (a: any, b: any) {
        const tasksA = a.tasks
          ? a.tasks.filter(
              (x: any) =>
                !x.completed ||
                (x.post_type === "ig_story" &&
                  (!x.engagemnts || x.engagemnts.views === null)),
            ).length > 0
          : false;
        const tasksB = b.tasks
          ? b.tasks.filter(
              (x: any) =>
                !x.completed ||
                (x.post_type === "ig_story" &&
                  (!x.engagemnts || x.engagemnts.views === null)),
            ).length > 0
          : false;
        if (tasksA === tasksB) {
          if (a.name > b.name) {
            return 1;
          }
          return -1;
        }
        if (tasksB > tasksA) {
          return 1;
        }
        return -1;

        // return b - a;
      });
    }
    // apply sorting..
    if (sortKey && column.influencers && column.influencers.length > 0) {
      column.influencers = sortInfluencers(column.influencers, sortKey);
    }
    if (column.influencers.length > 0 && filter) {
      const avgReview = filters?.find(
        (f: any) => f.name === "avg_review",
      ).selected;
      column.influencers = column.influencers.filter((x: any) =>
        filterBySearch(search?.trim(), x),
      );
      column.influencers = column.influencers.filter((x: any) =>
        filterByStars(avgReview, x),
      );
    }
    column.count = column.influencers.length;

    // sort influencers by starts next month - to the end
    column.influencers = column.influencers.sort((a: any, b: any) => {
      const startDateA = new Date(a?.start_from);
      const startDateB = new Date(b?.start_from);
      return startDateA > startDateB ? 1 : -1;
    });
    return column;
  };

  const selectInfluencer = useCallback(
    (ids: any) => {
      let newInfluencersSelected: number[] = [...influencersSelected];
      if (ids.length > 1 && ids.length === influencersSelected.length) {
        newInfluencersSelected = [];
      } else if (ids.length > 1) {
        newInfluencersSelected = ids;
      } else {
        ids.forEach((id: any) => {
          newInfluencersSelected.addOrRemove(id);
        });
      }
      setInfluencersSelected(newInfluencersSelected);
    },
    [influencersSelected],
  );

  const openAddToList = (column: any) => {
    const influencers: any = [];
    column
      .filter((x: InfluencerInManageAuctionData) =>
        influencersSelected.includes(x.influencer_id),
      )
      .forEach((i: InfluencerInManageAuctionData) => {
        influencers.push({
          id: i.influencer_id.toString(),
          picture_url: i.picture_url,
          name: i.name,
        });
      });
    props.openAddToList(influencers);
  };

  const editTasks = (influencer_id: number, tasks: any, callback: any) => {
    props.editTasksOnCampaign(
      board.board_id,
      influencer_id,
      tasks,
      !lastMonth && !isCurrentMonth,
      (data: any) => {
        callback(data);
        if (editTasksCallback) editTasksCallback();
      },
    );
  };

  const sendTaskInviteResponse = (
    influencerId: number,
    taskId: number,
    response_type: any,
    price: number,
    callback: any,
  ) => {
    const response = { response_type, counter_offer: price };
    props.responseForInfluencerTaskCounterOffer(
      influencerId,
      taskId,
      response,
      callback,
    );
  };

  const closeCampaignLinks = () => {
    if (!openCampaignLinks) return;

    const element = document.getElementById("campaign_links_menu");
    if (element) {
      element.classList.remove("fadeInRight");
      element.classList.add("fadeOutRight");
    }
    window.setTimeout(() => setOpenCampaignLinks(false), 500);
  };

  const handleCloseLinksDialog = (id: number) => {
    setOpenAttachLinks(null);
    const influencer = board.states.approval.influencers.find(
      (x: InfluencerInManageAuctionData) => x.influencer_id === id,
    );

    if (influencer && !influencer.review && !props.share) {
      // add review if influencer doesnt have a review reguardless if tasks are completed.
      // const notCompleted = influencer.tasks_details.tasks.find(x => !x.completed);
      // if (!notCompleted) {
      influencerRating.current.show(influencer, boardId);
      // }
    }

    handleRefreshBoard(true, true);
  };

  const openChatsAndContent = useCallback(
    (influencer: InfluencerInManageAuctionData) => {
      if (!share) {
        props.closeChatDialog(false);
      }
      setOpenProposals(influencer.influencer_id);
    },
    [props, share],
  );

  const updateColumns = useCallback(() => {
    setColumns([
      getConcatColumn(board, Columns.bids, true),
      getConcatColumn(board, Columns.invite, true),
      getConcatColumn(board, Columns.approval, true),
      getConcatColumn(board, Columns.publish, true),
      getConcatColumn(board, Columns.trash, true),
    ]);
  }, [
    sort,
    filters,
    sortKey,
    newSort,
    search,
    board,
    filteredResult,
    tagsFilter,
  ]);

  const currencyLabel = useCallback(() => {
    if (!board) {
      return null;
    }
    return window.translate("", `money_symbol_${board?.summary?.currency}`);
  }, [board]);

  const toggleFilterMenu = useCallback(() => {
    sideSearchRef?.current?.toggleFilterMenu(() => {
      props.closeInfluencerSegmentations();
    });
  }, [props]);

  const resultsCallback = (filterResult: any) => {
    setSearchLoader(false);
    setFilteredResult(filterResult.data);
  };

  const setLoadingData = (state = true) => {
    setSearchLoader(state);
  };

  const searchInfluencers = () => {
    setLoadingData();
    const result = sideSearchRef?.current?.getSearchQuery(false, false);
    if (questions) {
      const resQuestions = questions.filter(
        (x: AuctionQuestionWithSelected) => !!x.selected,
      );
      const answers: any = [];
      resQuestions.forEach((q: AuctionQuestionWithSelected) => {
        answers.push({ question_id: q.id, answer: q.selected });
      });
      result.query.questions = answers;
    }

    props.searchOnBoard(boardId, result.query, resultsCallback);
  };

  const searchLists = (e: any) => {
    setLoadingData();
    setList(
      e ? props?.lists?.find((l: ListSummary) => l.id === e.value) : null,
    );
  };

  const createInfoBoxes = useCallback(() => {
    const boxes = [...starterBoxes];

    const socialNetworks = board?.summary?.social_networks;
    const influencersInCampaign = selectedSocial
      ? formatBigNumbers(
          socialNetworks[selectedSocial as keyof typeof socialNetworks]
            ?.influencers,
        )
      : board?.states?.approval?.count;

    // Influencers
    boxes[0].count = `${formatBigNumbers(influencersInCampaign)}`;

    boxes[0].info = `Active influencers in the campaign`;

    const totalTasks = board?.states?.approval?.influencers?.reduce(
      (count, obj) =>
        count +
        (obj.tasks_details?.tasks?.filter(
          (x) =>
            (selectedSocial
              ? POST_TYPE_TO_SOCIAL[x.post_type] === selectedSocial
              : true) && !x.subtask_of,
        ).length || 0),
      0,
    );
    const completedTasks = board?.states?.approval?.influencers?.reduce(
      (count, obj) =>
        count +
        (obj.tasks_details?.tasks?.filter(
          (x) =>
            (selectedSocial
              ? POST_TYPE_TO_SOCIAL[x.post_type] === selectedSocial
              : true) &&
            x.completed &&
            !x.subtask_of,
        ).length || 0),
      0,
    );

    // Tasks box
    boxes[1].count = `${formatBigNumbers(
      completedTasks || 0,
    )}/${formatBigNumbers(totalTasks || 0)}`;
    boxes[1].info =
      "Completed tasks / The amount of tasks of approved influencers";

    setInfoBoxes(boxes);
  }, [board, selectedSocial]);

  const clearRefreshBoardInterval = useCallback(() => {
    if (refreshBoardTimer) {
      clearInterval(refreshBoardTimer);

      refreshBoardTimer = null;

      RefreshTimes = 0;
    }
  }, []);

  // Effects

  // Update info boxes on selected social
  useEffect(() => {
    createInfoBoxes();
  }, [selectedSocial, createInfoBoxes]);

  // Update influencers on search
  useEffect(() => {
    updateColumns();
  }, [search, filters, sort, newSort, sortKey]);

  // Mark current column as read
  useEffect(() => {
    props.markSeenColumnNotif(
      boardId,
      manageColumns[selectedColumn].name,
      () => {
        board.states[
          manageColumns[selectedColumn].name as keyof typeof Columns
        ].notifications = 0;
        props.updateBoardState(board);
      },
    );
  }, [selectedColumn]);

  useEffect(() => {
    updateColumns();
  }, [filteredResult]);

  const handleRefreshBoard = useCallback(
    (execute: any, resetEtag?: boolean) => {
      const month = props?.month
        ? moment(props?.month).format("YYYY-MM-DD")
        : null;
      if (props.share) return;
      if (execute) {
        props.updateBoardDetails(
          props.id,
          resetEtag ? null : board.etag,
          month,
          () => {
            setLoadingData(false);
          },
        );
      }
      if (refreshBoardTimer) return;
      if (RefreshTimes >= TIMES_TO_AUTO_REFRESH_BOARD) return;

      RefreshTimes = 0;

      refreshBoardTimer = setInterval(() => {
        RefreshTimes += 1;
        const boardPage = window.location.href.includes(
          `campaign/${boardId}/manage`,
        );
        if (boardPage) {
          if (
            RefreshTimes >= TIMES_TO_AUTO_REFRESH_BOARD &&
            refreshBoardTimer
          ) {
            clearRefreshBoardInterval();
            toast.info(
              <div>
                Some changes might happened{" "}
                <Button variant="link" onClick={() => window.location.reload()}>
                  Reload Page
                </Button>
              </div>,
              { position: "bottom-right", autoClose: false },
            );
          } else if (openAttachLinks || editTasksData) {
            console.log("nothing");
            // Nothing
          } else {
            props.updateBoardDetails(props.id, board.etag, month);
            props.getAuctionGoals(props.id, undefined, month);
          }
        }
      }, TIME_TO_UPDATE_BOARD);
    },
    [props.id],
  );

  const removeReloadPageToast = () => {
    try {
      toast.dismiss();
    } catch (e) {
      // nothing
    }
  };

  // Init
  useEffect(() => {
    let i: number;
    if (props.share) {
      props.getShareBoardDetails(boardId, () => {
        setSelectedColumn(Columns.bids);
      });
    } else if (board && board.states) {
      updateColumns();
      // Has Sharing enabled
      if (board.share_guid) {
        props.getShareNotesSummary(
          board.share_guid,
          (shareNotesSummaryData: ShareNotesSummary) => {
            if (shareNotesSummaryData?.type_and_notes_summary) {
              const tmpWaitingNotes: any = {};
              shareNotesSummaryData.type_and_notes_summary.forEach((note) => {
                if (note?.notes_summary) {
                  const ids = note.notes_summary
                    .filter((x) => !x.seen)
                    .map((x) => x.item_id);
                  if (ids.length) {
                    const infId = parseInt(
                      note.note_type.replace("campaign_content_approval_", ""),
                      10,
                    );
                    tmpWaitingNotes[infId] = ids;
                  }
                }
              });
              setWaitingNotes(tmpWaitingNotes);
            }
          },
        );
      }
      if (firstRun.current) {
        // check if board.campaign_end_time month is equal or less than today's month
        // const today = new Date();
        const choosenMonth = moment(board?.current_manage_month);
        const currentYear = choosenMonth.year();
        const currentMonth = choosenMonth.month();

        // Get the year and month of campaign_end_time
        const endYear = new Date(board.campaign_end_time).getFullYear();
        const endMonth = new Date(board.campaign_end_time).getMonth();

        // Check if the campaign end year is less than or equal to the current year
        // and if the campaign end month is less than or equal to the current month
        if (
          endYear < currentYear ||
          (endYear === currentYear && endMonth <= currentMonth)
        ) {
          setLastMonth(true);
        } else {
          setLastMonth(false);
        }
        setIsCurrentMonth(
          moment(board?.current_manage_month).isSame(moment(), "month"),
        );

        // Is Survery type
        if (board.summary && board?.summary?.campaign_type === "survey") {
          setSelectedColumn(Columns.publish);
          props.markSeenColumnNotif(boardId, "publish", () => {
            board.states.approval.notifications = 0;
            props.updateBoardState(board);
          });
          // Normal or any other type of campaign
        } else {
          for (i = manageColumns.length - 2; i >= 0; i--) {
            const stat =
              board.states[manageColumns[i].name as keyof typeof Columns];
            if (stat) {
              try {
                if (
                  i === 2 &&
                  stat.influencers.find(
                    (x: any) => !!x.tasks.find((y: any) => y.completed),
                  )
                ) {
                  i = stat.influencers.find(
                    (x: any) =>
                      !!x.tasks.find(
                        (y: any) =>
                          !y.completed &&
                          y.proposal &&
                          y.proposal.approved === null,
                      ),
                  )
                    ? i
                    : i + 1;
                }
                setSelectedColumn(i);
              } catch (e) {
                /* empty */
              }
              break;
            }
          }
        }

        firstRun.current = false;
      }
      // Has Board questions
      if (board.auction_questions) {
        board.auction_questions.forEach((q: any) => {
          if (q.question_type === "select") {
            // eslint-disable-next-line no-param-reassign
            q.selected = "";
            // eslint-disable-next-line no-param-reassign
            q.options = q.options
              .filter((o: any) => o)
              .map((x: any) => {
                return { value: x, label: x };
              });
          } else if (q.question_type === "open") {
            // eslint-disable-next-line no-param-reassign
            q.selected = "";
          }
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setQuestions(board.auction_questions);
      }
    }
  }, [board]);

  useEffect(() => {
    handleRefreshBoard(false);
    window.addEventListener("focus", handleRefreshBoard, false);
    window.addEventListener("blur", clearRefreshBoardInterval, false);

    // Unset stuff on destroy
    return () => {
      removeReloadPageToast();
      clearRefreshBoardInterval();
      // remove board month
      window.removeEventListener("focus", handleRefreshBoard, false);
      window.removeEventListener("blur", clearRefreshBoardInterval, false);
    };
  }, [handleRefreshBoard, clearRefreshBoardInterval]);

  const lastAvailableMonth = useMemo(() => {
    if (board.one_month_campaign || board.available_months?.length < 2)
      return true;
    return board?.current_manage_month === board?.available_months?.[0];
  }, [
    board.one_month_campaign,
    board.available_months,
    board.current_manage_month,
  ]);

  const toggleInviteMenu = (influencer: InfluencerInManageAuctionData) => {
    if (!influencer) {
      const element = document.getElementById("invite-to-campaign");
      element.classList.remove("fadeInRight");
      element.classList.add("fadeOutRight");
      window.setTimeout(() => setInviteToCampaignOpen(false), 500);
    } else {
      props.closeInfluencerSegmentations();
      setInviteToCampaignOpen(influencer);
    }
  };

  const sendToTrash = useCallback(
    (influencer: InfluencerInManageAuctionData, showDialog: boolean) => {
      if (showDialog) {
        setOpenTrashDialog(influencer);
      } else {
        setOpenTrashDialog(null);
        props.sendToTrash(
          boardId,
          influencer.influencer_id,
          manageColumns[selectedColumn].name as keyof typeof Columns,
          () => {
            if (selectedColumn === Columns.approval && !influencer.review) {
              influencerRating.current.show(influencer, boardId);
            }
            updateColumns();
            handleRefreshBoard(true);
          },
        );
      }
    },

    [boardId, props, selectedColumn, updateColumns],
  );

  const openTargetsModal = (
    influencer: InfluencerInManageAuctionData,
    _targetsEditMode?: boolean,
  ) => {
    setSelectedInfluencerForTargets(influencer);
    setTargetsEditMode(_targetsEditMode);
  };

  // Internal Components

  const SocialNetworksTabs = () => {
    const socialButtons = [
      { name: "instagram", class: SocialIconsT.instagram },
      { name: "tiktok", class: SocialIconsT.tiktok },
      { name: "twitter", class: SocialIconsT.twitter },
      { name: "youtube", class: SocialIconsT.youtube },
    ];
    return (
      <>
        {socialButtons.map((social: { name: string; class: string }) => {
          return (
            <SocialIcon
              className={selectedSocial === social.name ? "active" : ""}
              onClick={() => {
                setSelectedSocial(
                  social.name === selectedSocial ? null : social.name,
                );
              }}
              social={social.name as SocialNetworkEnum}
              key={social.name}
            />
          );
        })}
      </>
    );
  };

  // Renderers

  const renderSingleInfoBox = (box: SingleInfoBox) => {
    return (
      <div className="single-info-box" key={box.title}>
        {box.info ? (
          <InfoToolTip overlay={box.info}>
            <div className="single-info-box-title">{box.title}</div>
          </InfoToolTip>
        ) : (
          <div className="single-info-box-title">{box.title}</div>
        )}
        <div className="single-info-box-count">{box.count}</div>
      </div>
    );
  };

  const renderCampaignInfoBoxes = (boxes: Array<SingleInfoBox>) => {
    return boxes.map((box: SingleInfoBox) => {
      return renderSingleInfoBox(box);
    });
  };

  const tasksSocialNetworks = useMemo(() => {
    const res: any[] = [];

    board?.states?.approval?.influencers?.forEach((inf, index) => {
      inf?.tasks_details?.tasks?.forEach((task) => {
        const existingObjIndex = res.findIndex(
          (obj) => obj.value === task.post_type,
        );
        if (existingObjIndex === -1 && index < MAX_INFLUENCERS_TASKS) {
          switch (task.post_type) {
            case "ig_post":
              res.push({
                value: "ig_post",
                label: "Instagram Post",
                icon: "fa fa-instagram",
              });
              break;
            case "ig_story":
              res.push({
                value: "ig_story",
                label: "Instagram Story",
                icon: "fa moi-stories",
              });
              break;
            case "yt_video":
              res.push({
                value: "yt_video",
                label: "YouTube Video",
                icon: "fa fa-youtube-play",
              });
              break;
            case "tk_video":
              res.push({
                value: "tk_video",
                label: "Tiktok Video",
                icon: "moi-tik-tok",
              });
              break;
            default:
              break;
          }
        }
      });
    });
    return res;
  }, [board.states.approval]);

  const renderCampaignInfo = () => {
    const handleOpenAttachTasks = () => {
      setAttachTasks(true);
      writeLog({
        message: "handleOpenAttachTasks",
        event_name: "website_open_attach_tasks_search",
        log_level: "info",
      });
    };
    return (
      <>
        <Flex mb={3} justifyContent="space-between" alignItems={"center"}>
          <Box>
            <h2 style={{ marginTop: "auto" }} className="blurable">
              {board?.summary?.pixel_enabled ? (
                <i className="fa-solid fa-badge-dollar sales-icon" />
              ) : null}{" "}
              {board?.summary?.name}
            </h2>
          </Box>
          <div>
            {campaignHoursLeft <= HoursToAlertSalesEnds &&
              board?.summary?.pixel_enabled && (
                <Container
                  alignSelf={"center"}
                  w="auto"
                  marginInlineEnd="initial"
                  display="flex"
                  cursor="default"
                  className="alert-soft"
                >
                  <Box color="red.400">
                    <i className="far fa-exclamation-triangle" />
                  </Box>
                  <Text mx="2" color="red.400">
                    {campaignHoursLeftMesg(campaignHoursLeft)}
                  </Text>
                  <Box
                    cursor="pointer"
                    onClick={() =>
                      props.navigate(`/edit-campaign/${board.board_id}`, {
                        state: { tab: "sales_tab" },
                      })
                    }
                  >
                    <i className="far fa-external-link" />
                  </Box>
                </Container>
              )}
          </div>
        </Flex>
        <div
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <div className="flex">
            <div className="campaign-image">
              <HumanzImageIcon image={board?.summary?.image} />
            </div>
            <div className="campaign-social-networks">
              <SocialNetworksTabs />
            </div>
            <div className="campaign-info-boxes">
              {renderCampaignInfoBoxes(infoBoxes)}
            </div>
          </div>
          {board.states.approval?.influencers &&
            tasksSocialNetworks &&
            tasksSocialNetworks.length > 0 && (
              <button
                className="attach-task-button"
                onClick={() => handleOpenAttachTasks()}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>Attach tasks to content</span>
                  <div className="attach-task-icon">
                    <i className="fa-sharp fa-solid fa-link"></i>
                  </div>
                </div>
              </button>
            )}
        </div>
      </>
    );
  };

  const changeColumn = (index: number) => {
    const x = manageColumns[index];
    const notifications =
      board.states[manageColumns[selectedColumn].name as keyof typeof Columns]
        ?.notifications;
    if (
      board?.summary?.campaign_type === "survey" &&
      (index === 0 || index === 2)
    ) {
      return;
    }
    if (notifications) props.markSeenColumnNotif(x.name, board);
    setSelectedColumn(index);
    setInfluencersSelected([]);
  };

  const renderSingleColumnButton = (
    column: keyof typeof Columns,
    disabled?: boolean,
  ) => {
    const currentColumn = manageColumns.find((c) => c.name === column);
    if (!currentColumn) return null;
    const currentColumnIndex =
      Columns[currentColumn.name as keyof typeof Columns];
    if (currentColumnIndex === null) return null;
    const active = selectedColumn === currentColumnIndex;
    const notifications = columns[currentColumnIndex]?.notifications;
    return (
      <div className={`inner-navigation-block ${active ? "active" : ""}`}>
        <Button
          size={"sm"}
          variant={active ? "solid" : "ghost"}
          isDisabled={disabled}
          onClick={() =>
            currentColumnIndex !== null
              ? changeColumn(currentColumnIndex)
              : null
          }
        >
          <span>{currentColumn.label}&nbsp;</span>
          {!disabled && (
            <Badge variant="subtle">
              {columns[currentColumnIndex]?.count ?? 0}
            </Badge>
          )}
          {notifications && !disabled ? (
            <div className="manage-column-badge">{notifications}</div>
          ) : null}
        </Button>
      </div>
    );
  };

  const renderCampaignNavigationBar = () => {
    return (
      <div className="manage-navigation-container">
        <div className="manage-navigation">
          <div
            className={`single-navigation-block diagonal ${
              [Columns.bids, Columns.invite].includes(selectedColumn)
                ? " active"
                : ""
            } ${lastAvailableMonth ? "" : "disabled"}`}
          >
            {renderSingleColumnButton("bids")}
            <div className="sep"></div>
            {renderSingleColumnButton("invite")}
          </div>
          <div
            className={`single-navigation-block diagonal ${
              Columns.approval === selectedColumn ? " active" : ""
            }`}
          >
            {renderSingleColumnButton("approval")}
          </div>
          <div
            className={`single-navigation-block diagonal ${
              Columns.publish === selectedColumn ? " active" : ""
            }`}
          >
            {renderSingleColumnButton("publish")}
          </div>
        </div>

        <div className="manage-navigation">
          <div
            className={`single-navigation-block ${
              Columns.trash === selectedColumn ? " active" : ""
            } ${lastAvailableMonth ? "" : "disabled"}`}
          >
            {renderSingleColumnButton("trash", !lastAvailableMonth)}
          </div>
        </div>
      </div>
    );
  };

  const addMultipleTasks = (influencers: InfluencerInManageAuctionData[]) => {
    setEditTasksData(influencers);
  };

  const renderCampaignActions = () => {
    return (
      <HumanzActionsMenu
        style={{ width: 120, borderRadius: 20, marginLeft: 8 }}
        buttontype="button"
        buttontext=" Actions "
        variant={"solid"}
        trigger={["hover"]}
        onClick={() => {}}
        actions={[
          board?.summary?.short_link && {
            action: () => {
              setOpenCampaignLinks(true);
            },
            text: "Influencers Campaign Links",
            icon: "fad fa-link",
          },
          {
            action: () => {
              setOpenTrackingCoupons(true);
            },
            text: "Tracking Coupons",
            icon: "fad fa-ticket",
          },
          board?.summary?.pixel_enabled && {
            action: () => {
              setShowPhases(true);
            },
            text: "Affiliation rules",
            icon: "fal fa-handshake-alt",
          },
          {
            action: () => {},
            text: `Download ${
              influencersSelected?.length ? "Selected influencers" : ""
            } as spreadsheet (Excel)`,
            icon: "fad fa-file-excel",
            children: (
              <DownloadExcelButton
                board={board}
                action={"downloadBoardCsv"}
                getConcatColumn={getConcatColumn}
                influencersSelected={influencersSelected}
                questions={questions}
                setCsvData={setCsvData}
              />
            ),
          },
          questions?.length
            ? {
                action: () => {},
                text: `Download ${
                  influencersSelected?.length ? "Selected influencers" : ""
                } questions as spreadsheet (Excel)`,
                icon: "fad fa-file-excel",
                children: (
                  <DownloadExcelButton
                    board={board}
                    action={"downloadAnswersCSV"}
                    getConcatColumn={getConcatColumn}
                    influencersSelected={influencersSelected}
                    questions={questions}
                    setCsvData={setCsvData}
                  />
                ),
              }
            : null,
          {
            action: () => {
              setOpenSendMultiMessages(true);
            },
            text: "Broadcast message",
            icon: "fad fa-envelope",
          },
          !!influencersSelected?.length &&
            columns[selectedColumn]?.influencers.length && {
              action: () => {
                openAddToList(columns[selectedColumn]?.influencers);
              },
              text: "Add to list",
              icon: "moi-add-list",
            },
          !!influencersSelected?.length &&
            columns[selectedColumn]?.influencers.length && {
              action: () => {
                addMultipleTasks(columns[selectedColumn]?.influencers);
              },
              text: "Add Tasks to selected influencers",
              icon: "fas fa-tasks",
            },
        ]}
      />
    );
  };

  const renderBidsWarnings = (campaign_end_time: string) => {
    const auctionEnded =
      campaign_end_time && new Date(campaign_end_time) <= new Date();
    let overResponseTime = false;
    let pastWeekDate;
    if (auctionEnded) {
      pastWeekDate = new Date(campaign_end_time);
      pastWeekDate.setDate(pastWeekDate.getDate() + DAYS_EXPIRE_BID);
      overResponseTime = new Date() > pastWeekDate;
    }
    if (overResponseTime) {
      return (
        <div className="warning-banner">
          <i className="far fa-exclamation-triangle" />
          Bids expired
        </div>
      );
    }
    if (auctionEnded) {
      const a = moment(new Date(), "M/D/YYYY");
      const b = moment(pastWeekDate, "M/D/YYYY");
      const diffDays = b.diff(a, "days");
      if (diffDays >= 0) {
        return (
          <div className="warning-banner">
            <i className="far fa-exclamation-triangle" />
            The bids will exprire in {diffDays} days
          </div>
        );
      }
    }
    return null;
  };

  const openAlarmDialog = useCallback(
    (influencer: InfluencerInManageAuctionData) => {
      reminderMenu.current.show(influencer);
    },
    [reminderMenu],
  );

  const renderSortCircle = (typeKey: any, label: string, text: string) => {
    const dir =
      sort[typeKey] > 0 ? (
        <i className="fal fa-chevron-up" />
      ) : (
        <i className="fal fa-chevron-down" />
      );
    return (
      <div
        onClick={() => {
          setNewSort(true);
          setSortKey(sort[typeKey] < 0 && sortKey ? null : typeKey);
        }}
      >
        <Checkbox
          style={{ pointerEvents: "none" }}
          defaultChecked={sortKey === typeKey}
        />{" "}
        <span>
          {label} {text} {sortKey === typeKey ? dir : null}
        </span>
      </div>
    );
  };

  const renderInfluencerSearch = () => {
    return (
      <div className={`search-field ${search?.length ? "active" : ""}`}>
        <SearchBar
          onSearch={(e: any) => {
            setSearch(e.target.value.toLowerCase());
          }}
        />
      </div>
    );
  };

  const renderCampaignSorting = () => {
    return (
      <HumanzActionsMenu
        buttontype="button"
        style={{
          marginLeft: 8,
          background: "white",
          borderRadius: 20,
        }}
        buttontext={
          <span>
            <i style={{ fontSize: 16 }} className="fas fa-filter" />
          </span>
        }
        variant={"small-action"}
        actions={[
          {
            children: renderSortCircle(
              "price",
              currencyLabel(),
              "Sort by price",
            ),
            icon: "fad fa-link",
          },
          {
            children: renderSortCircle("name", "A-Z", "Sort by name"),
            icon: "fad fa-link",
          },
          {
            children: renderSortCircle("followers", "", "Sort by followers"),
            icon: "fad fa-link",
          },
          {
            children: (
              <div
                className={`${
                  sideSearchRef?.current?.getActiveFilter("influencer") ||
                  sideSearchRef?.current?.getActiveFilter("audience") ||
                  sideSearchRef?.current?.getActiveFilter("content")
                    ? "active-filter"
                    : ""
                }`}
                onClick={toggleFilterMenu}
              >
                <i className="far fa-filter" /> Filters
              </div>
            ),
          },
        ]}
      />
    );
  };

  const renderCampaignNavigationAndFilters = () => {
    return (
      <>
        <div className="flex">
          {renderCampaignNavigationBar()}
          <div className="campaign-header-right">
            <div
              className="manage-navigation"
              style={{ padding: "0 15px", background: "none" }}
            >
              {influencersSelected?.length ? (
                <div
                  className="single-navigation-block"
                  style={{ marginRight: 10 }}
                >
                  <div className="gray-circle-icon">
                    <CampaignInfluencerTags
                      board={board}
                      selectedInfluencers={influencersSelected}
                      boardTags={board.tags}
                      influencerId={-1}
                      onUpdate={() => updateColumns()}
                    />
                  </div>
                </div>
              ) : null}
              <div className="single-navigation-block">
                {renderInfluencerSearch()}
              </div>
              <div className="single-navigation-block">
                {renderCampaignSorting()}
              </div>
              <div className="single-navigation-block">
                {renderCampaignActions()}
              </div>
              <div className="single-navigation-block">
                <div
                  className="checkbox"
                  style={{ padding: 0, margin: 0, marginTop: 6 }}
                >
                  <label>
                    <input
                      type="checkbox"
                      checked={
                        influencersSelected?.length &&
                        columns[selectedColumn]?.influencers.length ===
                          influencersSelected?.length
                      }
                      onChange={() =>
                        selectInfluencer(
                          columns[selectedColumn]?.influencers
                            .map(
                              (x: InfluencerInManageAuctionData) =>
                                x?.influencer_id,
                            )
                            .filter((i) => i),
                        )
                      }
                    />
                    <span className="checkbox-material">
                      <span className="check"></span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderCampaignHeader = () => {
    return (
      <div className="campaign-header">
        {renderCampaignInfo()}
        {renderCampaignNavigationAndFilters()}
      </div>
    );
  };

  const campaignMonthsSelectorMemo = useMemo(() => {
    return (
      <CampaignMonthSelector
        months={props.board?.available_months}
        allMonths={true}
        onChange={(month: string) => {
          props.triggerMonthChange(month);
        }}
        boardId={props.board.board_id}
      />
    );
  }, [props.board]);

  return (
    board && (
      <>
        {showPhases ? (
          <PhasesDialog
            auctionId={board?.summary?.id}
            openInfluencerSegmentations={props.openInfluencerSegmentations}
            campaignData={board}
            handleClose={() => {
              setShowPhases(false);
            }}
          />
        ) : null}

        <div className="section-top-bar"></div>
        {csvData && (
          <div className="hidden">
            <CSVLink
              filename={`${board?.summary?.name}.csv`}
              id="csv-test"
              data={csvData}
            ></CSVLink>
          </div>
        )}
        {inviteToCampaignOpen && (
          <InviteToCampaign
            currency={board ? board?.summary?.currency : ""}
            needToUntrash={true}
            campaignId={board?.summary?.id}
            openEditTasksDialog={(b: any) => setEditTasksData(b)}
            influencers={[inviteToCampaignOpen]}
            id={board?.summary?.campaign_list_id}
            listsLoaded={true}
            closeMenu={() => toggleInviteMenu(null)}
            callback={() => updateColumns()}
          />
        )}

        {openCampaignLinks &&
          board &&
          board?.summary &&
          board?.summary?.short_link && (
            <CampaignLinksSide
              boardId={board?.board_id}
              getBoardShortLinks={props.getBoardShortLinks}
              editInfluencerShortLink={props.editInfluencerShortLink}
              deleteInfluencerShortLink={props.deleteInfluencerShortLink}
              handleClose={() => closeCampaignLinks()}
              influencers={columns[Columns.publish]}
              shortLink={board.summary.short_link}
              longLink={board.summary.long_link}
            />
          )}
        {openTrackingCoupons && board && (
          <CampaignCouponsSide
            boardId={board.summary.id}
            handleClose={() => setOpenTrackingCoupons(false)}
            influencers={columns[Columns.publish]?.influencers}
            sales={board.summary.pixel_enabled}
            salesStartTime={board.sales_start_time}
            allowedMergerInjection={
              props.user?.userDetails?.sidebar_data?.allowed_merger_injection
            }
          />
        )}
        <CampaignReminderMenu boardId={board.summary.id} ref={reminderMenu} />
        {openSendMultiMessages && (
          <SendMultiMessageSide
            influencersSelected={influencersSelected}
            influencers={columns[selectedColumn].influencers}
            handleClose={() => setOpenSendMultiMessages(false)}
          />
        )}
        {openAttachLinks && (
          <AttachLinksDialogWithManageData
            handleClose={handleCloseLinksDialog}
            influencers={columns[selectedColumn].influencers}
            data={openAttachLinks}
            onDataChange={(newData) => {
              setOpenAttachLinks(newData);
              props.setApprovalInfluencer(
                newData.influencer.influencer_id,
                newData.influencer,
              );
              handleRefreshBoard(true);
            }}
          />
        )}
        <SearchFilter
          share={false}
          type="manage"
          searchInfluencers={searchInfluencers}
          setLoadingData={setLoadingData}
          user={props.user}
          ref={sideSearchRef}
          renderProfileSearchItems={() => (
            <CampaignManagerFilters
              filters={filters}
              list={list}
              lists={props.lists}
              questions={questions}
              setQuestions={setQuestions}
              searchInfluencers={searchInfluencers}
              searchLists={searchLists}
              setFilters={setFilters}
              setTagsFilter={setTagsFilter}
              tags={board.tags}
              tagsFilter={tagsFilter}
              callback={updateColumns}
            />
          )}
        />
        <HumanzPage
          content={
            <>
              <div className="campaigns-section-main">
                <div className="main-middle-container" style={{ padding: 0 }}>
                  {!board ? (
                    <div className="logo-loader">
                      <div className="loader" />
                      <img src={"/images/humanz-logo.png"} />
                    </div>
                  ) : (
                    <div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Flex alignItems="flex-start" flex={1}>
                            <h1>{"Campaign Manager"}</h1>
                            {!board?.one_month_campaign && (
                              <span
                                style={{
                                  marginTop: "20px",
                                  marginLeft: "20px",
                                  fontSize: "18px",
                                  fontWeight: 400,
                                  color: "#808080",
                                }}
                              >
                                {moment(
                                  props.month
                                    ? props.month
                                    : board?.current_manage_month,
                                ).format("MMM YYYY")}
                              </span>
                            )}
                          </Flex>

                          <Flex gap={2} alignItems="center">
                            {columns[Columns.publish]?.influencers?.length &&
                            !board?.one_month_campaign &&
                            !lastMonth ? (
                              <Tooltip
                                overlay={
                                  "Plan next month tasks, only available when campaign is not over in the next month"
                                }
                                placement={"bottom"}
                              >
                                <Box>
                                  <Button
                                    style={{ borderRadius: 20 }}
                                    leftIcon={
                                      <i className="far fa-calendar-pen" />
                                    }
                                    variant={"solid"}
                                    isDisabled={
                                      selectedColumn === Columns.bids ||
                                      selectedColumn === Columns.invite ||
                                      moment(
                                        props.month
                                          ? props.month
                                          : board?.current_manage_month,
                                      ).isAfter(
                                        moment(board.campaign_end_time),
                                      ) ||
                                      board?.one_month_campaign
                                    }
                                    onClick={() => {
                                      setShowPlanNextMonthDialog(true);
                                    }}
                                  >
                                    Plan next month
                                  </Button>
                                </Box>
                              </Tooltip>
                            ) : null}
                            {!board?.one_month_campaign &&
                              campaignMonthsSelectorMemo}
                          </Flex>
                        </div>
                        {/* End Top Details */}
                        {/* End Tabs and Filters row */}
                        {isAttachTasks ? (
                          <AttachTasks
                            month={board.current_manage_month}
                            goBack={() => setAttachTasks(false)}
                            // getInfluencerData={props.getInfluencerData}
                            board={board}
                            showTikTok={
                              props.user.userDetails.sidebar_data
                                .can_view_tiktok
                            }
                            options={tasksSocialNetworks}
                            markSeenTaskInvites={props.markTaskInvitesAsSeen}
                            sendInviteCounterResponse={sendTaskInviteResponse}
                            currencyLabel={currencyLabel}
                            onSave={editTasks}
                            influencer={editTasksData}
                            handleClose={() => setEditTasksData(null)}
                          />
                        ) : (
                          <>
                            {renderCampaignHeader()}
                            {selectedColumn === Columns.bids &&
                              renderBidsWarnings(board.campaign_end_time)}
                            {searchLoader ? (
                              <div className="logo-loader">
                                <div className="loader" />
                                <img src={"/images/humanz-logo.png"} />
                              </div>
                            ) : (
                              <CampaignManagerInfluencers
                                questions={questions}
                                selectedColumn={selectedColumn}
                                column={columns[selectedColumn]}
                                setEditTasksData={setEditTasksData}
                                openAlarmDialog={openAlarmDialog}
                                openChatsAndContent={openChatsAndContent}
                                influencerRating={influencerRating}
                                influencersSelected={influencersSelected}
                                sendToTrash={sendToTrash}
                                share={share}
                                showCost={props.showCost}
                                negotiate={negotiate}
                                setNegotiate={setNegotiate}
                                waitingNotes={waitingNotes}
                                setShowPaymentsDialog={setShowPaymentsDialog}
                                negotiateInfluencerBid={
                                  props.negotiateInfluencerBid
                                }
                                selectInfluencer={selectInfluencer}
                                toggleInviteMenu={toggleInviteMenu}
                                setOpenAttachLinks={setOpenAttachLinks}
                                openInfluencerSegmentations={
                                  props.openInfluencerSegmentations
                                }
                                getInfluencerData={props.getInfluencerData}
                                acceptInfluencerBid={props.acceptInfluencerBid}
                                changeInfluencerNoteOnBoard={
                                  props.changeInfluencerNoteOnBoard
                                }
                                updateBoardDetails={props.updateBoardDetails}
                                board={board}
                                user={props.user}
                                updateColumns={updateColumns}
                                responseForInfluencerCounterOffer={
                                  props.responseForInfluencerCounterOffer
                                }
                                nextMonthMode={false}
                                useWindowAsScrollContainer={true}
                                setSelectedBid={setSelectedBid}
                                lastMonth={lastMonth}
                                openTargetsModal={openTargetsModal}
                                month={
                                  props.month || board?.current_manage_month
                                }
                              />
                            )}
                          </>
                        )}
                        <div style={{ height: 60 }} />
                      </div>
                    </div>
                  )}
                </div>

                {selectedInfluencerForTargets ? (
                  <CampaignTargetsModalRenderer
                    influencer={selectedInfluencerForTargets}
                    refreshBoard={(month?: string) => {
                      props.triggerMonthChange(month);
                    }}
                    board={board}
                    editMode={targetsEditMode}
                    selectedMonth={props.month}
                    onClose={() => {
                      setSelectedInfluencerForTargets(null);
                      setTargetsEditMode(false);
                    }}
                  />
                ) : null}
                {openTrashDialog && (
                  <CampaignTrashDialog
                    user={props.user}
                    openTrashDialog={openTrashDialog}
                    callback={() => {}}
                    selectedColumn={selectedColumn}
                    sendToTrash={() => {
                      sendToTrash(openTrashDialog, false);
                      setOpenTrashDialog(false);
                    }}
                    onClose={() => {
                      setOpenTrashDialog(false);
                      updateColumns();
                    }}
                    onDispute={() => {
                      // setDisputeInfluencer({ ...openTrashDialog });
                      // setOpenTrashDialog(false);
                    }}
                    oneMonthCampaign={board.one_month_campaign}
                    auctionId={board.board_id}
                    refreshBoard={() => handleRefreshBoard(true)}
                    planNextMonth={showPlanNextMonthDialog}
                  />
                )}

                {showPaymentsDialog && (
                  <CampaignPaymentDialog
                    navigate={props.navigate}
                    onClose={() => setShowPaymentsDialog(false)}
                    payToInfluencer={payToInfluencer}
                    paymentDialogData={showPaymentsDialog}
                    user={props.user}
                  />
                )}

                {showPageBlocker && <PageBlocker />}

                {editTasksData && (
                  <EditTasksDialog
                    markSeenTaskInvites={props.markTaskInvitesAsSeen}
                    sendInviteCounterResponse={sendTaskInviteResponse}
                    currencyLabel={currencyLabel}
                    onSave={editTasks}
                    influencer={editTasksData}
                    handleClose={() => setEditTasksData(null)}
                    month={board.current_manage_month}
                    board={board}
                  />
                )}
                {openProposals !== null && (
                  <CampaignPreviewDialog
                    newOnly={false}
                    waitingNotes={waitingNotes}
                    shareGuid={board.share_guid}
                    allowedUploadContent={
                      props.user &&
                      props.user.userDetails &&
                      props.user.userDetails.sidebar_data
                        ? props.user.userDetails.sidebar_data
                            .allowed_upload_content
                        : false
                    }
                    updateColumns={updateColumns}
                    columns={columns}
                    handleClose={() => {
                      setOpenProposals(null);
                    }}
                    influencer_id={openProposals}
                    isShowingModal={openProposals !== null}
                    boardId={board?.summary?.id}
                    shareActive={board?.summary?.shared_campaign}
                    share={props.share}
                    onlyChat={
                      selectedColumn === Columns.invite ||
                      selectedColumn === Columns.bids ||
                      selectedColumn === Columns.trash
                    }
                  />
                )}
                <RateInfluencerDialog
                  ref={influencerRating}
                  isShowingModal={true}
                />
              </div>
              {showPlanNextMonthDialog ? (
                <PlanNextMonthDialog
                  openAlarmDialog={openAlarmDialog}
                  openChatsAndContent={openChatsAndContent}
                  share={share}
                  showCost={props.showCost}
                  negotiateInfluencerBid={props.negotiateInfluencerBid}
                  openInfluencerSegmentations={
                    props.openInfluencerSegmentations
                  }
                  getInfluencerData={props.getInfluencerData}
                  acceptInfluencerBid={props.acceptInfluencerBid}
                  changeInfluencerNoteOnBoard={
                    props.changeInfluencerNoteOnBoard
                  }
                  updateBoardDetails={props.updateBoardDetails}
                  user={props.user}
                  responseForInfluencerCounterOffer={
                    props.responseForInfluencerCounterOffer
                  }
                  markTaskInvitesAsSeen={props.markTaskInvitesAsSeen}
                  currencyLabel={currencyLabel}
                  sendTaskInviteResponse={sendTaskInviteResponse}
                  editTasks={editTasks}
                  onClose={() => setShowPlanNextMonthDialog(false)}
                  sendToTrash={sendToTrash}
                />
              ) : null}
              <ResponseToInfluencerBidDialog
                influencer={selectedBid}
                isOpen={!!selectedBid}
                currencyLabel={currencyLabel}
                onClose={() => {
                  setSelectedBid(null);
                }}
                updateColumns={() => {
                  setLoadingData(true);
                  handleRefreshBoard(true);
                }}
              />
              {!share && <CampaignManagerFeedbackModal />}
            </>
          }
        />
      </>
    )
  );
};

const mapStateToProps = (state: any) => {
  return {
    board: state.boardReducer.board as ManageAuctionData,
    premium: state.premiumReducer,
    user: state.userReducer,
    lists: state.listsReducer?.lists as ListSummary[],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return CampaignManagerApis(dispatch);
};

const CampaignManagerConnected: any = connect(
  mapStateToProps,
  mapDispatchToProps,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
)(withTranslation()(CampaignManager));

export default (props: CampaignManagerProps) => {
  const navigate = useNavigate();
  return <CampaignManagerConnected {...props} navigate={navigate} />;
};
