import axios from "axios";
import { toast } from "react-toastify";
import { getMoiAuctionAuction_idTargets } from "src/api/services";
import { getSelectedChild } from "../components/feed_components/TopUserDetails";
import { store } from "../store";
import {
  getBackendUri,
  getHeaders,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";
import { getListsNew } from "./premiumActions";

const auctionsUrlBase = () => `${getBackendUri()}/moi/auctions`;
const manageUrlBase = () => `${getBackendUri()}/moi/auction/manage`;
const shareRoiUrlBase = () => `${getBackendUri()}/moi/premium/roi`;

export const uploadImageToBE = (image, type, callback) => {
  return () => {
    axios
      .post(`${getBackendUri()}/moi/upload_image/${type}`, image, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
      })
      .catch((err) => {
        if (callback) callback(false);
        console.log(err);
      });
  };
};

export const getAuctionRoi = (id, storyOnly, successCallBack, failCallBack) => {
  return (dispatch) => {
    axios
      .get(`${auctionsUrlBase()}/${id}/roi?full_roi=${!storyOnly}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "GET_AUCTION_ROI", payload: response.data });
        successCallBack(response.data);
      })
      .catch((err) => {
        dispatch({ type: "GET_AUCTION_ROI_REJECTED", payload: err });
        console.log(err);
        // dispatch(setToastMessage(I18n.t("system_notifications_failure_get_campaign"),"error"));
        failCallBack();
      });
  };
};

export const getUserBoards = (archive, search, lastTime, tags, callback) => {
  const params = {};
  if (tags?.length) {
    params.tags = tags.map((x) => x.id).join(",");
  }
  return (dispatch) => {
    let url = `${getBackendUri()}/moi/campaigns/?${
      lastTime ? `last_seen_time=${lastTime}&` : ""
    }${search ? `filter=${search}&` : ""}${
      archive ? `archived_campaigns=${archive}&` : ""
    }`;
    const childId = getSelectedChild();
    if (childId) {
      url += `child_id=${childId}`;
    }
    axios
      .get(encodeURI(url), {
        params,
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        let type = "";
        if (archive) {
          type = lastTime
            ? "GET_MORE_USER_ARCHIVED_BOARDS_FULFILLED"
            : "GET_USER_ARCHIVED_BOARDS_FULFILLED";
        } else {
          type = lastTime
            ? "GET_MORE_USER_BOARDS_FULFILLED"
            : "GET_USER_BOARDS_FULFILLED";
        }
        // eslint-disable-next-line no-param-reassign
        response.data.has_more = response.data && response.data.length > 10;
        dispatch({ type, payload: response.data });
        if (callback) callback();
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get user boards",
          "system_notifications_failure_get_user_boards",
        );
        toast.error(notificationOpts.message);
        if (callback) callback();
        console.error(err);
      });
  };
};

export const getBoardDetails = (id, month, callback) => {
  return (dispatch) => {
    if (!id) {
      console.error("Campaign id is undefined");
      return;
    }
    let url = `${manageUrlBase()}/${id}`;
    if (month === "next") {
      url = `${getBackendUri()}/moi/auction/manage/${id}/next_month`;
      dispatch({ type: "CLEAR_NEXT_MONTH", payload: null });
    }
    const childId = getSelectedChild();
    if (childId) {
      url += `?child_id=${childId}`;
    }
    axios
      .get(url, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        params: month && month !== "next" ? { month } : null,
      })
      .then((response) => {
        const { data } = response;
        if (month === "next") {
          dispatch({ type: "UPDATE_BOARD_NEXT_MONTH", payload: data });
        } else {
          data.board_id = id;
          data.waiting_for_update = false;
          data.last_update_time = new Date();
          data.etag = response.headers.etag;
          dispatch({ type: "GET_BOARD_DATA_FULLFILLED", payload: data });
        }
        dispatch(getAuctionGoalsData(id, undefined, month));
        if (callback) callback(response.data);
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = `${window.translate(
          "Failed to get board",
          "system_notifications_failure_get_inf_in_board",
        )} - ${id}`;
        toast.error(notificationOpts.message);
        if (callback) callback();
        console.error(err);
      });
  };
};

export const updateBoardDetails = (id, etag, month, callback) => {
  return (dispatch) => {
    axios
      .get(`${manageUrlBase()}/${id}${month ? `?month=${month}` : ""}`, {
        withCredentials: true,
        headers: {
          "If-None-Match": etag,
          "X-MoiBackendAuthorization": getSavedJwtToken(),
        },
      })
      .then((response) => {
        if (response.status === 304) {
          if (callback) {
            callback();
          }
          return;
        }
        const { data } = response;
        data.board_id = id;
        data.waiting_for_update = false;
        data.last_update_time = new Date();
        data.etag = response.headers.etag;
        dispatch({ type: "UPDATE_BOARD_DATA_FULLFILLED", payload: data });
        if (callback) callback(response.data);
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 304) {
          if (callback) {
            callback();
          }
          return;
        }
        // notificationOpts["title"] = window.translate("Failed", "system_notifications_failure");
        // notificationOpts["message"] = window.translate("Failed to get board", "system_notifications_failure_get_inf_in_board");
        // toast.error(notificationOpts["message"]);
        if (callback) callback();
        console.error(err);
      });
  };
};

export const setBoardIfluencerTags = (id, influencerId, tags, callback) => {
  return () => {
    axios
      .put(
        `${manageUrlBase()}/${id}/${influencerId}/tags`,
        { tags },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response.data);
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to set tags",
          "system_notifications_failure_set_tags",
        );
        toast.error(notificationOpts.message);
        if (callback) callback();
        console.error(err);
      });
  };
};

export const addBoardTag = (id, tagData, callback) => {
  axios
    .post(`${manageUrlBase()}/${id}/tag`, tagData, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((response) => {
      callback(response.data);
    })
    .catch(() => callback(false));
};

export const editTagInBoard = (boardId, tagId, tagData, callback) => {
  axios
    .put(`${manageUrlBase()}/${boardId}/tag/${tagId}`, tagData, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then(() => {
      callback(true);
    })
    .catch(() => callback(false));
};

export const deleteTagInBoard = (boardId, tagId, callback) => {
  axios
    .delete(`${manageUrlBase()}/${boardId}/tag/${tagId}`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then(() => {
      callback(true);
    })
    .catch(() => callback(false));
};

export const responseForInfluencerCounterOffer = (
  boardId,
  influencerId,
  data,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${manageUrlBase()}/${boardId}/invite_influencers/${influencerId}/advertiser_response`,
        data,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async (response) => {
        dispatch({
          type: "RESPOSE_FOR_INFLUENCER_BID",
          payload: response.data,
        });
        if (data.response_type === "accept") {
          await dispatch({
            type: "MOVE_INFLUENCER_COLUMN",
            payload: {
              current_column: "invite",
              to_column: "approval",
              influencer_id: influencerId,
            },
          });
        }
        if (callback) {
          callback();
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "RESPOSE_FOR_INFLUENCER_BID_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to response to the influencer offer",
          "system_notifications_failure_response_to_influencer_offer",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });

        console.error(err);
      });
  };
};

export const responseForProposal = (
  boardId,
  proposalId,
  responseData,
  callBack,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${manageUrlBase()}/${boardId}/proposal_feedback/${proposalId}`,
        responseData,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        // toast.success(window.translate("Review sent", "system_notifications_success_review_for_post_sent"))
        callBack();
        // showSendButtonsLoader(false);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "SHOW_MAIN_LOADER", payload: false });
        toast.error(
          window.translate(
            "Failed to send",
            "system_notifications_failure_review_for_post_sent",
          ),
        );
        dispatch({ type: "RESPONSE_FOR_PROPOSAL_LOADER", payload: false });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        // showSendButtonsLoader(false);
        console.error(err);
      });
  };
};

export const attachLinkMedia = (boardId, influencerId, data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${manageUrlBase()}/${boardId}/${influencerId}/post_link`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async (response) => {
        if (response && response.status === 201) {
          // in case we update boosted impression
          dispatch({
            type: "BOOSTED_LINK_UPDATED",
            payload: {
              board_id: boardId,
              influencer_id: influencerId,
              medias: data,
              res: response.data,
            },
          });
          notificationOpts.title = window.translate(
            "Success!",
            "system_notifications_success",
          );
          notificationOpts.message = window.translate(
            "Links and boosted impressions have been updated",
            "system_notifications_success_boosted_saved",
          );
          toast.success(notificationOpts.message);
        } else if (response.data) {
          const payload = {
            ...response.data,
            influencer_id: influencerId,
            board_id: boardId,
          };

          await dispatch({
            type: "ATTACH_POST_LINK_FULLFILED",
            payload,
          });
          notificationOpts.title = window.translate(
            "Success!",
            "system_notifications_success",
          );
          notificationOpts.message = window.translate(
            "Link as been attached",
            "system_notifications_success_link_sent",
          );
        }
        if (callback) callback(false);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to attach link. Links must be valid and unique.",
          "system_notifications_failure_link_sent",
        );
        if (err && err.response && err.response.status === 412) {
          toast.error(notificationOpts.message);
          callback(err.response.data);
        } else {
          toast.error(notificationOpts.message);
          callback(notificationOpts.message);
          console.log(err);
        }
      });
  };
};

export const markSeenColumnNotif = (boardId, columnName, callback) => {
  return () => {
    axios
      .post(
        `${manageUrlBase()}/${boardId}/seen_column_notification`,
        { column_name: columnName },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        if (callback) callback();
      })
      .catch(() => {});
  };
};

export const sendToTrash = (boardId, influencerId, currentColumn, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${manageUrlBase()}/${boardId}/${influencerId}/trash`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async () => {
        await dispatch({
          type: "MOVE_INFLUENCER_COLUMN",
          payload: {
            influencer_id: influencerId,
            current_column: currentColumn,
            to_column: "trash",
          },
        });
        if (callback) callback();
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed sending to trash",
          "system_notifications_failure_send_trash",
        );
        toast.error(notificationOpts.message);
        if (callback) callback();
      });
  };
};

export const addNewAuction = (
  unfinishedCamapignId,
  auction,
  successCallback,
  failCallback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${auctionsUrlBase()}?related_unfinished_id=${unfinishedCamapignId}`,
        auction,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        dispatch({
          type: "INSERT_NEW_AUCTION_FULFILLED",
          payload: response.data,
        });
        dispatch(getUserBoards());
        dispatch(getListsNew());
        toast.success(
          window.translate(
            "The campaign created Successfully",
            "system_notifications_success_new_auction",
          ),
        );
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: false });
        if (successCallback) successCallback(response.data);
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: false });
        dispatch({ type: "INSERT_NEW_AUCTION_REJECTED", payload: err });
        toast.error(
          window.translate(
            "Filed to create auction",
            "system_notifications_failure_new_auction",
          ),
        );
        if (failCallback) failCallback();
        console.error(err);
      });
  };
};

export const getAuctionById = (id, callBack) => {
  return (dispatch) => {
    dispatch({ type: "GET_AUCTION_BY_ID_FULLFILLED", payload: null });
    axios
      .get(`${auctionsUrlBase()}/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "GET_AUCTION_BY_ID_FULLFILLED",
          payload: response.data,
        });
        if (callBack) {
          callBack(response.data);
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_AUCTION_BY_ID_REJECTED", payload: err });

        console.log(err);
      });
  };
};

export const changeInfluencerNoteOnBoard = (
  boardId,
  influencerId,
  note,
  callback,
) => {
  return (dispatch) => {
    axios
      .put(`${manageUrlBase()}/${boardId}/${influencerId}/change_note`, note, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "CHANGE_INFLUENCER_NOTE_ON_BOARD",
          payload: response.data,
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHANGE_INFLUENCER_NOTE_ON_BOARD_REJECTED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to change influencer note",
          "system_notifications_failure_add_influencer_on_board_note",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const editKpis = (auctionId, kpis, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${auctionsUrlBase()}/${auctionId}/kpis`, kpis, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) {
          callback();
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to change KPI's",
          "system_notifications_failure_change_kpis",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const addInfluencerToBoard = (boardId, influencers, month, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(`${manageUrlBase()}/${boardId}/invite`, influencers, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        dispatch(getBoardDetails(boardId));
        if (callback) callback();

        toast.success(
          window.translate(
            "This user has been added and now shows in the campaign management",
            "system_notifications_success_add_to_campaign",
          ),
        );
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to add influencers to campaign",
          "system_notifications_failure_add_inf_to_board",
        );
        toast.error(notificationOpts.message);
        if (callback) callback();
        console.error(err);
      });
  };
};

export const unTrash = (boardId, influencer, month, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${manageUrlBase()}/${boardId}/${influencer.influencer_id}/untrash`,
        influencer,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        dispatch(getBoardDetails(boardId, month, null));
        if (callback) callback();
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to add influencers to campaign",
          "system_notifications_failure_add_inf_to_board",
        );
        toast.error(notificationOpts.message);
        if (callback) callback();
        console.error(err);
      });
  };
};

export const createUnfinishedCampaign = (title, jsonData, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${getBackendUri()}/moi/unfinished_campaigns`,
        { title, json_data: jsonData, image_url: jsonData.image_url },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        dispatch({
          type: "CREATED_UNFINISHED_CAMPAIGN",
          payload: { id: response.data.id, title },
        });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to create unfinished campaign",
          "system_notifications_failure_create_unfinished_campaign",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(null);
        console.error(err);
      });
  };
};

export const updateUnfinishedCampaign = (
  campaignId,
  title,
  imageUrl,
  jsonData,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${getBackendUri()}/moi/unfinished_campaigns/${campaignId}`,
        { title, json_data: jsonData, image_url: imageUrl },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response.data);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        dispatch({
          type: "UPDATED_UNFINISHED_CAMPAIGN",
          payload: { id: campaignId, title, image: imageUrl },
        });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to update unfinished campaign",
          "system_notifications_failure_create_unfinished_campaign",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(null);
        console.error(err);
      });
  };
};

export const deleteUnfinishedCampaign = (campaignId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .delete(
        `${getBackendUri()}/moi/unfinished_campaigns/${campaignId}`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) callback(response.data);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        dispatch({
          type: "DELETED_UNFINISHED_CAMPAIGN",
          payload: { id: campaignId },
        });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to update unfinished campaign",
          "system_notifications_failure_create_unfinished_campaign",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(null);
        console.error(err);
      });
  };
};

export const getUnfinishedCampaignData = (campaignId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(`${getBackendUri()}/moi/unfinished_campaigns/${campaignId}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) callback(response.data);

        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to update unfinished campaign",
          "system_notifications_failure_create_unfinished_campaign",
        );
        toast.error(notificationOpts.message);
        if (callback) callback(null);
        console.error(err);
      });
  };
};

export const uploadImageOnUnfinishedCampaign = (
  unfinishedCampaignId,
  image,
  imageType,
  callback,
) => {
  return () => {
    const toAdd = unfinishedCampaignId
      ? `?related_auction_id=${unfinishedCampaignId}`
      : "";
    axios
      .post(`${getBackendUri()}/moi/upload_image/${imageType}${toAdd}`, image, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        if (callback) callback(true);
      })
      .catch(() => {
        if (callback) callback(false);
      });
  };
};

export const updateAuctionPartly = (
  auctionId,
  part,
  partObject,
  successCallBack,
  failCallBack,
  ignoreSuccessToast = false,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${auctionsUrlBase()}/${auctionId}/${part}`, partObject, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (!ignoreSuccessToast) {
          toast.success("Saved changes successfully");
        }
        if (part === "overview") {
          dispatch({
            type: "UPDATED_CAMPAIGN",
            payload: { id: auctionId, title: partObject.title },
          });
        }
        if (successCallBack !== undefined) successCallBack(response);
      })
      .catch((err) => {
        toast.error("Failed to save changes");
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (failCallBack !== undefined) failCallBack();
        console.log(err);
      });
  };
};

export const changeStoriesCount = (
  influencerId,
  boardId,
  stories,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${manageUrlBase()}/${boardId}/story_view_count/${influencerId}`,
        { views: stories },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "CHANGE_STORY_VIEW_FULLFILED",
            payload: {
              tasks: response.data,
              influencer_id: influencerId,
              board_id: boardId,
            },
          });
        }
        if (callback) {
          callback(response.data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to change stories",
          "system_notifications_failure_stories_change",
        );
        toast.error(notificationOpts.message);
        if (callback) {
          callback(false);
        }
        console.error(err);
      });
  };
};

export const acceptInfluencerBid = (
  auctionId,
  influencerId,
  bidId,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${manageUrlBase()}/${auctionId}/approve_bid/${influencerId}`,
        { bid_id: bidId, express: false },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async (response) => {
        dispatch({
          type: "INFLUENCER_IN_BOARD_DATA_UPDATED_FULLFILLED",
          payload: response.data,
        });
        await dispatch({
          type: "MOVE_INFLUENCER_COLUMN",
          payload: {
            current_column: "bids",
            to_column: "approval",
            influencer_id: influencerId,
          },
        });
        if (callback !== null && callback !== undefined) {
          callback();
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({
          type: "INFLUENCER_IN_BOARD_DATA_UPDATED_FAILED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to view influencer update",
          "system_notifications_failure_response_to_influencer_offer",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.error(err);
      });
  };
};

export const negotiateInfluencerBid = (
  auctionId,
  influencerId,
  data,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${manageUrlBase()}/${auctionId}/negotiate_bid/${influencerId}`,
        data,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async (response) => {
        dispatch({
          type: "INFLUENCER_IN_BOARD_DATA_UPDATED_FULLFILLED",
          payload: response.data,
        });
        await dispatch({
          type: "MOVE_INFLUENCER_COLUMN",
          payload: {
            current_column: "bids",
            to_column: "invite",
            influencer_id: influencerId,
          },
        });
        if (callback !== null && callback !== undefined) {
          callback();
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({
          type: "INFLUENCER_IN_BOARD_DATA_UPDATED_FAILED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to view influencer update",
          "system_notifications_failure_response_to_influencer_offer",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.error(err);
      });
  };
};

export const getSharedAuctionRoi = (auctionId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(`${shareRoiUrlBase()}/${auctionId}/share`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async (response) => {
        if (callback !== null && callback !== undefined) {
          callback(response.data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get shared roi details",
          "system_notifications_failure_shared_roi_get",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.error(err);
      });
  };
};

export const putSharedAuctionRoi = (auctionId, data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(`${shareRoiUrlBase()}/${auctionId}/share`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async (response) => {
        if (callback !== null && callback !== undefined) {
          callback(response.data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to share roi",
          "system_notifications_failure_shared_roi",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.error(err);
      });
  };
};

export const disableSharedAuctionRoi = (auctionId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .delete(`${shareRoiUrlBase()}/${auctionId}/share`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async () => {
        if (callback !== null && callback !== undefined) {
          callback();
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to disable shared roi",
          "system_notifications_failure_shared_roi_disable",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.error(err);
      });
  };
};

export const deleteProduct = (auctionId, productIds, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .delete(`${auctionsUrlBase()}/${auctionId}/product`, {
        data: productIds,
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async () => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) callback();
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.error(err);
      });
  };
};

export const deleteQuestions = (auctionId, ids, callback) => {
  store.dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
  axios
    .delete(`${auctionsUrlBase()}/${auctionId}/questions`, {
      data: ids,
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then(async () => {
      store.dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      if (callback) callback();
    })
    .catch((err) => {
      store.dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      console.error(err);
    });
};

export const checkLinkAvailable = (
  unfinishedCampaignId,
  shortLink,
  linkType,
  callback,
  errorCallbak,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${auctionsUrlBase()}/check_link_availability`,
        {
          short_link: shortLink,
          unfinished_campaign_id: unfinishedCampaignId,
          link_type: linkType,
        },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async (response) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) callback(response.data);
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        errorCallbak();
      });
  };
};

export const editInfluencerShortLink = (
  auctionId,
  influencerId,
  data,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(`${manageUrlBase()}/${auctionId}/short_link/${influencerId}`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async (response) => {
        if (callback) {
          callback(response.data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Success",
          "system_notifications_success",
        );
        notificationOpts.message = window.translate(
          "Links updated successfully",
          "system_notifications_success_edit_inf_link",
        );
        toast.success(notificationOpts.message);
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to edit influencer link",
          "system_notifications_failure_edit_inf_link",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) {
          callback(false);
        }
        console.error(err);
      });
  };
};

export const deleteInfluencerShortLink = (
  auctionId,
  influencerId,
  data,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .request({
        url: `${manageUrlBase()}/${auctionId}/short_link/${influencerId}`,
        data,
        method: "delete",
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async (response) => {
        if (callback) {
          callback(response.data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to delete influencer link",
          "system_notifications_failure_delete_inf_link",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) {
          callback(false);
        }
        console.error(err);
      });
  };
};

export const getBoardShortLinks = (auctionId, callback) => {
  return () => {
    axios
      .get(`${manageUrlBase()}/${auctionId}/short_link`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async (response) => {
        if (callback) {
          callback(response.data);
        }
      })
      .catch(() => {
        if (callback) {
          callback(false);
        }
      });
  };
};

export const getAuctionInfluencers = (auctionId, callback) => {
  axios
    .get(`${manageUrlBase()}/${auctionId}/influencers`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((response) => {
      if (callback) {
        callback(response.data);
      }
    })
    .catch(() => {
      if (callback) {
        callback(false);
      }
    });
};

export const payInfluencer = (
  auctionId,
  influencerId,
  currentColumn,
  callback,
) => {
  return (dispatch) => {
    axios
      .post(
        `${manageUrlBase()}/${auctionId}/pay/${influencerId}`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async () => {
        await dispatch({
          type: "MOVE_INFLUENCER_COLUMN",
          payload: {
            current_column: currentColumn,
            to_column: "approval",
            influencer_id: influencerId,
          },
        });
        if (callback) callback(true);
      })
      .catch((err) => {
        if (err.response.status === 429) {
          notificationOpts.message = window.translate(
            "Another payment is already processing. Please try again in a few minutes.",
          );
        } else {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message = window.translate(
            "Failed to pay to influencer, please contact us",
            "system_notifications_failure_pay",
          );
        }
        toast.error(notificationOpts.message);
        if (callback) callback(false);
      });
  };
};

export const responseForInfluencerTaskCounterOffer = (
  influencerId,
  taskId,
  data,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${manageUrlBase()}/${influencerId}/invite_task/${taskId}/advertiser_response`,
        data,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async (response) => {
        dispatch({
          type: "INFLUENCER_CHANGED_ON_BOARD",
          payload: response.data,
        });
        if (callback) {
          callback(response.data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({
          type: "RESPOSE_FOR_INFLUENCER_TASK_REJECTED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to response to the influencer offer",
          "system_notifications_failure_response_to_influencer_offer",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });

        console.error(err);
      });
  };
};

export const getProposals = (auctionId, influencerId, callback) => {
  axios
    .post(
      `${manageUrlBase()}/${auctionId}/proposals_history/${influencerId}`,
      null,
      {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      },
    )
    .then((response) => {
      callback(response.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const searchOnBoard = (auctionId, searchParams, callback) => {
  return (dispatch) => {
    const url = `${manageUrlBase()}/${auctionId}/search_bids`;
    axios
      .post(url, searchParams, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "SET_SEARCH_PARAMETERS", payload: searchParams });
        callback(response);
      })
      .catch((err) => {
        dispatch({ type: "SEARCH_INFLUENCERS_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to get influencers";
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const shareProposal = (
  auctionId,
  proposalId,
  data,
  callback = () => {},
) => {
  axios
    .post(
      `${manageUrlBase()}/${auctionId}/proposal_feedback/${proposalId}/share`,
      data,
      {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      },
    )
    .then(() => {
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

export const shareAllProposals = (
  auctionId,
  influencerId,
  callback = () => {},
) => {
  axios
    .post(
      `${manageUrlBase()}/${auctionId}/proposal_feedback/${influencerId}/share_all_for_user`,
      {},
      {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      },
    )
    .then(() => {
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

export const toggleUploadsShare = (
  auctionId,
  proposalId,
  data,
  callback = () => {},
) => {
  axios
    .post(
      `${manageUrlBase()}/${auctionId}/proposal_feedback/${proposalId}/toggle_share_uploads`,
      data,
      {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      },
    )
    .then(() => {
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

export const UploadVideo = (id, file, callback = () => {}) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("file", file);

  axios
    .post(`${getBackendUri()}/moi/upload_video`, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-MoiBackendAuthorization": getSavedJwtToken(),
      },
    })
    .then(() => {
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

export const uploadProposal = (
  auctionId,
  influencerId,
  taskId,
  data,
  callback = () => {},
) => {
  axios
    .post(
      `${manageUrlBase()}/${auctionId}/proposal_upload_as_advertiser/${influencerId}/task/${taskId}`,
      data,
      {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      },
    )
    .then(() => {
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

export const toggleArchiveAuction = (
  auctionId,
  archive,
  callback = () => {},
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${getBackendUri()}/moi/premium/auction/${auctionId}/archive`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        dispatch({
          type: "CHANGE_AUCTION_ARCHIVE_STATUS",
          payload: { auction_id: auctionId, archive },
        });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback();
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback();
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message =
          "Failed. Please contact us at info@humanz.ai";
        toast.error(notificationOpts.message);
      });
  };
};

export const reviewInfluencerInCampaign = (
  campaignId,
  influencerId,
  reviewData,
  callback = () => {},
) => {
  return () => {
    axios
      .post(
        `${getBackendUri()}/moi/reviews/${influencerId}`,
        { ...reviewData, campaign_id: campaignId },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        toast.success(
          window.translate(
            "Review sent",
            "system_notifications_success_review_for_post_sent",
          ),
        );
        callback(true);
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to send",
            "system_notifications_failure_review_for_post_sent",
          ),
        );
      });
  };
};

export const attachInvoice = (
  auctionId,
  influencerId,
  fileUrl,
  month,
  callback,
) => {
  return () => {
    axios
      .post(
        `${auctionsUrlBase()}/attach_invoice`,
        {
          auction_id: auctionId,
          influencer_id: influencerId,
          file_url: fileUrl,
          month,
        },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        callback(true);
        toast.success(
          window.translate(
            "Invoice attached",
            "system_notifications_success_invoice_attached",
          ),
        );
      })
      .catch(() => {
        callback(false);
        toast.error(
          window.translate(
            "Failed to attach invoice",
            "system_notifications_failure_invoice_attached",
          ),
        );
      });
  };
};

export const saveReminder = (
  auctionId,
  influencerId,
  taskId,
  data,
  callback,
) => {
  axios
    .post(
      `${manageUrlBase()}/${auctionId}/add_reminder_influencer/${influencerId}/task/${taskId}`,
      data,
      {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      },
    )
    .then(() => {
      store.dispatch({
        type: "CHANGE_INFLUENCER_REMINDER",
        payload: { influencer_id: influencerId, task_id: taskId, data },
      });
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

export const deleteReminder = (auctionId, influencerId, taskId, callback) => {
  axios
    .delete(
      `${manageUrlBase()}/${auctionId}/add_reminder_influencer/${influencerId}/task/${taskId}`,
      {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      },
    )
    .then(() => {
      store.dispatch({
        type: "CHANGE_INFLUENCER_REMINDER",
        payload: { influencer_id: influencerId, task_id: taskId, data: {} },
      });
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

export const revertProposalResponse = (boardId, proposalId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .delete(`${manageUrlBase()}/${boardId}/proposal_feedback/${proposalId}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        callback(true);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "SHOW_MAIN_LOADER", payload: false });
        // notificationOpts["title"] = window.translate("Failed", "system_notifications_failure");
        // notificationOpts["message"] = window.translate("Failed to send", "system_notifications_failure_review_for_post_sent");
        // toast.error(notificationOpts["message"]);
        dispatch({ type: "RESPONSE_FOR_PROPOSAL_LOADER", payload: false });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        // showSendButtonsLoader(false);
        callback(false);
        console.error(err);
      });
  };
};

export const revertLinkedMedia = (boardId, influencerId, taskId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .delete(
        `${manageUrlBase()}/${boardId}/${influencerId}/post_link/${taskId}`,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async () => {
        if (callback) callback(true);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to attach link",
          "Failed to revert media link",
        );
        toast.error(notificationOpts.message);
        callback(false);
      });
  };
};

export const revertLinkedStory = (boardId, influencerId, taskId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .delete(
        `${manageUrlBase()}/${boardId}/story_view_count/${influencerId}/${taskId}`,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(async () => {
        if (callback) callback(true);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to attach link",
          "Failed to revert media link",
        );
        toast.error(notificationOpts.message);
        callback(false);
      });
  };
};

export const getFilteredCampaignReport = (id, filters, queryId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${auctionsUrlBase()}/${id}/roi_filtered`, filters, getHeaders())
      .then((r) => {
        callback({ ...r, queryId });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        if (callback) callback(false, null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const attachAgentToInfInCampaign = (
  auctionId,
  influencerId,
  agentId,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${manageUrlBase()}/${auctionId}/manage_influencer/${influencerId}/add_agent/${agentId}`,
        {},
        { headers: getHeaders() },
      )
      .then((r) => {
        callback(true);
        dispatch({ type: "INFLUENCER_CHANGED_ON_BOARD", payload: r.data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        callback(false);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getAuctionTrackingCoupons = (auctionId, callback) => {
  axios
    .get(`${auctionsUrlBase()}/${auctionId}/coupons`, getHeaders())
    .then((r) => {
      callback(r.data);
    })
    .catch(() => {
      callback(null);
    });
};

export const saveCampaignCoupons = (
  auctionId,
  coupons,
  callback,
  failedCallback,
) => {
  axios
    .post(`${auctionsUrlBase()}/${auctionId}/coupons`, coupons, getHeaders())
    .then(() => {
      callback(true);
    })
    .catch((err) => {
      callback(false);
      if (err?.response?.status === 409) {
        failedCallback(err);
      } else {
        toast.error("Failed to save coupons");
      }
    });
};

export const getAuctionGoalsData = (
  auctionId,
  influencerId,
  monthRelevance,
) => {
  return (dispatch) => {
    getMoiAuctionAuction_idTargets(auctionId, {
      influencer_id: influencerId,
      month_relevance: monthRelevance || undefined,
    })
      .then((res) => {
        const sortedData = res.data.reduce((acc, goal) => {
          const { influencer_id } = goal;

          if (!acc[influencer_id]) {
            acc[influencer_id] = [];
          }

          acc[influencer_id].push(goal);

          return acc;
        }, {});

        dispatch({
          type: "GET_AUCTION_GOALS",
          payload: {
            auctionId: res.data[0].auction_id,
            influencers: sortedData,
          },
        });
      })
      .catch((error) =>
        dispatch({ type: "GET_AUCTION_GOALS_ERROR", payload: true }),
      );
  };
};
