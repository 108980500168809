// TODO - lint
/* eslint-disable global-require, react/no-deprecated, global-require, no-shadow, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, import/no-default-export */
// General Imports
import React from "react";
// import PlacesAutocomplete from 'react-places-autocomplete';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/semantic-ui.css";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
// Actions imports
import {
  clearUser,
  editAdvertiserDetails,
  getCountry,
} from "../../actions/userActions";
import CheckBox from "../feed_components/general_components/CheckBox";
import { Currencies } from "../feed_components/utilities/general";

// Consts
const audience = [
  {
    name: "categories",
    label: "Categories",
    type: "checkbox",
    selected: [],
    isOpen: true,
    isMulti: true,
    labels: [
      "Art",
      "Beauty",
      "Bloggers",
      "Books",
      "Coaching",
      "Family",
      "Fashion",
      "Finance",
      "Fitness",
      "Food & Drink",
      "Funny",
      "Gaming",
      "Health",
      "Lifestyle",
      "Home&Garden",
      "Motherhood",
      "Music",
      "News&TV",
      "Nightlife",
      "Pets",
      "Photography",
      "Science",
      "Sports",
      "Tech",
      "Travel",
      "Vegan",
    ],
    values: [
      "art",
      "beauty",
      "bloggers",
      "books",
      "coaching",
      "family",
      "fashion",
      "finance",
      "fitness",
      "food",
      "funny",
      "gaming",
      "health",
      "lifestyle",
      "home",
      "motherhood",
      "music",
      "news",
      "nightlife",
      "photography",
      "science",
      "sports",
      "tech",
      "travel",
      "vegan",
    ],
    row: "audience",
  },
];

class CompleteLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectedType: null,
      audienceFields: audience,
      addressInput: "",
      address: "",
      phone: "+972",
      name: null,
      currency: "USD",
      companyName: null,
      instagramPage: null,
      facebookPage: null,
      country: null,
      youtubePage: null,
      twitterPage: null,
      loading: false,
      errors: {},
      phoneCountry: null,
    };
    this.pages_regexes = {
      instagram:
        /^(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)(\/?)?(.*)/,
      facebook:
        /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:w)*#!\/)?(?:pages\/)?(?:[?w-]*\/)?(?:profile.php?id=(?=d.*))?([w-]*)?/,
      twitter:
        /^(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/([A-Za-z0-9-_]+)(\/?)?(.*)/,
    };

    this.countries = require("../feed_components/utilities/countries.json");
    this.countries = this.countries.data;
  }

  componentWillMount() {
    if (!this.props.user.userDetails) {
      return;
    }

    const { userDetails } = this.props.user;
    audience[0].selected = userDetails.categories;
    const objForState = {
      selectedType: userDetails.advertiser_type,
      phone: userDetails.phone,
      name: userDetails.full_name,
      currency: userDetails.currency ? userDetails.currency : "USD",
    };
    if (userDetails.company_details) {
      const company = userDetails.company_details;
      objForState.country = company.country;
      objForState.companyName = company.name;
      objForState.instagramPage = company.instagram_page;
      objForState.twitterPage = company.twitter_page;
      objForState.facebookPage = company.facebook_page;
      objForState.youtubePage = company.youtube_page;
      objForState.addressInput = company.address;
    }
    this.setState(objForState);
    if (!userDetails.phone) {
      getCountry(
        function (data) {
          let country;
          const codeCountries = require("../feed_components/utilities/countries-codes.json");
          if (data) {
            country = codeCountries.find((x) => x.iso2 === data.toLowerCase());
          }
          if (!country) {
            country = codeCountries.find((x) => x.iso2 === "us");
          }
          this.setState({
            phoneCountry: country.iso2,
            country: country.code,
            phone: "",
            currency: country.currency ? country.currency : "USD",
          });
        }.bind(this),
      );
    } else {
      this.setState({ phoneCountry: userDetails.phone });
    }
  }

  /* ##### Functions ##### */
  onChangeTargetAudience(item, selected) {
    item.selected.addOrRemove(selected);
    this.setState({ updated: true });
  }

  onSelect(val) {
    this.setState({ addressInput: val });
  }
  /* ##### End Functions ##### */

  /* ##### Renders ##### */
  renderAudienceOptins(item, index) {
    return (
      <div key={item.name + index} style={{ marginTop: 5, marginRight: -30 }}>
        <div>
          <strong className="label-title upper-case">{item.label}</strong>
        </div>

        <div
          className="checkbox-container"
          style={{ textAlign: "left", marginTop: 15 }}
        >
          {item.values.map((x, index) => {
            const checked = item.selected.includes(x);
            return (
              <CheckBox
                style={{ marginTop: 5 }}
                checked={checked}
                onChange={() => this.onChangeTargetAudience(item, x)}
                label={item.labels[index]}
                key={x}
              />
            );
          })}
        </div>
      </div>
    );
  }

  onChange = (addressInput) => {
    this.setState({ addressInput });
  };
  /* ##### End Renders ##### */

  /* ##### Stages ###### */
  renderChooseTypeStage() {
    return (
      <div>
        <div style={{ marginTop: 20, height: 150 }}>
          <div
            style={{
              fontSize: 25,
              fontWeight: 300,
              color: "var(--main-baby-blue-color)",
            }}
          >
            Lets Know each other
          </div>
          <div style={{ fontSize: 16 }}>Are you a Brand or an Agency?</div>
        </div>
        <div>
          <div
            className={`complete-cube ${this.state.selectedType === "agency"}`}
            onClick={() => this.setState({ selectedType: "agency" })}
          >
            <div>Agency</div>
            <div>
              <i
                className="far fa-building"
                style={{ fontSize: 40, color: "#aaaaaa" }}
              ></i>
              <div>I manage campaigns for multiple brands</div>
            </div>
          </div>
          <div
            className={`complete-cube ${this.state.selectedType === "brand"}`}
            onClick={() => this.setState({ selectedType: "brand" })}
          >
            <div>Brand</div>
            <div>
              <i
                className="far fa-user-circle"
                style={{ fontSize: 40, color: "#aaaaaa" }}
              ></i>
              <div>I want to promote service or product</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAudienceStage() {
    return (
      <div>
        <div style={{ marginTop: 20, height: 150 }}>
          <div
            style={{
              fontSize: 25,
              fontWeight: 300,
              color: "var(--main-baby-blue-color)",
            }}
          >
            Let&#39;s connect you with the right influencers for your brand
          </div>
          <div style={{ fontSize: 16 }}>
            Firstly, tell us which industries you’re looking for influencers in.
          </div>
        </div>
        <div className="complete-login-categories">
          {this.state.audienceFields.map((x, i) =>
            this.renderAudienceOptins(x, i),
          )}
        </div>
      </div>
    );
  }

  renderMyProfileStage() {
    if (this.state.phoneCountry) {
      return (
        <div style={{ textAlign: "left", marginTop: 20 }}>
          <div style={{ marginTop: 20, height: 120, textAlign: "center" }}>
            <div
              style={{
                fontSize: 25,
                fontWeight: 300,
                color: "var(--main-baby-blue-color)",
              }}
            >
              Lets Know each other
            </div>
            <div style={{ fontSize: 16 }}>Tell us more about you</div>
          </div>
          <div className="input-row">
            <strong className="label-title">NAME</strong>
            <div>
              <input
                placeholder="Enter name"
                defaultValue={this.state.name}
                className="input-line"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
              <span style={{ color: "red" }}>{this.state.errors.name}</span>
            </div>
          </div>
          <div className="input-row">
            <strong className="label-title">COMPANY</strong>
            <div>
              <input
                placeholder="Enter company name"
                defaultValue={this.state.companyName}
                className="input-line"
                onChange={(e) => this.setState({ companyName: e.target.value })}
              />
              <span style={{ color: "red" }}>
                {this.state.errors.company_name}
              </span>
            </div>
          </div>
          <div className="input-row">
            <strong className="label-title">PHONE NUMBER</strong>
            {/* <div><input placeholder="Enter phone number" className="input-line" /></div> */}
            <ReactPhoneInput
              enableSearch={true}
              inputStyle={{ paddingLeft: 45 }}
              value={this.state.phone}
              country={this.state.phoneCountry}
              onChange={(e) => this.setState({ phone: e })}
            />
            <span style={{ color: "red" }}>{this.state.errors.phone}</span>
          </div>
          <div className="input-row">
            <strong className="label-title">Country</strong>
            <div className="searchable-input">
              <Select
                // eslint-disable-next-line react/no-string-refs
                ref="stateSelect"
                options={this.countries}
                placeholder={""}
                value={this.state.country}
                simpleValue
                clearable={false}
                onChange={(value) => {
                  this.setState({ country: value });
                }}
                name="selected-state"
                disabled={false}
                searchable={true}
              />
              <span style={{ color: "red" }}>{this.state.errors.country}</span>
            </div>
          </div>
          <div className="input-row">
            <strong className="label-title">Currency</strong>
            <div className="searchable-input">
              <Select
                // eslint-disable-next-line react/no-string-refs
                ref="stateSelect"
                options={Currencies}
                placeholder={""}
                value={this.state.currency}
                simpleValue
                clearable={false}
                onChange={(value) => {
                  this.setState({ currency: value });
                }}
                name="selected-state"
                disabled={false}
                searchable={true}
              />
              <span style={{ color: "red" }}>{this.state.errors.currency}</span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="spinner small" style={{ marginTop: 50, marginBottom: 0 }}>
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
    );
  }

  saveDetails() {
    const errors = {};
    const data = {
      advertiser_type: this.state.selectedType,
      full_name: this.state.name,
      company_name: this.state.companyName?.trim(),
      phone: this.state.phone,
      company_address: this.state.addressInput,
      currency: this.state.currency,
      categories: this.state.audienceFields[0].selected,
      facebook_page: this.state.facebookPage,
      instagram_page: this.state.instagramPage,
      twitter_page: this.state.twitterPage,
      youtube_page: this.state.youtubePage,
      company_country: this.state.country,
      show_bids_notifications: true,
    };
    if (!this.state.name || this.state.name === "") {
      errors.name = "Please Enter Name";
    }
    if (!this.state.companyName?.trim()) {
      errors.company_name = "Please Enter Company Name";
    }
    if (!this.state.country || this.state.country === "") {
      errors.country = "Please Enter Country";
    }
    if (!this.state.phone || this.state.phone.length < 5) {
      errors.phone = "Please Enter a valid phone";
    }
    if (!this.state.currency || this.state.currency === "") {
      errors.currency = "Please enter your currency";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    this.setState({ loading: true, errors: {} });
    // dont change his image
    if (
      this.props.user &&
      this.props.user.userDetails &&
      this.props.user.userDetails.picture_url
    ) {
      data.image_changed = false;
      data.original_image_url = this.props.user.userDetails.picture_url;
    }
    this.props.editAdvertiserDetails(data, () => {
      this.props.navigate("/");
      window.location.reload();
    });
  }

  moveToNextStage() {
    const currentStage = this.state.stage;
    if (currentStage === 2) {
      const errors = {};
      if (!this.state.name || this.state.name === "") {
        errors.name = "Please Enter Name";
      }
      if (!this.state.companyName?.trim()) {
        errors.company_name = "Please Enter Company Name";
      }
      if (!this.state.currency || this.state.currency === "") {
        errors.currency = "Please enter your currency";
      }
      if (!this.state.country || this.state.country === "") {
        errors.country = "Please Enter Country";
      }
      if (!this.state.phone || this.state.phone.length < 4) {
        errors.phone = "Please Enter A valid phone";
      }
      if (!this.state.addressInput || this.state.addressInput === "") {
        errors.address = "Please Enter Address";
      }
      if (Object.keys(errors).length > 0) {
        this.setState({ errors });
        return;
      }
    }
    this.setState({ errors: {}, stage: currentStage + 1 });
  }

  renderLinkSocial() {
    return (
      <div style={{ textAlign: "left", marginTop: 20 }}>
        <div
          style={{
            fontSize: 25,
            marginBottom: 40,
            fontWeight: 300,
          }}
        >
          Social account link
        </div>
        <div className="input-row">
          <strong className="label-title" style={{ display: "flex" }}>
            <i
              style={{ marginRight: 10, marginTop: -1, fontSize: 19 }}
              className="fa fa-instagram"
            />{" "}
            Instagram
          </strong>
          <div>
            <input
              placeholder="Instargram page"
              defaultValue={this.state.instagramPage}
              className="input-line"
              onChange={(e) => this.setState({ instagramPage: e.target.value })}
            />
            <span style={{ color: "red" }}>{this.state.errors.instagram}</span>
          </div>
        </div>
        <div className="input-row">
          <strong className="label-title" style={{ display: "flex" }}>
            <i
              style={{ marginRight: 10, marginTop: -1, fontSize: 19 }}
              className="fa fa-twitter"
            />{" "}
            Twitter
          </strong>
          <div>
            <input
              placeholder="Twitter page"
              defaultValue={this.state.twitterPage}
              className="input-line"
              onChange={(e) => this.setState({ twitterPage: e.target.value })}
            />
            <span style={{ color: "red" }}>{this.state.errors.twitter}</span>
          </div>
        </div>
        <div className="input-row">
          <strong className="label-title" style={{ display: "flex" }}>
            <i
              style={{ marginRight: 10, marginTop: -1, fontSize: 19 }}
              className="fa fa-facebook"
            />{" "}
            Facebook
          </strong>
          <div>
            <input
              placeholder="Facebook page"
              defaultValue={this.state.facebookPage}
              className="input-line"
              onChange={(e) => this.setState({ facebookPage: e.target.value })}
            />
            <span style={{ color: "red" }}>{this.state.errors.facebook}</span>
          </div>
        </div>
        <div className="input-row">
          <strong className="label-title" style={{ display: "flex" }}>
            <i
              style={{ marginRight: 10, marginTop: -1, fontSize: 19 }}
              className="fa fa-youtube-play"
            />{" "}
            YouTube
          </strong>
          <div>
            <input
              placeholder="YouTube page"
              defaultValue={this.state.youtubePage}
              className="input-line"
              onChange={(e) => this.setState({ youtubePage: e.target.value })}
            />
            <span style={{ color: "red" }}>{this.state.errors.youtube}</span>
          </div>
        </div>
      </div>
    );
  }

  /* ##### End Stages ###### */

  render() {
    const { stage, loading } = this.state;
    return (
      <div className="login-page">
        <div className="login-left-side">
          <div className="login-left-inner">
            <div>
              <a onClick={() => clearUser()}>Logout</a>
            </div>
            <img className="login-logo" src={"/images/login/HumanzLogo.png"} />
            {/* {stage === 0 && this.renderChooseTypeStage()} */}
            {/* {stage === 1 && this.renderAudienceStage()} */}
            {this.renderMyProfileStage()}
            {/* {stage === 3 && this.renderLinkSocial()} */}
            <div
              style={{
                paddingBottom: 5,
                paddingTop: 10,
                marginTop: 40,
                textAlign: "center",
              }}
            >
              <button
                className={`btn-primary cancel ${stage === 0 && "disabled"}`}
                onClick={() => this.setState({ stage: stage - 1 })}
              >
                <span>Back</span>
              </button>
              {
                <button
                  className={`save-btn btn btn-primary ${
                    loading ? "disabled" : ""
                  }`}
                  onClick={() => this.saveDetails()}
                  style={{ width: 90, marginLeft: 20 }}
                >
                  <span>Lets Start</span>
                </button>
              }
            </div>
          </div>
        </div>
        <div className="login-right-side"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editAdvertiserDetails: (newDetails, callback) => {
      dispatch(editAdvertiserDetails(newDetails, false, callback));
    },
  };
};

const CompleteLoginPageConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompleteLoginPage);

export default (props) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <CompleteLoginPageConnected
      {...props}
      navigate={navigate}
      params={params}
    />
  );
};
