export const jwtToken = localStorage.getItem("moi-jwt-token");

export const getSavedJwtToken = () => {
  return localStorage.getItem("moi-jwt-token");
};

export const getBackendUri = () => window.SERVER_CONF.BACKEND_URI;

const getEnvironmentName = () => {
  const url = window.location.origin;
  if (url.includes("localhost")) {
    return "localhost";
  }

  if (url.includes("humanz.ai")) {
    if (url === "https://humanz.ai" || url === "https://www.humanz.ai") {
      return "prod";
    }
    const regexString = `https?:\\/\\/(?:be-)?([a-zA-Z0-9-]+)?(?:\\.dev)?\\.humanz\\.ai`;
    const regex = new RegExp(regexString);
    const match = url.match(regex);

    if (match) {
      const environmentMatch = match[1] || "";
      return environmentMatch ? environmentMatch.replace(/-/g, "") : "prod";
    }
  }

  return "unknown";
};

export const getHeaders = () => {
  return {
    withCredentials: true,
    headers: {
      "X-MoiBackendAuthorization": getSavedJwtToken() || "",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  };
};

export const notificationOpts = {
  title: "Success!",
  message: "",
  position: "tr",
  autoDismiss: 5,
};

export const environmentName = getEnvironmentName();

export const showLogger = false;

export const taggerUserId = 320;
export const tagColors = [
  "#9C6DDF",
  "#6D87F1",
  "#5DB1FF",
  "#5AD4D4",
  "#86D65F",
  "#BDE458",
  "#FBC65B",
  "#FBAF5C",
  "#FB8760",
  "#F8646C",
  "#F16DB6",
  "#BFBFBF",
];
export const eventColors = ["#94e6f1", "#23d4ad", "#ceb9e0"];

export const ALLOW_COUNTRIES = {
  WorldWide: "WorldWide",
  Israel: "Israel",
  "South Africa": "South Africa",
  Australia: "Australia",
  "New Zealand": '"New Zealand"',
  Turkey: "Turkey",
  Nigeria: "Nigeria",
  "United States": "United States",
  Brazil: "Brazil",
  "United Kingdom": "United Kingdom",
};

if (window.location.href.includes("semiMode=true")) {
  localStorage.setItem("semiMode", true);
  window.history.pushState(
    {},
    null,
    window.location.href.replace("semiMode=true", ""),
  );
} else if (window.location.href.includes("semiMode=false")) {
  localStorage.removeItem("semiMode");
  window.history.pushState(
    {},
    null,
    window.location.href.replace("semiMode=false", ""),
  );
}
export const semiMode = true;

export const FACEBOOK_CONFIG = {
  appId: "404822187805092",
  scopes:
    "public_profile,instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement,business_management",
  redirectUri: `${window.location.origin}/connect-influencer/meta`,
  agentsRedirectUri: `${window.location.origin}/talent-manager/meta`,
};

export const DAYS_EXPIRE_BID = 21;

export const GOOGLE_CLIENT_ID =
  "737087957786-doo2rd0432lflve6aeg4jke27ugkbcpf.apps.googleusercontent.com";
