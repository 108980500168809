/* eslint-disable no-nested-ternary */
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import Tooltip from "rc-tooltip";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteUpfrontPayment,
  useDeleteExtraPayout,
} from "src/api/actions/auctionActions";
import { deleteMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes } from "src/api/services";
import { AuctionPaymentsData } from "src/api/types";
import { InfoToolTip } from "src/components/feed_components/general_components/InfoToolTip";
import { RootState } from "src/declarations/react-redux";
import { getTargetHeader } from "src/helpers/getTargetHeader";
import { AllSocialIcons } from "src/helpers/SocialNetworksHelper";
import { useDeletePrompt } from "src/hooks/promt";
import UploadInvoiceDialog from "../../dialogs/UploadInvoiceDialog";
import {
  CampaignPaymentsContext,
  CampaignPaymentsContextType,
} from "../CampaignPaymentsContext";
import AddAmountToPayModal from "./AddAmountToPayModal";
import AddUpfrontToPayModal from "./AddUpfrontToPayModal";
import AgentDetailsModal from "./AgentDetailsModal";
import StopAffiliationModal from "./StopAffiliationModal";

type Props = {
  influ: AuctionPaymentsData["months"][0]["influencers"][0];
  month?: any;
  paid_amount?: number;
  month_total?: number;
  charged_total?: number;
  media_total?: number;
  getPrice: (price: number, full?: boolean) => string;
  isCurrentMonth: boolean;
  monthChargeStatus?: string;
};

type Payment =
  AuctionPaymentsData["months"][0]["influencers"][0]["payments"][0];

const PaymentsInfluencerLine = ({
  influ,
  month,
  paid_amount,
  month_total,
  charged_total,
  media_total,
  getPrice,
  isCurrentMonth,
  monthChargeStatus,
}: Props) => {
  const context = React.useContext<CampaignPaymentsContextType>(
    CampaignPaymentsContext,
  );

  const currentMonth = context.payments.months[0].month;

  const [influencerSelectedToAddAmount, setInfluencerSelectedToAddAmount] =
    useState<any>();
  const [influencerSelectedToAddUpfront, setInfluencerSelectedToAddUpfront] =
    useState<{ influencer_id: number; payment_id: string; maxPrice: number }>();

  const [
    influencerSelectedToStopAffiliation,
    setInfluencerSelectedToStopAffiliation,
  ] = useState<any>();
  const auction = useSelector((state) => state.boardReducer?.board);
  const { board_targets } = useSelector(
    (state: RootState) => state.boardReducer,
  );
  const [isStopped, setIsStopped] = useState(influ.stop_next_month);

  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedInfluencerForInvoices, setSelectedInfluencerForInvoices] =
    useState(null);

  const getTotalPaymentAmount = (key: string) => {
    return (
      influ?.payments?.reduce(
        (sum: number, paymentObj: any) => sum + paymentObj[key],
        0,
      ) || 0
    );
  };

  const deleteExtraPayout = useDeleteExtraPayout();
  const deletePrompt = useDeletePrompt();

  const handleExtraPayoutDelete = useCallback(
    async (id: number) => {
      const answer = await deletePrompt.askToDelete({
        text: "Are you sure you want to remove this extra payment for the influencer?",
      });
      if (answer) {
        const res = await deleteExtraPayout.mutateAsync({
          auction_id: auction.board_id,
          influencer_id: influ.influencer_id,
          extra_payout_id: id,
        });
        if (res?.status === 200) {
          context.getPaymentData();
        }
      }
    },
    // eslint-disable-next-line
    [deleteExtraPayout, auction, influ, deletePrompt],
  );

  const handleDeleteUpfrontPayment = useCallback(
    async (id: string) => {
      const answer = await deletePrompt.askToDelete({
        text: "Are you sure you want to remove this upfront payment for the influencer?",
      });
      if (answer) {
        deleteUpfrontPayment(auction.board_id, influ.influencer_id, {
          id,
        })
          .then(() => {
            context.getPaymentData();
          })
          .catch(() => {
            toast.error("Failed to delete upfront payment");
          });
      }
    },
    // eslint-disable-next-line
    [deleteExtraPayout, auction, influ, deletePrompt],
  );

  // total calculations

  const influTotals = {
    month_total,
    total_paid: paid_amount,
    total_charged: charged_total,
    billingIssue:
      monthChargeStatus === "warning" && !isCurrentMonth
        ? month_total - charged_total !== 0
        : false,
    commission: getTotalPaymentAmount("commission"),
    media_total,
  };

  const handleUploadCallback = () => {
    context.getPaymentData();
  };

  const getAffiliateStatus = () => {
    if (influ.trashed) {
      return "trashed";
    }
    if (influ.stop_next_month) {
      return "pending";
    }
    return "active";
  };

  const PaymentTypeBox = ({
    payment,
    comment,
  }: {
    payment: Payment;
    comment: string;
  }) => {
    const paymentStep: {
      targetMetrics: string;
      step: number;
    } = {
      targetMetrics: "",
      step: 0,
    };

    if (payment.target_step_id) {
      const influencer = board_targets?.influencers[influ.influencer_id];
      const target = influencer.find((target) =>
        target.steps?.some((step) => step?.id === payment.target_step_id),
      );

      if (!target) return;

      const stepIndex = target.steps
        .filter((step) => step.completed_at && step.month_relevance === month)
        .findIndex((step) => step?.id === payment.target_step_id);
      const step = stepIndex !== -1 ? stepIndex + 1 : 0;

      const targetMetrics = target.target_metric;
      // influencer.findIndex((t) => t === target) + 1;

      paymentStep.targetMetrics = getTargetHeader(targetMetrics);
      paymentStep.step = step;
    }
    const type = () => {
      switch (payment.payment_type) {
        case "affiliation":
          return `${isCurrentMonth ? "Est." : ""} Affiliation`;
        case "extra":
          return "Extra payment";
        case "extra_task":
          return "Extra task";
        case "recurring_payment":
          return "Flat fee";
        case "tasks":
          return "Tasks payment";
        case "target_bonus":
          return `Target ${paymentStep.targetMetrics} step ${paymentStep.step}`;
        case "base":
        default:
          return "Base payment";
      }
    };
    const paymentType = type();
    const paymentTaskType = payment.based_on_task_type;
    return (
      <Box sx={{ display: "flex" }}>
        {payment.charged && payment.is_completed && (
          <Tooltip overlay={"Completed and charged"}>
            <Box mr={1}>
              <Text color={"green.500"} className="fas fa-check-circle" />
            </Box>
          </Tooltip>
        )}
        <span>
          {paymentTaskType && AllSocialIcons[paymentTaskType] ? (
            <i
              className={`${AllSocialIcons[paymentTaskType]} colored`}
              style={{ fontSize: "1.2em", marginRight: "5px" }}
            ></i>
          ) : null}
        </span>
        <span>{paymentType}</span>
        {paymentType === "Extra payment" && (
          <InfoToolTip
            overlay={comment}
            overlayStyle={{ whiteSpace: "pre-line" }}
            iconStyle={{ color: "#646464", marginLeft: "5px" }}
          ></InfoToolTip>
        )}
      </Box>
    );
  };

  const affiliateStatus = getAffiliateStatus();

  const affiliationText = () => {
    switch (affiliateStatus) {
      case "trashed":
        return "Affiliation ended";
      case "pending":
        return "Affiliation stops next month";
      case "active":
      default:
        return "Ongoing affiliation";
    }
  };
  const AffiliationStatus = ({ isExpanded }: any) => {
    return <Box fontSize={"11px"}>{isExpanded ? affiliationText() : null}</Box>;
  };

  const affiliationStatusColor = useMemo(() => {
    switch (affiliateStatus) {
      case "trashed":
        return "#F56565";
      case "pending":
        return "#FBB943";
      case "active":
      default:
        return "#00A99D";
    }
  }, [affiliateStatus]);

  const leftBadge = useMemo(() => {
    switch (affiliateStatus) {
      case "trashed":
        return "-0.8em";
      case "pending":
        return "-2em";
      case "active":
      default:
        return "-0.8em";
    }
    // eslint-disable-next-line
  }, []);

  const handleCancelDispute = useCallback(
    async () => {
      const answer = await deletePrompt.askToDelete({
        text: "Are you sure you cancel this dispute?",
      });
      if (answer) {
        deleteMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes(
          auction.board_id,
          influ.influencer_id,
          { ids: influ.payments.map((payment: any) => payment.id) },
        )
          .then(() => {
            context.getPaymentData();
          })
          .catch(() => {
            toast.error("Failed to cancel dispute");
          });
      }
    },
    // eslint-disable-next-line
    [influ, deletePrompt],
  );

  const hasDispute =
    influ?.dispute_details?.creation_time &&
    !influ?.dispute_details?.response_time;
  return (
    <>
      {selectedAgent && (
        <AgentDetailsModal
          agent={selectedAgent}
          onClose={() => {
            setSelectedAgent(null);
          }}
        />
      )}
      {influencerSelectedToStopAffiliation && (
        <StopAffiliationModal
          auctionId={context.boardId}
          influencerDetails={influencerSelectedToStopAffiliation}
          influTotals={influTotals}
          influencerId={influencerSelectedToStopAffiliation.influencer_id}
          onClose={() => {
            setInfluencerSelectedToStopAffiliation(null);
          }}
          isStopped={isStopped}
        />
      )}
      {selectedInfluencerForInvoices && (
        <UploadInvoiceDialog
          isOpen={true}
          onClose={() => {
            setSelectedInfluencerForInvoices(null);
          }}
          auction_id={context.boardId}
          influencer_id={selectedInfluencerForInvoices.influencer_id}
          callback={handleUploadCallback}
          influencer_name={selectedInfluencerForInvoices.name}
          image_url={selectedInfluencerForInvoices.image_url}
          invoices={selectedInfluencerForInvoices.invoices || []}
          month={month}
        />
      )}
      {influencerSelectedToAddAmount && (
        <AddAmountToPayModal
          onClose={() => setInfluencerSelectedToAddAmount(null)}
          paymentDetails={influencerSelectedToAddAmount}
          auctionId={context.boardId}
          influencerId={influencerSelectedToAddAmount?.influencer_id}
          currencyLabel={auction?.summary?.currency}
          month={month}
        />
      )}
      {influencerSelectedToAddUpfront && (
        <AddUpfrontToPayModal
          onClose={() => setInfluencerSelectedToAddUpfront(null)}
          auctionId={context.boardId}
          influencerId={influencerSelectedToAddUpfront?.influencer_id}
          currencyLabel={auction?.summary?.currency}
          paymentId={influencerSelectedToAddUpfront?.payment_id}
          maxPrice={influencerSelectedToAddUpfront?.maxPrice}
        />
      )}
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <Box>
              <AccordionButton
                name={
                  month
                    ? `${influ.influencer_id?.toString()}_${moment(month)
                        .utc()
                        .format("YYYY-MM")}`
                    : influ.influencer_id?.toString()
                }
              >
                <Box
                  flex={1}
                  textAlign="left"
                  alignItems="center"
                  display={"flex"}
                  gap={10}
                >
                  <Box
                    minWidth={200}
                    justifyContent="center"
                    alignContent={"center"}
                    flex={2}
                  >
                    <Flex
                      gap={2}
                      alignItems={isExpanded ? "flex-start" : "center"}
                    >
                      <Avatar
                        style={{ transition: "all 0.1s" }}
                        size={isExpanded ? "sm" : "xs"}
                        src={influ.image_url}
                      >
                        {window.pixel_enabled && (
                          <AvatarBadge
                            style={{ transition: "all 0.1s" }}
                            borderColor="white"
                            bg={affiliationStatusColor}
                            boxSize="1.25em"
                            width={isExpanded ? "max-content" : "1.25em"}
                            height={isExpanded ? "1.6em" : "1.25em"}
                            wordBreak="keep-all"
                            display={"inline-block"}
                            left={isExpanded ? leftBadge : undefined}
                            padding={"0 0.5em"}
                            textTransform={"none"}
                          >
                            <AffiliationStatus isExpanded={isExpanded} />
                          </AvatarBadge>
                        )}
                      </Avatar>
                      <Text
                        style={{ transition: "all 0.1s" }}
                        alignSelf="center"
                        fontSize={isExpanded ? 14 : 12}
                        marginTop={
                          isExpanded && window.pixel_enabled ? "-17px" : 0
                        }
                        transition="all 0.3s"
                      >
                        {influ.name}
                      </Text>
                      {influTotals.billingIssue && (
                        <Badge
                          bgColor={"#F56565"}
                          color="white"
                          variant={"subtle"}
                          borderRadius="30px"
                          zIndex={9}
                        >
                          Billing issue
                        </Badge>
                      )}
                      {hasDispute && (
                        <Badge
                          // bgColor={"#F56565"}
                          // color="white"
                          colorScheme={"red"}
                          variant={"solid"}
                          fontWeight={600}
                          borderRadius="30px"
                          zIndex={9}
                        >
                          <i
                            className="fas fa-exclamation-triangle"
                            style={{ marginRight: "5px" }}
                          />
                          Dispute
                        </Badge>
                      )}
                    </Flex>
                  </Box>

                  <Box minWidth={"100px"}>
                    {/* Total Paid */}
                    <Box
                      style={
                        hasDispute
                          ? { backgroundColor: "#F56565", color: "white" }
                          : null
                      }
                      className={`payment-status-box  ${
                        isExpanded ? "expanded" : ""
                      }`}
                    >
                      {getPrice(influTotals?.media_total)}
                    </Box>
                  </Box>
                  {/* <Box minWidth={"100px"}>
                    <Box
                      className={`payment-status-box  ${
                        isExpanded ? "expanded" : ""
                      }`}
                    >
                      {getPrice(influTotals?.month_total)}
                    </Box>
                  </Box> */}
                  <Box
                    className={`payment-status-box ${
                      influ?.invoices?.length ? "blue" : ""
                    } ${isExpanded ? "expanded" : ""}`}
                    paddingRight={"4px"}
                    width={"85px"}
                  >
                    {/* Invoices */}
                    <Flex
                      gap={4}
                      alignItems="center"
                      justifyContent={"space-around"}
                    >
                      <Box>{influ?.invoices?.length || 0}</Box>
                      <Button
                        variant={
                          influ?.invoices?.length ? "solid" : "small-action"
                        }
                        color={influ?.invoices?.length ? "white" : "brand.500"}
                        fontSize={18}
                        m={0}
                        maxWidth={"30px"}
                        width={"30px"}
                        flex={1}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedInfluencerForInvoices(influ);
                        }}
                      >
                        <i className="fas fa-file-invoice-dollar" />
                      </Button>
                    </Flex>
                  </Box>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={1}>
              {hasDispute && (
                <Alert variant={"top-accent"} mb={2} status="error">
                  <Flex justifyContent={"space-between"} flex={1}>
                    <Flex>
                      <Tag colorScheme={"red"} variant="solid">
                        Dispute
                      </Tag>
                      <Box ml={3}>
                        <Box>
                          <strong>Creation time: </strong>
                          {moment(influ.dispute_details.creation_time)
                            .utc()
                            .format(window.time.short_date)}
                        </Box>
                        {influ.dispute_details.custom_message && (
                          <Box
                            style={{ wordBreak: "break-word", maxWidth: 650 }}
                          >
                            <strong>Message: </strong>
                            {influ.dispute_details.custom_message}
                          </Box>
                        )}
                      </Box>
                    </Flex>
                    <Box justifyContent={"center"} alignContent="center">
                      <Button
                        onClick={handleCancelDispute}
                        variant="outline"
                        colorScheme={"red"}
                      >
                        Cancel dispute
                      </Button>
                    </Box>
                  </Flex>
                </Alert>
              )}
              {influ?.dispute_details?.response_time && (
                <Alert variant={"top-accent"} mb={2} status="success">
                  <Tag colorScheme={"green"} variant="solid">
                    Dispute resolution
                  </Tag>
                  <Box ml={3}>
                    <Box>
                      <strong>Creation time: </strong>
                      {moment(influ.dispute_details.response_time)
                        .utc()
                        .format(window.time.short_date)}
                    </Box>
                    {influ.dispute_details.response && (
                      <Box>
                        <strong>Message: </strong>
                        {influ.dispute_details.response}
                      </Box>
                    )}
                  </Box>
                </Alert>
              )}
              <TableContainer>
                <Table size="sm" variant={"simple"} className="table-dashed">
                  <Thead style={{ textAlign: "start" }}>
                    <Tr backgroundColor="#FAFAFA">
                      <Th colSpan={5} paddingLeft={1} paddingRight={1}>
                        <Flex padding={1} gap={2} justifyContent="flex-end">
                          <Box>
                            <Button
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                              }}
                              onClick={() =>
                                setInfluencerSelectedToAddAmount(influ)
                              }
                              variant="small-action-white"
                              leftIcon={
                                <i className="fad fa-envelope-open-dollar" />
                              }
                              isDisabled={paid_amount > 0 || influ.on_dispute}
                            >
                              Add amount
                            </Button>
                          </Box>
                          {window.pixel_enabled && currentMonth === month && (
                            <Box>
                              <Button
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                }}
                                variant="small-action-white"
                                onClick={() => {
                                  setInfluencerSelectedToStopAffiliation(influ);
                                  setIsStopped(affiliateStatus !== "pending");
                                }}
                                colorScheme="orange"
                                isDisabled={
                                  influ.on_dispute ||
                                  affiliationText() === "Affiliation ended"
                                }
                                leftIcon={
                                  <i
                                    className={`${
                                      influ.stop_next_month
                                        ? "fa-solid fa-arrow-rotate-left"
                                        : "fad fa-ban"
                                    }`}
                                  />
                                }
                              >
                                {influ.stop_next_month
                                  ? "Undo “Stop working”"
                                  : "Stop working"}
                              </Button>
                            </Box>
                          )}
                          {!influ.on_dispute && (
                            <Tooltip
                              overlay={
                                influTotals.total_paid
                                  ? "Can't open dispute, the influencer's payment has already been processed"
                                  : ""
                              }
                              trigger={influTotals.total_paid ? ["hover"] : []}
                            >
                              <Box>
                                <Button
                                  isDisabled={!!influTotals.total_paid}
                                  variant="small-action-white"
                                  onClick={() =>
                                    context.setDisputeInfluencer(influ)
                                  }
                                  sx={{
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                  colorScheme="red"
                                  leftIcon={
                                    <i className="fad fa-exclamation-triangle" />
                                  }
                                >
                                  Dispute
                                </Button>
                              </Box>
                            </Tooltip>
                          )}
                        </Flex>
                      </Th>
                    </Tr>
                    <Tr>
                      <Th style={{ textAlign: "start" }}>Type</Th>
                      <Th style={{ textAlign: "start" }}>Status</Th>
                      <Th isNumeric style={{ textAlign: "start" }}>
                        Influencer fee
                      </Th>
                      {/* <Th isNumeric style={{ textAlign: "start" }}>
                        Media Fee
                      </Th> */}
                      {/* <Th isNumeric style={{ textAlign: "start" }}>
                        Bill
                      </Th> */}
                      <Th style={{ textAlign: "start" }}>Upfront</Th>
                      <Th isNumeric style={{ textAlign: "start" }}>
                        Paid influencer
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {influ?.payments.map((payment, i: number) => {
                      // let total = payment.commission;

                      // if (!payment?.only_commission) {
                      //   total += payment.price;
                      // }
                      return (
                        <Tr key={i}>
                          <Td>
                            {payment.agent_details && (
                              <Box
                                sx={{ marginBottom: "5px" }}
                                onClick={() =>
                                  setSelectedAgent(payment.agent_details.id)
                                }
                                className={`humanz-indicator clickable`}
                                style={{
                                  width: "auto",
                                  display: "inline-block",
                                  padding: "1px 3px",
                                  fontSize: "11px",
                                }}
                              >
                                <Box className="fas fa-user-tie" /> Agent -{" "}
                                {payment.agent_details.name}
                              </Box>
                            )}
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                              }}
                            >
                              <PaymentTypeBox
                                payment={payment}
                                comment={payment.comment}
                              />
                            </Box>
                          </Td>
                          <Td>
                            {payment.is_completed ? (
                              <Tag
                                colorScheme="green"
                                sx={{
                                  borderRadius: 20,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignContent: "center",
                                }}
                              >
                                <Tooltip
                                  overlay={`
                                    Due date ${
                                      payment.due_date
                                        ? moment(payment.due_date)
                                            .utc()
                                            .format(window.time.short_date)
                                        : null
                                    }
                                    `}
                                >
                                  <i className="fad fa-check-circle fa-lg"></i>
                                </Tooltip>
                              </Tag>
                            ) : payment.ongoing_price ? (
                              <Tooltip
                                overlay={
                                  "Will be finished automatically by the end of the month"
                                }
                              >
                                <Tag
                                  colorScheme="yellow"
                                  sx={{
                                    borderRadius: 20,
                                    padding: 0,
                                    justifyContent: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <i className="fad fa-clock fa-lg"></i>
                                </Tag>
                              </Tooltip>
                            ) : (
                              <Tag
                                colorScheme="red"
                                sx={{
                                  borderRadius: 20,
                                  padding: 0,
                                  justifyContent: "center",
                                  alignContent: "center",
                                }}
                              >
                                <Tooltip overlay={"Not completed yet"}>
                                  <i className="fad fa-exclamation-circle fa-lg"></i>
                                </Tooltip>
                              </Tag>
                            )}
                          </Td>
                          <Td>{getPrice(payment.price)}</Td>
                          <Td>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {payment?.upfront_details?.price ? (
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {getPrice(payment.upfront_details.price)}
                                  {!payment.upfront_details.paid ? (
                                    <IconButton
                                      pl={1}
                                      pr={1}
                                      ml={2}
                                      variant="small-action-white"
                                      onClick={() => {
                                        handleDeleteUpfrontPayment(payment.id);
                                      }}
                                      color="red"
                                      aria-label=""
                                      icon={<i className="fas fa-times" />}
                                    ></IconButton>
                                  ) : null}
                                </Box>
                              ) : !payment.paid_amount &&
                                !paid_amount &&
                                payment.price > 0 &&
                                payment.payment_type !== "affiliation" &&
                                payment.payment_type !== "target_bonus" ? (
                                <IconButton
                                  pl={1}
                                  pr={1}
                                  ml={2}
                                  isDisabled={influ.on_dispute}
                                  variant="small-action-white"
                                  onClick={() =>
                                    setInfluencerSelectedToAddUpfront({
                                      influencer_id: influ.influencer_id,
                                      payment_id: payment.id,
                                      maxPrice: payment.price,
                                    })
                                  }
                                  color="var(--main-baby-blue-color)"
                                  aria-label=""
                                  icon={<i className="fas fa-plus" />}
                                ></IconButton>
                              ) : null}
                            </Box>
                          </Td>
                          {/* <Td>{getPrice(payment.commission)}</Td> */}
                          {/* <Td>{getPrice(total)}</Td> */}
                          <Td>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {payment.only_commission ? (
                                <Box>Not via Humanz</Box>
                              ) : (
                                <Box>{getPrice(payment.paid_amount)}</Box>
                              )}

                              {payment?.can_be_removed &&
                              !payment?.upfront_details?.price ? (
                                <>
                                  <IconButton
                                    pl={1}
                                    pr={1}
                                    ml={2}
                                    variant="small-action-white"
                                    onClick={() => {
                                      handleExtraPayoutDelete(
                                        payment.extra_payment_id,
                                      );
                                    }}
                                    color="red"
                                    aria-label=""
                                    icon={<i className="fas fa-times" />}
                                  ></IconButton>
                                </>
                              ) : null}
                            </Box>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                  <Tfoot>
                    <Tr
                      backgroundColor={"rgba(231, 243, 251, 0.32)"}
                      borderBottom={"1px dashed #249FF033"}
                      color={"var(--main-baby-blue-color)"}
                    >
                      {window.pixel_enabled ? (
                        <Td colSpan={2}>Total monthly amount</Td>
                      ) : (
                        <Td colSpan={2}>Total amount</Td>
                      )}
                      <Td>{getPrice(influTotals.media_total, true)}</Td>
                      <Td colSpan={1} />
                      <Td>{getPrice(influTotals.total_paid)}</Td>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </>
  );
};

export default PaymentsInfluencerLine;
