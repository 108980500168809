import { googleLogout } from "@react-oauth/google";
import axios from "axios";
import moment from "moment-timezone";
import ReactGA from "react-ga";
import { toast } from "react-toastify";
import countries from "../components/feed_components/utilities/countries-codes.json";
import {
  getBackendUri,
  getHeaders,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";
import { InitSock, closeSocket } from "./moiSockJs";

// geoservers
const geoserver = "https://geolocation-db.com/json/";
const geoserverCountryKey = "country_code";

const mimicUserId = 232;
// routes
const loginUrl = () => `${getBackendUri()}/login`;
const registerUrl = () => `${getBackendUri()}/signup`;
const socialAuthUrl = () =>
  `${getBackendUri()}/socialLogin/advertiser_login_with_social`;
const refreshTokenUrl = () => `${loginUrl()}/refreshToken`;
const mimicUrl = () => `${loginUrl()}/mimic`;
const mimicSpecialUrl = () => `${loginUrl()}/mimic_role`;

let gettingRefreshToken = false;

const saveJwtToken = (token, userId) => {
  localStorage.setItem("moi-jwt-token", token);
  if (userId === mimicUserId) {
    localStorage.setItem("mimic-moi-jwt-token", token);
  }
};

const saveJwtRefreshToken = (token, userId) => {
  localStorage.setItem("moi-jwt-refresh-token", token);
  if (userId === mimicUserId) {
    localStorage.setItem("mimic-moi-jwt-refresh-token", token);
  }
};

export const setTokens = (jwtToken, refreshToken) => {
  saveJwtToken(jwtToken, null);
  saveJwtRefreshToken(refreshToken, null);
};

export const getSavedRefreshToken = () => {
  return localStorage.getItem("moi-jwt-refresh-token");
};

const saveEmailToLocalStorage = (val, email) => {
  if (val) {
    localStorage.setItem("moi_user_email", email);
  } else {
    localStorage.removeItem("moi_user_email");
  }
};

export const renewRefreshToken = () => {
  if (!gettingRefreshToken) {
    gettingRefreshToken = true;
  }
  const data = { refresh_token: getSavedRefreshToken() };
  const oldJwtToken = getSavedJwtToken();
  return axios.post(refreshTokenUrl(), data, {
    withCredentials: true,
    headers: { "X-MoiBackendAuthorization": oldJwtToken },
  });
};

export const updateUserCountry = (type, country) => {
  return (dispatch) => {
    axios
      .put(
        `${getBackendUri()}/moi/edit_details/country`,
        { country },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        dispatch({
          type: "EDIT_USER_COUNTRY",
          payload: { user_type: type, country },
        });
      })
      .catch((err) => {
        dispatch({ type: "EDIT_USER_COUNTRY_REJECTED  ", payload: err });
      });
  };
};

export const setGeo = (type) => {
  return (dispatch) => {
    if (localStorage.getItem("MOI_GEO")) {
      dispatch(updateUserCountry(type, localStorage.getItem("MOI_GEO")));
    } else {
      axios.get(geoserver, { timeout: 6000 }).then((response) => {
        const code = response?.data?.[geoserverCountryKey];
        if (code) {
          const country = countries.find(
            (x) => x.iso2 === code.toLowerCase(),
          )?.code;
          if (country) {
            localStorage.setItem("MOI_GEO", country);
            dispatch(updateUserCountry(type, country));
          }
        }
      });
    }
  };
};

export const logInUser = (
  email,
  password,
  captcha,
  isPresenter,
  saveEmail,
  failCallback,
) => {
  const credantials = {
    email,
    password,
    captcha,
    is_influencer: isPresenter,
    user_type: "advertiser",
  };
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });

    axios
      .post(loginUrl(), credantials, { withCredentials: true })
      .then((response) => {
        const advertiser = response?.data?.advertiser;
        saveJwtToken(
          response.headers["x-moibackendauthorization"],
          advertiser.user_id,
        );
        saveJwtRefreshToken(
          response.headers["x-moibackendrefreshtoken"],
          advertiser.user_id,
        );
        dispatch({ type: "SET_USER", payload: advertiser });
        // notificationOpts["title"] = window.translate("Success","system_notifications_success");
        // notificationOpts["message"] = "Welcome";
        // toast.success(notificationOpts["message"]);
        saveEmailToLocalStorage(saveEmail, email);

        ReactGA.event({
          category: "User",
          action: `Logged in user: ${advertiser?.user_id}`,
          value: advertiser.user_id,
        });
        if (
          advertiser?.company_details &&
          !advertiser.company_details.country
        ) {
          dispatch(setGeo("advertiser"));
        }
        // dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        window.location.href = "/home";
      })
      .catch((err) => {
        dispatch({ type: "SET_USER_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (failCallback) {
          if (err && err.response) {
            if (err.response.data.error === "email_not_verified") {
              failCallback("email");
            } else if (
              err.response.data.error ===
              "This user has this email registered with a different user type. Expected type: advertiser, Current type: agent."
            ) {
              failCallback("agent");
            } else {
              failCallback("details");
            }
          } else {
            failCallback("details");
          }
        }
        console.error(err);
      });
  };
};

export const socialLogin = (provider, token, profileId) => {
  const authData = {
    provider,
    access_token: token,
    profile_id: profileId,
    challenge_token: null,
  };
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(socialAuthUrl(), authData, { withCredentials: true })
      .then((response) => {
        const userData = response.data.returning_user;
        if (userData) {
          saveJwtToken(response.headers["x-moibackendauthorization"]);
          saveJwtRefreshToken(response.headers["x-moibackendrefreshtoken"]);
          InitSock(userData.user_id);
          dispatch({ type: "SET_USER", payload: userData });
          notificationOpts.title = window.translate(
            "Success",
            "system_notifications_success",
          );
          notificationOpts.message = "Welcome";
          toast.success(notificationOpts.message);
          dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
          window.location.href = "/";
        }
      })
      .catch((err) => {
        dispatch({ type: "SET_USER_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        if (err.response !== undefined) {
          notificationOpts.message = `${err.response.status} ${err.response.data.error}`;
        } else {
          notificationOpts.message = err.message;
        }
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.error(err);
      });
  };
};

export const signUpUser = (
  email,
  password,
  captcha,
  isPresenter,
  saveEmail,
  successCallback,
  failCallback,
) => {
  const credantials = {
    email,
    password,
    captcha,
    is_influencer: isPresenter,
    user_type: "advertiser",
  };
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(registerUrl(), credantials, { withCredentials: true })
      .then(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        try {
          window.gtag("event", "conversion", {
            send_to: "AW-612443082/51FpCJT4570DEMrHhKQC",
          });
          // eslint-disable-next-line no-empty
        } catch (e) {}
        try {
          window.fbq("track", "CompleteRegistration");
          // eslint-disable-next-line no-empty
        } catch (e) {}
        try {
          ReactGA.event({
            category: "sign_up",
            action: "completed",
            label: "sign_up",
          });
          // eslint-disable-next-line no-empty
        } catch (e) {}
        if (successCallback) successCallback();
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        dispatch({ type: "SET_USER_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to signup";
        if (failCallback) {
          failCallback(err);
        }
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const getDetailsOfUserFromServer = (isPresenter, callback) => {
  let detailsUrl = `${getBackendUri()}/moi/details`;
  if (isPresenter) {
    detailsUrl += "/influencer";
  } else {
    detailsUrl += "/current_user";
  }
  return (dispatch) => {
    axios
      .get(detailsUrl, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "SET_USER", payload: response.data });
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        // console.log(err);
        // console.log("GET DETAILS FAILED");
      });
  };
};

export const setUserFromLocalStorage = (userData) => {
  return (dispatch) => {
    InitSock(userData.id);
    dispatch({ type: "SET_USER_FROM_LOCALSTORAGE", payload: userData });
    if (
      userData.userDetails.company_details &&
      !userData.userDetails.company_details.country
    ) {
      dispatch(setGeo("advertiser"));
    }
    const isInfluencer = userData.userDetails.user_kind === "influencer";
    dispatch(getDetailsOfUserFromServer(isInfluencer));
  };
};

export const clearUser = async () => {
  console.log("LOGOUT");
  await closeSocket();
  googleLogout();
  await localStorage.removeItem("MOI_USER_KEY_0_8_0");
  await localStorage.removeItem("moi-jwt-token");
  await localStorage.removeItem("moi-jwt-refresh-token");
  await localStorage.removeItem("mimic-moi-jwt-token");
  await localStorage.removeItem("mimic-moi-jwt-refresh-token");
  await localStorage.removeItem("mimic-moi-mimicing-user");
  await localStorage.removeItem("child_account");
  window.location = "/login";
};

export const editAdvertiserDetails = (newDetails, logoutAll, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${getBackendUri()}/moi/edit_details/advertiser?disconnect_all_devices=${logoutAll}`,
        newDetails,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (logoutAll) {
          saveJwtToken(
            response.headers["x-moibackendauthorization"],
            response.data.user_id,
          );
          saveJwtRefreshToken(
            response.headers["x-moibackendrefreshtoken"],
            response.data.user_id,
          );
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: false });
        // newDetails["can_open_auctions"] = response.data["can_open_auctions"];
        // newDetails["picture_url"] = response.data["new_image_url"];
        // newDetails["finished_basic_details"] = response.data["finished_basic_details"];
        // dispatch({ type: "EDIT_ADVERTISER_DETAILS_FULFILLED", payload: newDetails });
        dispatch(getDetailsOfUserFromServer(false, callback));
        notificationOpts.title = window.translate(
          "Success",
          "system_notifications_success",
        );
        notificationOpts.message = window.translate(
          "Details edited",
          "system_notifications_success_details_edit",
        );
        toast.success(notificationOpts.message);
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: false });
        dispatch({ type: "EDIT_ADVERTISER_DETAILS_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Details was not edit",
          "system_notifications_failed_details_edit",
        );
        toast.error(notificationOpts.message);
        if (callback) {
          callback();
        }
      });
  };
};

export const getUserSideDetails = () => {
  return (dispatch) => {
    axios
      .get(`${getBackendUri()}/moi/sidebar`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "GET_USER_SIDE_DETAILS_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({ type: "GET_USER_SIDE_DETAILS_REJECTED", payload: err });
        console.log(err);
        if (
          err.response.data.error === "Unauthorized" ||
          err.response.data.status === 401
        ) {
          console.log("Unauthorized");
          // localStorage.removeItem("MOI_USER_KEY_0_8_0");
          // cookies.remove("moi-jwt-token");
          // console.error(err);
          // window.location.reload();
        }
      });
  };
};

export const resetPassword = (
  email,
  password,
  challenge,
  captcha,
  successCallback,
  failCallback,
) => {
  const credantials = {
    email,
    password,
    challenge,
    captcha,
    user_type: "advertiser",
  };
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${getBackendUri()}/forgot_password/reset`, credantials, {
        withCredentials: true,
      })
      .then(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (successCallback) successCallback();
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (failCallback) failCallback();
        console.error(err);
      });
  };
};

export const recoverPassword = (
  email,
  captcha,
  successCallback,
  failCallback,
) => {
  const params = {
    user_email_address: email,
    captcha,
    user_type: "advertiser",
  };
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .get(`${getBackendUri()}/forgot_password/`, {
        withCredentials: true,
        params,
      })
      .then(() => {
        if (successCallback) {
          successCallback();
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (failCallback && err.response.status !== 429) {
          failCallback();
        } else if (err.response.status === 429 && failCallback) {
          failCallback();
        }

        console.log(err);
      });
  };
};

export const getBankDetails = (successCallback, failCallback) => {
  return (dispatch) => {
    axios
      .get(`${getBackendUri()}/moi/edit_details/influencer/bank/`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "GET_USER_BANK_DETAILS", payload: response.data });
        if (successCallback !== null && successCallback !== undefined) {
          successCallback();
        }
      })
      .catch((err) => {
        if (failCallback !== null && failCallback !== undefined) {
          failCallback();
        }
        dispatch({ type: "GET_USER_BANK_DETAILS_REJECTED", payload: err });

        console.log(err);
      });
  };
};

export const addBankAccount = (details, successCallback, failCallback) => {
  return (dispatch) => {
    dispatch({ type: "SHOW_MAIN_LOADER", payload: true });
    axios
      .put(`${getBackendUri()}/moi/edit_details/influencer/bank/`, details, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "ADD_BANK_DETAILS_FULLFILED",
          payload: response.data,
        });
        dispatch(getBankDetails(successCallback));
        notificationOpts.title = window.translate(
          "Success",
          "system_notifications_success",
        );
        notificationOpts.message = window.translate(
          "Added bank account",
          "system_notifications_success_details_edit_add_bank",
        );
        toast.success(notificationOpts.message);
        dispatch({ type: "SHOW_MAIN_LOADER", payload: false });
      })
      .catch((err) => {
        if (failCallback !== null && failCallback !== undefined) {
          failCallback();
        }
        dispatch({ type: "ADD_USER_BANK_DETAILS_REJECTED", payload: err });
        dispatch({ type: "SHOW_MAIN_LOADER", payload: false });
        console.log(err);
      });
  };
};

export const deleteBankAccount = (id, successCallback, failCallback) => {
  return (dispatch) => {
    dispatch({ type: "SHOW_MAIN_LOADER", payload: true });
    axios
      .delete(`${getBackendUri()}/moi/edit_details/influencer/bank/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "DELETE_BANK_DETAILS_FULLFILED",
          payload: response.data,
        });
        dispatch(getBankDetails(successCallback, failCallback));
        dispatch({ type: "SHOW_MAIN_LOADER", payload: false });
      })
      .catch((err) => {
        if (failCallback !== null && failCallback !== undefined) {
          failCallback();
        }
        dispatch({ type: "DELETE_USER_BANK_DETAILS_REJECTED", payload: err });
        dispatch({ type: "SHOW_MAIN_LOADER", payload: false });

        console.log(err);
      });
  };
};

export const advertiserSupport = (data, extended, callback) => {
  return () => {
    const url = `${getBackendUri()}/moi/advertiser_support${
      extended ? "/extended" : ""
    }`;
    axios
      .post(url, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        notificationOpts.title = window.translate(
          "Success",
          "system_notifications_success",
        );
        notificationOpts.message = window.translate(
          "Thank you, we will contact you soon!",
          "system_notifications_success_advertiser_support",
        );
        toast.success(notificationOpts.message);
        callback(true);
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Form was not sent",
          "system_notifications_failed_advertiser_support",
        );
        toast.error(notificationOpts.message);
        callback(false);
      });
  };
};

export const getCountry = (callback = () => {}) => {
  const zones = {};
  Object.values(moment.tz._countries).forEach((country) => {
    country.zones.forEach((zone) => {
      zones[zone] = country.name;
    });
  });
  const timezone = moment.tz.guess();
  callback(zones[timezone]?.toLowerCase() || "us");
};

export const getUserBillingAddress = (callback) => {
  return () => {
    axios
      .get(`${getBackendUri()}/moi/edit_details/current_user/billing`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        // dispatch({ type: 'GET_USER_BILLING_ADDRESS', payload: response.data })
        if (callback) callback(response.data);
      })
      .catch(() => {
        if (callback) callback(false);
      });
  };
};

export const editUserBillingAddress = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${getBackendUri()}/moi/edit_details/current_user/billing`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        // dispatch({ type: 'GET_USER_BILLING_ADDRESS', payload: response.data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) callback(response.data);
      })
      .catch(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) callback(false);
      });
  };
};

export const resetMimicUser = () => {
  setTokens(
    localStorage.getItem("mimic-moi-jwt-token"),
    localStorage.getItem("mimic-moi-jwt-refresh-token"),
  );
  localStorage.removeItem("mimic-moi-mimicing-user");
  localStorage.removeItem("child_account");
  window.location.reload();
};

export const mimicUser = (email, userId, auctionId, callback) => {
  const credantials = { email, user_id: userId, auction_id: auctionId };
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(mimicUrl(), credantials, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        const advertiser = response?.data?.advertiser;
        saveJwtToken(
          response.headers["x-moibackendauthorization"],
          advertiser.user_id,
        );
        saveJwtRefreshToken(
          response.headers["x-moibackendrefreshtoken"],
          advertiser.user_id,
        );
        dispatch({ type: "SET_USER", payload: advertiser });
        // notificationOpts["title"] = window.translate("Success","system_notifications_success");
        // notificationOpts["message"] = "Welcome";
        // toast.success(notificationOpts["message"]);
        localStorage.setItem("mimic-moi-mimicing-user", advertiser.user_id);
        InitSock(advertiser.user_id);
        if (advertiser.company_details && !advertiser.company_details.country) {
          dispatch(setGeo("advertiser"));
        }
        // dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(true);
      })
      .catch((err) => {
        dispatch({ type: "SET_USER_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(false);
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed";
        toast.error(notificationOpts.message);
      });
  };
};

export const mimicSpecialUser = (type, callback) => {
  const credantials = { role: type };
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(mimicSpecialUrl(), credantials, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        const advertiser = response?.data?.advertiser;
        saveJwtToken(
          response.headers["x-moibackendauthorization"],
          advertiser.user_id,
        );
        saveJwtRefreshToken(
          response.headers["x-moibackendrefreshtoken"],
          advertiser.user_id,
        );
        dispatch({ type: "SET_USER", payload: advertiser });
        // notificationOpts["title"] = window.translate("Success","system_notifications_success");
        // notificationOpts["message"] = "Welcome";
        // toast.success(notificationOpts["message"]);
        localStorage.setItem("mimic-moi-mimicing-user", advertiser.user_id);
        InitSock(advertiser.user_id);
        ReactGA.event({
          category: "User",
          action: `Logged in user: ${advertiser.user_id}`,
          value: advertiser.user_id,
        });
        if (advertiser.company_details && !advertiser.company_details.country) {
          dispatch(setGeo("advertiser"));
        }
        // dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback();
      })
      .catch((err) => {
        dispatch({ type: "SET_USER_REJECTED", payload: err });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.error(err);
      });
  };
};

export const getAgents = (search, callback) => {
  return (dispatch) => {
    axios
      .get(`${getBackendUri()}/moi/agent`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "SET_USER_AGENTS_FULFILLED", payload: response.data });
        callback(response.data);
      })
      .catch(() => {});
  };
};

export const addAgent = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${getBackendUri()}/moi/agent`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async (r) => {
        // eslint-disable-next-line no-param-reassign
        data.agent_id = r.data.agent_id;
        await dispatch({ type: "ADD_NEW_AGENT", payload: data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(data.agent_id);
      })
      .catch((e) => {
        let error = "Something Went Wrong";
        if (e && e.response && e.response.data) {
          switch (e.response.data.error) {
            case "name_already_exists":
              error = "Agency with this name already exists";
              break;
            case "phone_already_exists":
              error = "Agency with this phone already exists";
              break;
            case "email_already_exists":
              error = "Agency with this email already exists";
              break;
            case "company_id_already_exists":
              error = "Agency with this company id already exists";
              break;
            default:
              break;
          }
        }
        callback(false, error);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const updateAgent = (agentId, data, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(`${getBackendUri()}/moi/agent/${agentId}`, data, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(async () => {
        await dispatch({ type: "EDIT_AGENT", payload: data });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        callback(data.agent_id);
      })
      .catch((e) => {
        let error = "Something Went Wrong";
        if (e && e.response && e.response.data) {
          switch (e.response.data.error) {
            case "name_already_exists":
              error = "Agency with this name already exists";
              break;
            case "phone_already_exists":
              error = "Agency with this phone already exists";
              break;
            case "email_already_exists":
              error = "Agency with this email already exists";
              break;
            case "company_id_already_exists":
              error = "Agency with this company id already exists";
              break;
            default:
              break;
          }
        }
        callback(false, error);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const deleteAgency = (id, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });

    axios
      .delete(`${getBackendUri()}/moi/agent/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        callback(true);
        dispatch({ type: "DELETE_AGENT_FULFILLED", payload: id });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((e) => {
        callback(false);
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.error === "still_in_use"
        ) {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message =
            "Couldn't delete. The agency in use in a campaign.";
          toast.error(notificationOpts.message);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getAgencyBankDetails = (agentId, callback = () => {}) => {
  axios
    .get(`${getBackendUri()}/moi/agent/${agentId}/bank`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      callback(r.data);
    })
    .catch(() => {
      callback(null);
    });
};

export const getFullAgencyBankDetails = (
  agentId,
  bankId,
  revealDetails,
  callback = () => {},
) => {
  // eslint-disable-next-line no-param-reassign
  revealDetails = revealDetails ? `/${bankId}?show_account=true` : "";
  axios
    .get(`${getBackendUri()}/moi/agent/${agentId}/bank${revealDetails}`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      callback(r.data);
    })
    .catch(() => {
      callback(null);
    });
};

export const getUKAgencyBankDetails = (agentId, callback = () => {}) => {
  axios
    .get(`${getBackendUri()}/moi/agent/${agentId}/bank/uk`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      callback(r.data);
    })
    .catch(() => {
      callback(null);
    });
};

export const getUSAgencyBankDetails = (agentId, callback = () => {}) => {
  axios
    .get(`${getBackendUri()}/moi/agent/${agentId}/bank/us`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      callback(r.data);
    })
    .catch(() => {
      callback(null);
    });
};

export const saveAgencyBank = (
  agentId,
  data,
  successCallback,
  failCallback,
) => {
  axios
    .put(`${getBackendUri()}/moi/agent/${agentId}/bank`, data, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      successCallback(r.data);
    })
    .catch((err) => {
      if (failCallback) {
        failCallback(
          err && err.response && err.response.data ? err.response.data : false,
        );
      }
    });
};

export const saveUKAgencyBank = (
  agentId,
  data,
  successCallback,
  failCallback,
) => {
  axios
    .put(`${getBackendUri()}/moi/agent/${agentId}/bank/uk`, data, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      successCallback(r.data);
    })
    .catch((err) => {
      if (failCallback) {
        failCallback(
          err && err.response && err.response.data ? err.response.data : false,
        );
      }
    });
};

export const saveUSAgencyBank = (
  agentId,
  data,
  successCallback,
  failCallback,
) => {
  axios
    .put(`${getBackendUri()}/moi/agent/${agentId}/bank/us`, data, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      successCallback(r.data);
    })
    .catch((err) => {
      if (failCallback) {
        failCallback(
          err && err.response && err.response.data ? err.response.data : false,
        );
      }
    });
};

export const saveTurkeyAgencyBank = (
  agentId,
  data,
  successCallback,
  failCallback,
) => {
  const filteredData = {
    full_name: data.full_name,
    iban: data.iban,
    identity_number: data.identity_number,
  };
  axios
    .put(`${getBackendUri()}/moi/agent/${agentId}/bank/turkey`, filteredData, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      successCallback(r.data);
    })
    .catch((err) => {
      if (failCallback) {
        failCallback(
          err && err.response && err.response.data ? err.response.data : false,
        );
      }
    });
};

export const deleteAgencyBank = (agentId, bankId, callback) => {
  axios
    .delete(`${getBackendUri()}/moi/agent/${agentId}/bank/${bankId}`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then(() => {
      callback(true);
    })
    .catch(() => {
      // callback(false);
    });
};

export const getUserTags = () => {
  return (dispatch) => {
    axios
      .get(`${getBackendUri()}/moi/meta_tags`, getHeaders())
      .then((r) => {
        dispatch({ type: "SET_USER_TAGS_FULFILLED", payload: r.data });
      })
      .catch((e) => {
        dispatch({ type: "SET_USER_TAGS_REJECTED", payload: e });
      });
  };
};

export const editMetaTag = (tag, callback) => {
  axios
    .post(`${getBackendUri()}/moi/meta_tags`, tag, getHeaders())
    .then((r) => {
      callback(r.data);
    })
    .catch(() => {
      callback(null);
    });
};

export const deleteMetaTag = (tagType, tagId, callback = () => {}) => {
  axios
    .delete(
      `${getBackendUri()}/moi/meta_tags/${tagType}/${tagId}`,
      getHeaders(),
    )
    .then(() => {
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

export const changeEnterprise = (enterpriseId) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${getBackendUri()}/moi/enterprise/users/change_enterprise`,
        { id: enterpriseId },
        getHeaders(),
      )
      .then(async () => {
        const success = await renewRefreshToken();
        const jwtTok = success.headers["x-moibackendauthorization"];
        const jwtRefreshTok = success.headers["x-moibackendrefreshtoken"];
        await setTokens(jwtTok, jwtRefreshTok);
        window.location.reload();
      })
      .catch((e) => {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.error === "still_in_use"
        ) {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message =
            "Couldn't delete. The agency in use in a campaign.";
          toast.error(notificationOpts.message);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const addPasswordToUser = (password, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${getBackendUri()}/moi/add_password`, { password }, getHeaders())
      .then(() => {
        dispatch({ type: "PASSWORD_ADDED", payload: true });
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });

        if (callback) {
          callback(true);
        }
      })
      .catch((e) => {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.error === "still_in_use"
        ) {
          notificationOpts.title = window.translate(
            "Failed",
            "system_notifications_failure",
          );
          notificationOpts.message =
            "Something went wrong, failed to add password";
          toast.error(notificationOpts.message);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) {
          callback(false);
        }
      });
  };
};

export const approveTerms = (callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(
        `${getBackendUri()}/moi/edit_details/current_user/toc/update`,
        { flag_value: false },
        getHeaders(),
      )
      .then(() => {
        dispatch({ type: "SET_ACCEPTED_TC", payload: true });
        if (callback) {
          callback(true);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        if (callback) {
          callback(false);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};
