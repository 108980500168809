import {
  changeInfluencerNoteOnBoard,
  deleteInfluencerShortLink,
  editInfluencerShortLink,
  getAuctionGoalsData,
  getBoardShortLinks,
  markSeenColumnNotif,
  negotiateInfluencerBid,
  payInfluencer,
  responseForInfluencerCounterOffer,
  responseForInfluencerTaskCounterOffer,
  searchOnBoard,
  sendToTrash,
  setBoardIfluencerTags,
  updateBoardDetails,
} from "src/actions/auctionsActions";
import { createDispute } from "src/actions/paymentActions";
import {
  acceptInfluencerBid,
  editTasksOnCampaign,
  getInfluencerData,
  getShareNotesSummary,
  markTaskInvitesAsSeen,
} from "src/actions/premiumActions";
import { getShareBoardDetails } from "src/actions/shareActions";

export const CampaignManagerApis = (dispatch: any) => {
  return {
    getShareNotesSummary: (shareGuid: any, callback: any) => {
      getShareNotesSummary(shareGuid, callback);
    },
    getInfluencerData: (
      influencerId: number,
      postsFiler: any,
      callback: any,
    ) => {
      dispatch(getInfluencerData(influencerId, postsFiler, callback));
    },
    updateBoardDetails: (
      id: number,
      etag: string,
      month: string,
      callback: any,
    ) => {
      dispatch(updateBoardDetails(id, etag, month, callback));
    },
    setBoardIfluencerTags: (
      id: number,
      influencerId: number,
      callback: any,
    ) => {
      dispatch(setBoardIfluencerTags(id, influencerId, callback));
    },
    responseForInfluencerCounterOffer: (
      board_id: number,
      influencer_id: number,
      response: any,
      callback: any,
    ) => {
      dispatch(
        responseForInfluencerCounterOffer(
          board_id,
          influencer_id,
          response,
          callback,
        ),
      );
    },
    markSeenColumnNotif: (
      board_id: number,
      column_name: string,
      callback: any,
    ) => {
      dispatch(markSeenColumnNotif(board_id, column_name, callback));
    },
    sendToTrash: (
      boardId: number,
      influencerId: number,
      currrentColumn: string,
      callback: any,
    ) => {
      dispatch(sendToTrash(boardId, influencerId, currrentColumn, callback));
    },
    changeInfluencerNoteOnBoard: (
      board_id: number,
      influencer_id: number,
      note: string,
      callback: any,
    ) => {
      dispatch(
        changeInfluencerNoteOnBoard(board_id, influencer_id, note, callback),
      );
    },
    acceptInfluencerBid: (
      auctionId: number,
      influencer_id: number,
      bid_id: number,
      callback: any,
    ) => {
      dispatch(acceptInfluencerBid(auctionId, influencer_id, bid_id, callback));
    },
    negotiateInfluencerBid: (
      auctionId: number,
      influencer_id: number,
      data: any,
      callback: any,
    ) => {
      dispatch(
        negotiateInfluencerBid(auctionId, influencer_id, data, callback),
      );
    },
    getBoardShortLinks: (auctionId: number, callback: any) => {
      dispatch(getBoardShortLinks(auctionId, callback));
    },
    editInfluencerShortLink: (
      auctionId: number,
      influencerId: number,
      data: any,
      callback: any,
    ) => {
      dispatch(
        editInfluencerShortLink(auctionId, influencerId, data, callback),
      );
    },
    deleteInfluencerShortLink: (
      auctionId: number,
      influencerId: number,
      data: any,
      callback: any,
    ) => {
      dispatch(
        deleteInfluencerShortLink(auctionId, influencerId, data, callback),
      );
    },
    payInfluencer: (
      auctionId: number,
      influencerId: number,
      currentColumn: string,
      callback: any,
    ) => {
      dispatch(payInfluencer(auctionId, influencerId, currentColumn, callback));
    },
    responseForInfluencerTaskCounterOffer: (
      influencer_id: number,
      task_id: number,
      data: any,
      callback: any,
    ) => {
      dispatch(
        responseForInfluencerTaskCounterOffer(
          influencer_id,
          task_id,
          data,
          callback,
        ),
      );
    },
    editTasksOnCampaign: (
      auctionId: number,
      influencer_id: number,
      tasks: any,
      oldMonth: boolean,
      callback: any,
    ) => {
      dispatch(
        editTasksOnCampaign(
          auctionId,
          influencer_id,
          tasks,
          oldMonth,
          callback,
        ),
      );
    },
    markTaskInvitesAsSeen: (
      auctionId: number,
      influencerId: number,
      callback: any,
    ) => {
      dispatch(markTaskInvitesAsSeen(auctionId, influencerId, callback));
    },
    getShareBoardDetails: (callback: any) => {
      dispatch(getShareBoardDetails(callback));
    },
    searchOnBoard: (auctionId: number, searchParams: any, callback: any) => {
      dispatch(searchOnBoard(auctionId, searchParams, callback));
    },
    createDispute: (pbi_fk: number, message: any, callback: any) => {
      dispatch(createDispute(pbi_fk, message, callback));
    },
    setApprovalInfluencer: (influencer_id: number, data: any) => {
      dispatch({
        type: "SET_APPROVAL_INFLUENCER",
        payload: {
          influencer_id,
          data,
        },
      });
    },
    updateBoardState: (newState: any) => {
      dispatch({
        type: "UPDATE_BOARD_STATE",
        payload: {
          newState,
        },
      });
    },
    getAuctionGoals: (
      auctionId: number,
      influencer_id: number | null,
      month: string,
    ) => {
      dispatch(getAuctionGoalsData(auctionId, influencer_id, month));
    },
  };
};
