import { TargetMetrics } from "src/api/types";

export const getTargetHeader = (type: string, isIcon?: boolean) => {
  switch (type) {
    case TargetMetrics.sales_value:
      return isIcon ? "far fa-bag-shopping" : "Net sales";
    case TargetMetrics.clicks:
      return isIcon ? "far fa-hand-back-point-up" : "Clicks";
    case TargetMetrics.video_views:
      return isIcon ? "far fa-eye" : "Video views";
    case TargetMetrics.impressions:
      return isIcon ? "far fa-group" : "Impressions";
    case TargetMetrics.engagements:
      return isIcon ? "far fa-chart-line" : "Engagements";
    case TargetMetrics.story_views:
      return isIcon ? "fa moi-stories" : "Story views";
    default:
      break;
  }
};
