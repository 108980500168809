import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { Box, IconButton } from "@material-ui/core";
import moment from "moment";
import {
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { AuctionDataExtended } from "src/api/ExtendedTypes";
import { Target, TargetMetrics, TargetTypes } from "src/api/types";
import { formatBigNumbers } from "src/components/feed_components/utilities/general";
import { RootState } from "src/declarations/react-redux";
import Indicator from "../../CreateCampaignSection/FormUI/Indicator";
import CampaignSwitch from "../CampaignSwitch";
import CampaignTargetSelect from "./CampaignTargetSelect";
import CampaignTargetStep from "./CampaignTargetStep";
import {
  AuctionGoals,
  FormAuctionGoal,
  FormValue,
} from "./CampaignTargetsModal";
import { repeatSelect, targetTypes } from "./TargetsTypes";

interface ICampaignTargetAccordion {
  handleDeleteTarget?: (e: MouseEvent<HTMLButtonElement>) => void;
  targetNumber?: number;
  handleTargetChange?: (
    targetId: number,
    step: number,
    reward: number,
    targetValue: FormValue<number>,
    targetType?: string,
    repeatType?: string,
    dueDate?: FormValue<string>,
    isEdit?: boolean,
    renew?: boolean,
  ) => void;
  handleDeleteSteps: (
    clearAll: boolean,
    stepIndex: number | null,
    target: number,
    isEdit?: boolean,
  ) => void;
  auctionGoals?: AuctionGoals;
  isRecurringCampaign?: boolean;
  isView?: boolean;
  currency?: string;
  board?: AuctionDataExtended;
  existingTargetsLength?: number;
  monthPickerOptions: { option: string; value: string }[];
  editTargets?: FormAuctionGoal[];
  influencerId?: number;
}

const CampaignTargetAccordion = ({
  handleDeleteTarget,
  targetNumber,
  handleTargetChange,
  handleDeleteSteps,
  auctionGoals,
  isRecurringCampaign,
  isView,
  currency,
  board,
  existingTargetsLength,
  monthPickerOptions,
  editTargets,
  influencerId,
}: ICampaignTargetAccordion) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [targetType, setTargetType] = useState("");
  const [repeatType, setRepeatType] = useState(
    repeatSelect[isRecurringCampaign ? 1 : 0].value,
  );
  const [isManySteps, setIsManySteps] = useState(false);
  const [steps, setSteps] = useState<number[]>([1, 2]);
  const [dateError, setDateError] = useState("");
  const [isTargetLocked, setIsTargetLocked] = useState(false);
  const [stepToDelete, setStepToDelete] = useState<number | null>(null);

  const { board_targets } = useSelector(
    (state: RootState) => state.boardReducer,
  );
  const boardReducer = useSelector((state: RootState) => state.boardReducer);
  const isCurrentMonth = moment(boardReducer?.month).isSame(moment(), "month");
  const influencerTargets = board_targets?.influencers[influencerId]?.filter(
    (target) =>
      moment(target?.end_time).isAfter(moment()) ||
      moment(target?.steps[0]?.month_relevance).isAfter(
        moment().format("YYYY-MM-01"),
      ),
  ) as Target[];

  const isTargetExpanded = !!(
    auctionGoals[targetNumber]?.steps?.find((step) => step?.target_value?.error)
      ?.target_value?.error || dateError
  );
  const currencySymbol = window.translate("", `money_symbol_${currency}`);

  const currentTarget = auctionGoals[targetNumber];

  const getAvailableMetrics = (auctionGoals: FormAuctionGoal[]) => {
    const usedMetrics = auctionGoals.map((goal) => goal.target_metric);

    const availableType = targetTypes.find((type) => {
      if (
        (type.value === TargetMetrics.sales_value &&
          !(board?.summary?.pixel_enabled && !board.one_month_campaign)) ||
        (!board?.summary?.short_link && type.value === TargetMetrics.clicks)
      )
        return;

      return !usedMetrics.includes(type.value as TargetMetrics);
    });

    return availableType ? availableType.value : null;
  };

  const addEmptyTarget = () => {
    handleTargetChange(
      targetNumber,
      currentTarget?.steps.length,
      undefined,
      {
        value: 0,
        error: "",
      },
      undefined,
      undefined,
      undefined,
      true,
    );
    // handleDueDateChange(monthPickerOptions[0]?.value);
  };

  useEffect(() => {
    if (!isView) {
      handleTargetChange(
        targetNumber,
        0,
        undefined,
        {
          value: 0,
          error: "",
        },
        undefined,
        undefined,
        undefined,
        false,
      );
      handleDueDateChange(monthPickerOptions[0]?.value);
    }
    const timer = setTimeout(() => {
      setIsExpanded(true);
      clearTimeout(timer);
    }, 0);

    const lockedSteps = currentTarget?.steps?.filter((step) =>
      step?.completed_at ? step : null,
    );

    setIsTargetLocked(lockedSteps?.length === currentTarget?.steps?.length);
  }, []);

  useEffect(() => {
    if (influencerTargets && !editTargets && !isView) {
      const usedMetrics = [
        ...influencerTargets,
        ...Object.values(auctionGoals),
      ].map((goal) => goal.target_metric);

      const availableType = targetTypes.find((type) => {
        if (
          (type.value === TargetMetrics.sales_value &&
            !(board?.summary?.pixel_enabled && !board.one_month_campaign)) ||
          (!board?.summary?.short_link && type.value === TargetMetrics.clicks)
        )
          return;

        return !usedMetrics.includes(type.value as TargetMetrics);
      });

      handleTypeChange(availableType ? availableType.value : null);
    }

    if (!isView && editTargets) {
      handleTypeChange(
        getAvailableMetrics(
          editTargets
            ? [...editTargets, ...Object.values(auctionGoals)]
            : Object.values(auctionGoals),
        ),
      );
    }
  }, [targetNumber]);

  useEffect(() => {
    if (currentTarget && targetNumber && isView) {
      setTargetType(currentTarget.target_metric);
      setRepeatType(currentTarget.target_type);
    }
  }, [currentTarget && targetNumber]);

  useLayoutEffect(() => {
    const dueDate = auctionGoals[targetNumber]?.end_time;
    setDateError(dueDate?.value ? "" : dueDate?.error);
  }, [auctionGoals[targetNumber]?.end_time]);

  useEffect(() => {
    if (isTargetExpanded && !isView) {
      setIsExpanded(isTargetExpanded);
    }
  }, [isTargetExpanded]);

  useEffect(() => {
    if (isView) {
      const stepsLength = auctionGoals[targetNumber]?.steps?.length;

      steps.length < currentTarget?.steps.length &&
        setSteps(currentTarget?.steps.map((_step, index) => index + 1));
      setIsManySteps(stepsLength > 1);
    }
  }, [currentTarget?.steps]);

  useEffect(() => {
    if (isManySteps && !isView) {
      setSteps((prev) => prev.slice(0, 2));
    }
    if (isManySteps && currentTarget?.steps.length === 1 && isView) {
      addEmptyTarget();
    }
  }, [isManySteps]);

  const handleTypeChange = (value: string) => {
    setTargetType(value);

    handleTargetChange(
      targetNumber,
      0,
      undefined,
      undefined,
      value,
      repeatType,
      { value: currentTarget?.end_time?.value, error: "" },
      isView,
    );
  };
  const totalPaymentSteps = useMemo(
    () =>
      currentTarget?.steps?.reduce(
        (acc, step) => (step?.reward ? acc + step?.reward : acc),
        0,
      ),
    [currentTarget?.steps],
  );

  const handleRepeatChange = (value: TargetTypes) => {
    setRepeatType(value);
    handleTargetChange(
      targetNumber,
      0,
      undefined,
      undefined,
      targetType,
      value,
      { value: currentTarget?.end_time?.value, error: "" },
      isView,
      !board.one_month_campaign ? value === TargetTypes.monthly : undefined,
    );
  };

  const handleDueDateChange = (value: string) => {
    handleTargetChange(
      targetNumber,
      0,
      undefined,
      undefined,
      targetType,
      repeatType,
      { value: value, error: "" },
      isView,
    );
  };

  const canDeleteTarget =
    currentTarget?.steps && !currentTarget?.steps[0]?.completed_at;

  return (
    <Accordion
      allowToggle
      className="target-accordion"
      style={{ borderRadius: "12px" }}
      index={isExpanded && 0}
      transition={"3s"}
    >
      <AccordionItem
        style={
          isTargetLocked && isView
            ? {
                border: "2px dashed #aaaaaa",
                borderRadius: "12px",
              }
            : null
        }
      >
        <AccordionButton
          onClick={() => setIsExpanded((prev) => !prev)}
          style={{
            minHeight: "52px",
            height: "52px",
          }}
          sx={
            isTargetLocked &&
            isView && {
              backgroundColor: "#808080",
              color: "#fff",
              borderTopRightRadius: "12px",
              borderTopLeftRadius: "12px",
            }
          }
        >
          <Box
            component="p"
            className={
              isTargetLocked && isView
                ? "campaign-target-step__header-text_locked"
                : "target-accordion-summary__title"
            }
          >
            Target{" "}
            {existingTargetsLength
              ? existingTargetsLength + targetNumber
              : targetNumber}{" "}
            (
            {isManySteps
              ? String(steps.length).slice(-1) === "1"
                ? `${steps.length} step`
                : `${steps.length} steps`
              : "1 step"}
            )
          </Box>
          <Box className="target-accordion-summary__buttons">
            {canDeleteTarget && (
              <IconButton
                size="small"
                className="target-accordion-summary__delete-button"
                onClick={handleDeleteTarget}
              >
                <Box component="div" className="fa-solid fa-trash" />
              </IconButton>
            )}

            <IconButton
              size="small"
              className="target-accordion-summary__delete-button"
            >
              {isExpanded ? (
                <Box component="div" className="fa-solid fa-angle-up" />
              ) : (
                <Box component="div" className="fa-solid fa-angle-down" />
              )}
            </IconButton>
          </Box>
        </AccordionButton>
        <AccordionPanel className="target-accordion__details">
          <Box className="target-accordion__select-container">
            <CampaignTargetSelect
              list={targetTypes}
              label="Target type"
              handleSelectChange={(e) =>
                handleTypeChange(String(e?.target?.value))
              }
              value={currentTarget?.target_metric ?? targetType}
              defaultValue={currentTarget?.target_metric ?? targetType}
              board={board}
              auctionGoals={
                editTargets
                  ? [...editTargets, ...Object.values(auctionGoals)]
                  : Object.values(auctionGoals)
              }
              influencerId={influencerId}
              isLocked={isView}
            />
            <Box className="target-accordion__month-select">
              {!board.one_month_campaign && (
                <CampaignTargetSelect
                  list={repeatSelect}
                  label="Repeat every month"
                  handleSelectChange={(e) =>
                    handleRepeatChange(e?.target?.value as TargetTypes)
                  }
                  value={
                    currentTarget?.renew === null ||
                    currentTarget?.renew === undefined
                      ? "monthly"
                      : !currentTarget?.renew
                      ? "one_time"
                      : "monthly"
                  }
                  defaultValue={
                    currentTarget?.renew === null ||
                    currentTarget?.renew === undefined
                      ? "monthly"
                      : !currentTarget?.renew
                      ? "one_time"
                      : "monthly"
                  }
                  // value={currentTarget?.target_type}
                  // defaultValue={currentTarget?.target_type}
                  board={board}
                  isLocked={!isCurrentMonth}
                />
              )}
              {!isRecurringCampaign && (
                <CampaignTargetSelect
                  list={monthPickerOptions}
                  label="Due Month"
                  handleSelectChange={(e) =>
                    handleDueDateChange(e?.target?.value)
                  }
                  defaultValue={currentTarget?.end_time?.value}
                  value={currentTarget?.end_time?.value}
                  board={board}
                  error={currentTarget?.end_time?.error}
                />
              )}
            </Box>
            <Box
              style={{
                background: "#EEE",
                padding: "5px 10px",
                borderRadius: 8,
                color: "#808080",
                display: "flex",
                gap: 6,
                fontWeight: 500,
                // height: 32,
                alignSelf: "flex-end",
                alignItems: "center",
              }}
            >
              <Box style={{ fontSize: 14 }}>Total payment</Box>
              <Box style={{ fontSize: 14, color: "#AAA" }}>
                {`${currencySymbol} ${formatBigNumbers(totalPaymentSteps)}`}
              </Box>
            </Box>
          </Box>
          <Box className="target-accordion__steps">
            <Indicator
              header={
                <Box className="target-accordion-steps__title">
                  <Box component="i" className="fa-solid fa-stairs" />
                  <Box component="p" className="target-select__label">
                    Steps
                  </Box>
                </Box>
              }
              description="You can add steps with a reward to be paid upon completion, with each step count needing to be progressively larger."
            >
              <CampaignSwitch
                switchValue={isManySteps}
                handleSwitchChange={() => {
                  setIsManySteps((prev) => !prev);
                  handleDeleteSteps(false, null, targetNumber);
                }}
                isLocked={
                  (currentTarget?.steps?.length > 1 &&
                    !!currentTarget?.steps[1]?.completed_at) ||
                  (steps?.length > 1 && currentTarget?.steps?.length > 1)
                }
              />
            </Indicator>
            <Box className="target-accordion__steps-container">
              {(isManySteps ? steps : steps?.slice(0, 1))?.map(
                (step, index) => (
                  <CampaignTargetStep
                    key={step}
                    step={isView && currentTarget?.steps[index]}
                    stepIndex={isManySteps ? index + 1 : null}
                    handleTargetChange={handleTargetChange}
                    target={targetNumber}
                    handleDeleteStep={() => {
                      setStepToDelete(index);
                      setSteps((prev) => prev.filter((el) => el !== step));
                      handleDeleteSteps(false, step, targetNumber, isView);
                    }}
                    error={
                      auctionGoals[targetNumber]?.steps &&
                      auctionGoals[targetNumber]?.steps[step - 1]?.target_value
                        ?.error
                    }
                    isEdit={isView}
                    isHiddenIndicator={isManySteps}
                    currency={currency}
                    isLocked={
                      currentTarget?.steps &&
                      !!currentTarget?.steps[index]?.completed_at
                    }
                    isCompleted={
                      currentTarget?.steps &&
                      !!currentTarget?.steps[index]?.completed_at
                    }
                    currentTarget={currentTarget}
                  />
                ),
              )}
              {isManySteps && steps.length <= 9 && (
                <CampaignTargetStep
                  stepIndex={0}
                  handleAddButton={() => {
                    setSteps((prev) => [
                      ...prev,
                      prev[Number(prev.length - 1)] + 1,
                    ]);
                    if (isView) {
                      addEmptyTarget();
                    }
                  }}
                  currentTarget={currentTarget}
                />
              )}
            </Box>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default CampaignTargetAccordion;
