import { TargetMetrics, TargetTypes } from "../../../../../api/types";

export const targetTypes = [
  {
    id: 1,
    option: "Video views",
    value: TargetMetrics.video_views,
  },
  {
    id: 2,
    option: "Story views",
    value: TargetMetrics.story_views,
  },
  {
    id: 3,
    option: "Clicks",
    value: TargetMetrics.clicks,
  },
  {
    id: 4,
    option: "Net sales",
    value: TargetMetrics.sales_value,
  },
  {
    id: 5,
    option: "Impressions",
    value: TargetMetrics.impressions,
  },
  {
    id: 6,
    option: "Engagements",
    value: TargetMetrics.engagements,
  },
];

export const repeatSelect = [
  {
    id: 1,
    option: "Do not repeat",
    value: TargetTypes.one_time,
  },
  {
    id: 2,
    option: "Repeat",
    value: TargetTypes.monthly,
  },
];
