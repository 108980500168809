// TODO - lint
/* eslint-disable import/no-default-export */
import React from "react";
import { connect } from "react-redux";
import { attachAgentToInfInCampaign } from "../../../../actions/auctionsActions";
import { UserReducer } from "../../../../reducers/userReducer";
import { AgentSelect } from "../../agency/AgentSelect";
import { MessageDialog } from "../../dialogs/MessageDialog";

interface Props {
  user?: UserReducer;
  isOpen: boolean;
  onClose(): void;
  attachAgentToInfInCampaign(
    auction_id: any,
    influencer_id: any,
    agent_id: any,
    callback: any,
  ): void;
  influencer: {
    id: number;
    name: string;
  };
  auctionId: any;
}

class AttachAgentDialog extends React.Component<Props> {
  state = {
    selectedAgency: -1,
    saving: false,
  };

  saveAgency = () => {
    if (this.state.saving || this.state.selectedAgency < 0) return;
    this.setState({ saving: true });
    this.props.attachAgentToInfInCampaign(
      this.props.auctionId,
      this.props.influencer.id,
      this.state.selectedAgency,
      () => {
        this.props.onClose();
      },
    );
  };

  render() {
    return this.props.isOpen ? (
      <MessageDialog handleClose={this.props.onClose} icon={"fas fa-user-tie"}>
        <div style={{ padding: 20 }}>
          <div style={{ fontSize: 18, marginBottom: 15, marginTop: -10 }}>
            Add Agency to - {this.props.influencer.name}
          </div>
          <strong className="label-title">
            Select Agency <span className="optional-title"></span>{" "}
          </strong>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 200, marginLeft: 5, marginRight: 10 }}>
              <AgentSelect
                value={this.state.selectedAgency}
                onChange={(value: number) =>
                  this.setState({ selectedAgency: value })
                }
              />
            </div>
          </div>
          <button
            className="btn btn-primary"
            disabled={this.state.saving || this.state.selectedAgency < 0}
            onClick={this.saveAgency}
            style={{ marginTop: 25, width: "100%" }}
          >
            Attach to influencer
          </button>
        </div>
      </MessageDialog>
    ) : null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    attachAgentToInfInCampaign: (
      auction_id: any,
      influencer_id: any,
      agent_id: any,
      callback: any,
    ) => {
      dispatch(
        attachAgentToInfInCampaign(
          auction_id,
          influencer_id,
          agent_id,
          callback,
        ),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachAgentDialog);
