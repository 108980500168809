import axios from "axios";
import { toast } from "react-toastify";
import uuidv4 from "uuid/v4";
import { getSelectedChild } from "../components/feed_components/TopUserDetails";
import { listsActions } from "../reducers/listsSlice";
import {
  getBackendUri,
  getHeaders,
  getSavedJwtToken,
  notificationOpts,
} from "./commonActionsConf";

const premiumUrlBase = () => `${getBackendUri()}/moi/premium`;
const boardsUrlBase = () => `${premiumUrlBase()}/boards`;
const listsUrlBase = () => `${premiumUrlBase()}/lists`;

const getBlankUsers = (count) => {
  const influencers = [];
  if (count && count > 0) {
    // eslint-disable-next-line no-param-reassign
    count = count > 500 ? 500 : count;
    for (let i = 0; i < count; i += 1) {
      const data = {
        age: null,
        age_group: null,
        categories: [],
        gender: "unknown",
        instagram_data: {},
        note: null,
        score: null,
        twitter_data: {},
        worked_with: null,
        youtube_data: {},
        name: "Only for premium users",
        id: `generated_${i}`,
        user_registered: true,
        is_spam: true,
        picture_url: "/images/empty-person.svg",
      };
      influencers.push(data);
    }
  }
  return influencers;
};

export const searchInfluencers = (
  searchParams,
  pageId,
  isPremium,
  deepSearch,
  defaultSearch,
  callback,
) => {
  const guid = uuidv4();
  return (dispatch) => {
    dispatch({ type: "SET_SEARCH_GUID", payload: guid });
    const url = `${premiumUrlBase()}/search_influencers`;

    const headers = {
      "X-MoiBackendAuthorization": getSavedJwtToken(),
      "X-No-Search-Depth": deepSearch !== true,
    };

    axios
      .post(url, searchParams, {
        withCredentials: true,
        headers,
        params: {
          scroll_id: pageId,
          default_search: defaultSearch,
        },
      })
      .then((response) => {
        const time = new Date();

        const limits = {
          limit: "searches",
          current: parseInt(response.headers["x-ratelimit-current"], 10),
          max: parseInt(response.headers["x-ratelimit-limit"], 10),
        };
        dispatch({ type: "USER_LIMIT_NUMBERS_UPDATE", payload: limits });

        const searchsCount = parseInt(response.headers["x-totalresults"], 10);
        const nextPageId = response.headers["x-nextscrollid"];
        const result = {};
        result.searchsCount = searchsCount;
        result.time = time;
        result.guid = guid;
        result.nextPageId = nextPageId;
        result.callback = callback;
        result.influencers = response.data ? response.data : [];
        result.inf_num = result.influencers.length;
        result.lastParams = searchParams;
        if (!isPremium) {
          result.influencers = result.influencers.concat(
            getBlankUsers(searchsCount - result.influencers.length),
          );
        }
        if (pageId) {
          dispatch({ type: "GET_MORE_SEARCH_RESULTS", payload: result });
        } else {
          dispatch({ type: "SEARCH_INFLUENCERS_FULFILLED", payload: result });
        }
      })
      .catch((err) => {
        dispatch({ type: "SEARCH_INFLUENCERS_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to get influencers";
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const searchOnList = (listId, searchParams, callback) => {
  return (dispatch) => {
    const url = `${listsUrlBase()}/${listId}/search`;
    axios
      .post(url, searchParams, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({ type: "SET_SEARCH_PARAMETERS", payload: searchParams });
        callback(response);
      })
      .catch((err) => {
        dispatch({ type: "SEARCH_INFLUENCERS_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to get influencers";
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const getInfluencerData = (influencerId, postsFilter, callback) => {
  return (dispatch) => {
    axios
      .post(
        `${premiumUrlBase()}/get_influencer_data/${influencerId}`,
        postsFilter,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        const limits = {
          limit: "segmentation_views",
          current: parseInt(response.headers["x-ratelimit-current"], 10),
          max: parseInt(response.headers["x-ratelimit-limit"], 10),
        };
        dispatch({ type: "USER_LIMIT_NUMBERS_UPDATE", payload: limits });
        if (
          response.data &&
          response.data.influencer_data.influencer_id !== influencerId
        ) {
          dispatch({
            type: "USER_SEARCH_CHANGED_ID",
            payload: {
              old: influencerId,
              new: response.data.influencer_data.influencer_id,
            },
          });
        }
        callback(response.data);
      })
      .catch((err) => {
        console.log(err?.response);
        if (
          err?.response?.status === 412 &&
          err?.response?.data?.error === "already_deleted"
        ) {
          const tmpData = {
            influencer_data: {
              influencer_id: influencerId,
              user_name: "Influencer deleted",
            },
          };
          dispatch({
            type: "USER_SEARCH_CHANGED_ID",
            payload: {
              old: influencerId,
              new: tmpData.influencer_data.influencer_id,
            },
          });
          callback(tmpData);
          return;
        }
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get influencer data",
          "system_notifications_failure_get_influencers_data",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const getListsNew = (archive = true, callback = () => {}) => {
  return (dispatch) => {
    const url = `${getBackendUri()}/moi/premium/lists/users_mapping?archived_lists=${archive}`;
    axios
      .get(url, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: archive
            ? "FETCH_ARCHIVED_LISTS_FULFILLED"
            : "FETCH_LISTS_FULFILLED",
          payload: response.data,
        });
        callback();
      })
      .catch((err) => {
        dispatch({ type: "FETCH_LISTS_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get lists",
          "system_notifications_failure_get_lists_opreation",
        );
        toast.error(notificationOpts.message);
      });
  };
};

export const pushCampaignThinListToListsReducer = (list, callback) => {
  return (dispatch) => {
    dispatch({ type: "CREATE_LIST_FULFILLED", payload: list });
    if (callback !== null && callback !== undefined) {
      callback(list);
    }
  };
};

export const addNewList = (details, callback) => {
  return (dispatch) => {
    axios
      .put(listsUrlBase(), details, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch(listsActions.resetLists());
        dispatch({ type: "CREATE_LIST_FULFILLED", payload: response.data });
        if (callback !== null && callback !== undefined) {
          callback(response.data);
        }
      })
      .catch((err) => {
        dispatch({ type: "CREATE_LIST_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to create list",
          "system_notifications_failure_create_lists_opreation",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const getListById = (id, callback, dataOnly) => {
  return (dispatch) => {
    axios
      .get(`${listsUrlBase()}/${id}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (!dataOnly) {
          dispatch({ type: "FETCH_LIST_FULFILLED", payload: response.data });
        }
        if (callback) {
          callback(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Could not load the list",
          "system_notifications_failure_load_list_opreation",
        );
        toast.error(notificationOpts.message);
      });
  };
};

export const addUsersToList = (
  listId,
  influencers,
  importExtraFromList,
  importContentFromListId,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    const url = `${listsUrlBase()}/${listId}?${
      importExtraFromList
        ? `import_extra_from_list=${importExtraFromList}&`
        : ""
    }${
      importContentFromListId
        ? `import_content_from_list_id=${importContentFromListId}&`
        : ""
    }`;
    axios
      .put(url, influencers, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "ADD_USERS_TO_LIST_FULFILLED",
          payload: response.data,
        });
        toast.success(
          window.translate(
            "The Users added to the list",
            "system_notifications_success_add_to_list_opreation",
          ),
        );
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: "ADD_USERS_TO_LISTS_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to add user to list",
          "system_notifications_failure_add_to_list_opreation",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const addUsersToLists = (array, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .put(`${listsUrlBase()}/add_to_lists`, array, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "ADD_USERS_TO_LISTS_FULFILLED",
          payload: response.data.lists_summary,
        });
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const oldAndNew in response.data.changed_ids) {
          dispatch({
            type: "USER_SEARCH_CHANGED_ID",
            payload: { old: oldAndNew.old_id, new: oldAndNew.new_id },
          });
        }
        notificationOpts.title = window.translate(
          "Success!",
          "system_notifications_success",
        );
        notificationOpts.message = window.translate(
          "The Users added to lists",
          "system_notifications_success_add_to_list_opreation",
        );
        toast.success(notificationOpts.message);
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: "ADD_USERS_TO_LIST_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to add users to lists",
          "system_notifications_failure_add_to_list_opreation",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const deleteList = (listId, callback) => {
  return (dispatch) => {
    axios
      .delete(`${listsUrlBase()}/${listId}`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        const limits = {
          limit: "lists",
          current: parseInt(response.headers["x-ratelimit-current"], 10),
          max: parseInt(response.headers["x-ratelimit-limit"], 10),
        };
        dispatch({ type: "USER_LIMIT_NUMBERS_UPDATE", payload: limits });
        notificationOpts.title = window.translate(
          "Success!",
          "system_notifications_success",
        );
        notificationOpts.message = window.translate(
          "The list has been deleted successfully",
          "system_notifications_success_delete_lists_opreation",
        );
        toast.success(notificationOpts.message);
        dispatch({
          type: "DELETE_LIST_FULFILLED",
          payload: { list_id: listId },
        });
        dispatch(listsActions.removeFromList(listId));
        if (callback) {
          callback(true);
        }
      })
      .catch((err) => {
        dispatch({ type: "DELETE_LIST_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to delete list",
          "system_notifications_failure_delete_lists_opreation",
        );
        toast.error(notificationOpts.message);
        if (callback) {
          callback(false);
        }
      });
  };
};

export const getListFrequencies = (id, callback) => {
  return (dispatch) => {
    dispatch({ type: "SHOW_LIST_FREQUENCIES_LOADER_FULLFILED", payload: true });
    axios
      .get(`${listsUrlBase()}/${id}/frequenices`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "GET_LIST_FREQUENCIES_FULLFILED",
          payload: response.data,
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_LIST_FREQUENCIES_REJECTED", payload: err });
      });
  };
};

export const removeFromList = (listId, influencers, callback) => {
  return (dispatch) => {
    axios
      .put(`${listsUrlBase()}/${listId}/remove_influencers`, influencers, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "DELETE_USER_FROM_LIST_FULFILLED",
          payload: { influencers, list_id: listId },
        });
        dispatch(getListFrequencies(listId));
        dispatch({
          type: "UPDATE_LIST_GENERAL_DETAILS",
          payload: response.data,
        });
        if (callback) {
          callback();
        }
        notificationOpts.title = window.translate(
          "Success!",
          "system_notifications_success",
        );
        notificationOpts.message = window.translate(
          "Influencers removed from the list",
          "system_notifications_success_delete_from_list_opreation",
        );
        toast.success(notificationOpts.message);
      })
      .catch((err) => {
        dispatch({ type: "DELETE_USER_FROM_LIST_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to remove users from list ";
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const hideOrUnhideOnList = (listId, influencers, callback) => {
  return (dispatch) => {
    dispatch({
      type: "SWITCH_HIDE_USER_FROM_LIST_FULFILLED",
      payload: { influencers, list_id: listId },
    });
    axios
      .put(`${listsUrlBase()}/${listId}/switch_hide_influencers`, influencers, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST_GENERAL_DETAILS",
          payload: response.data,
        });
        dispatch(getListFrequencies(listId));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({
          type: "SWITCH_HIDE_USER_FROM_LIST_REJECTED",
          payload: { influencers, list_id: listId },
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to hide/unhide users in list";
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const addNoteOnUser = (listId, influencerId, note, callback) => {
  return (dispatch) => {
    axios
      .post(
        `${listsUrlBase()}/${listId}/${influencerId}/note`,
        { note },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        dispatch({
          type: "ADD_NOTE_ON_USER_FULFILLED",
          payload: {
            influencer_id: influencerId,
            note,
            list_id: listId,
          },
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: "ADD_NOTE_ON_USER_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to save note",
          "system_notifications_failure_save_note_list_opreation",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const acceptInfluencerBid = (boardId, influencerId, bidId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${boardsUrlBase()}/${boardId}/approve_bid/${influencerId}`,
        {
          bid_id: bidId,
          express: false,
        },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        dispatch({
          type: "INFLUENCER_IN_BOARD_DATA_UPDATED_FULLFILLED",
          payload: response.data,
        });
        if (callback !== null && callback !== undefined) {
          callback();
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({
          type: "INFLUENCER_IN_BOARD_DATA_UPDATED_FAILED",
          payload: err,
        });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to view influencer update",
          "system_notifications_failure_response_to_influencer_offer",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        console.error(err);
      });
  };
};

export const changeInfluencerPrice = (
  listId,
  influencerId,
  replaceDefault,
  array,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${listsUrlBase()}/${listId}/${influencerId}/price?replace_default=${
          replaceDefault ? "true" : "false"
        }`,
        array,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        dispatch({
          type: "UPDATE_LIST_GENERAL_DETAILS",
          payload: response.data,
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        dispatch({ type: "ADD_PRICE_ON_USER_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to save prices",
          "system_notifications_failure_lists_price_opreation",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });

        console.error(err);
      });
  };
};

/* TAGGER ROUTES */

export const changeGender = (influencer, gender, callback) => {
  return () => {
    axios
      .post(
        `${getBackendUri()}/moi/change_gender/${influencer.id}`,
        {
          gender,
          registered: influencer.user_registered,
        },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to change gender";
      });
  };
};

export const changeAgeGroup = (influencer, age, callback) => {
  return () => {
    axios
      .post(
        `${getBackendUri()}/moi/change_age_group/${influencer.id}`,
        { age_group: age },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to change age group";
      });
  };
};

export const changeCountry = (influencer, country, callback) => {
  return () => {
    axios
      .post(
        `${getBackendUri()}/moi/change_country/${influencer.id}`,
        { country },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to change country";
      });
  };
};

export const changeCategories = (
  influencer,
  categories,
  socialNetwork,
  callback,
) => {
  return () => {
    axios
      .post(
        `${getBackendUri()}/moi/change_categories/${influencer.id}`,
        {
          social_network: socialNetwork,
          categories,
        },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to change categories";
      });
  };
};

export const setAsPage = (influencer, pageTag, callback) => {
  return () => {
    axios
      .post(
        `${getBackendUri()}/moi/set_as_page/${
          influencer.id
        }?is_page=${pageTag}`,
        null,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = "Failed to set as page";
      });
  };
};

export const changeUserConnection = (
  userId,
  socialType,
  socialTypeFrom,
  newUser,
  callback,
) => {
  return () => {
    axios
      .post(
        `${getBackendUri()}/moi/change_connection/${userId}`,
        {
          social_network_to: socialType,
          social_network_from: socialTypeFrom,
          change_to: newUser,
        },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        if (callback) {
          callback(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          notificationOpts.message = `User not in our db:${newUser}`;
        } else {
          notificationOpts.message = "General failure";
          if (err.response && err.response.data && err.response.data.error) {
            notificationOpts.message = err.response.data.error;
          }
        }
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        toast.error(notificationOpts.message);
        if (callback) {
          callback(false);
        }
      });
  };
};

export const removeSocialConnection = (userId, socialType, callback) => {
  return () => {
    axios
      .post(
        `${getBackendUri()}/moi/remove_social_connection/${userId}`,
        { social_network: socialType },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        console.log(err);

        notificationOpts.message = "General failure";
        if (err.response && err.response.data && err.response.data.error) {
          notificationOpts.message = err.response.data.error;
        }

        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        toast.error(notificationOpts.message);
      });
  };
};

export const editInfluencerNote = (influencerId, note, callback) => {
  return () => {
    axios
      .post(
        `${premiumUrlBase()}/influencer_note/${influencerId}`,
        { note },
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((response) => {
        if (callback) {
          callback(response);
        }
      })
      .catch((err) => {
        console.log(err);

        notificationOpts.message = "General failure";
        if (err.response && err.response.data && err.response.data.error) {
          notificationOpts.message = err.response.data.error;
        }

        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        toast.error(notificationOpts.message);
      });
  };
};

export const getSharedList = (listId, callback) => {
  return () => {
    axios
      .get(`${listsUrlBase()}/${listId}/share`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        callback();
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to get shared list data",
          "system_notifications_failure_lists_roi_get",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const shareList = (listId, sharedData, callback) => {
  return (dispatch) => {
    axios
      .put(`${listsUrlBase()}/${listId}/share`, sharedData, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "SHARE_LIST_FULFILLED",
          payload: {
            list_id: listId,
            share_notes: sharedData.share_comments,
            shared_link: response.data.shared_link,
          },
        });
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        callback();
        dispatch({ type: "SHARE_LIST_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to share list",
          "system_notifications_failure_lists_roi",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const disableShareList = (listId, callback) => {
  return (dispatch) => {
    axios
      .delete(`${listsUrlBase()}/${listId}/share`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        dispatch({
          type: "DISABLE_SHARE_LIST_FULFILLED",
          payload: { list_id: listId },
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        callback();
        dispatch({ type: "DISABLE_SHARE_LIST_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to disable shared list",
          "system_notifications_failure_lists_roi_disable",
        );
        toast.error(notificationOpts.message);
        console.error(err);
      });
  };
};

export const getListSegmentation = (id, callback) => {
  return (dispatch) => {
    dispatch({ type: "SHOW_LIST_FREQUENCIES_LOADER_FULLFILED", payload: true });
    axios
      .get(`${listsUrlBase()}/${id}/get_list_segmentation_data`, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        dispatch({
          type: "GET_LIST_SEGMENTATION_FULLFILED",
          payload: { listId: id, data: response.data },
        });
        if (callback) {
          callback(response.data);
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_LIST_SEGMENTATION_REJECTED", payload: err });
        callback(false);
      });
  };
};

export const editList = (listId, newDetails, callback) => {
  return (dispatch) => {
    axios
      .put(`${listsUrlBase()}/${listId}/edit`, newDetails, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
        dispatch({
          type: "EDIT_LIST_FULFILLED",
          payload: { new_details: newDetails, list_id: listId },
        });
        dispatch(listsActions.resetLists());
        dispatch(getListsNew());
        if (callback) {
          callback();
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        dispatch({ type: "EDIT_LIST_REJECTED", payload: err });
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to edit list",
          "system_notifications_failure_lists_rename",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });

        console.error(err);
      });
  };
};

export const editTasks = (listId, influencerId, tasks, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${listsUrlBase()}/${listId}/modify_task/${influencerId}`, tasks, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to edit tasks",
          "system_notifications_failure_edit_tasks",
        );
        if (callback) {
          callback(false);
        }
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });

        console.error(err);
      });
  };
};

export const editTasksOnCampaign = (
  auctionId,
  influencerId,
  tasks,
  oldMonth,
  callback,
) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${boardsUrlBase()}/${auctionId}/modify_task/${influencerId}`,
        tasks,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
          params: { old_month: oldMonth },
        },
      )
      .then((response) => {
        if (callback) {
          callback(response.data);
        }
        if (response && response.data && response.data.influencer) {
          dispatch({
            type: "INFLUENCER_CHANGED_ON_BOARD",
            payload: response.data.influencer,
          });
        }
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch((err) => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to edit tasks",
          "system_notifications_failure_edit_tasks",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) callback(false);
        console.error(err);
      });
  };
};

export const markTaskInvitesAsSeen = (auctionId, influencerId, callback) => {
  return (dispatch) => {
    axios
      .post(
        `${boardsUrlBase()}/${auctionId}/seen_approval_tasks/${influencerId}`,
        null,
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        dispatch({
          type: "INFLUENCER_INVITES_TASKS_SEEN",
          payload: influencerId,
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const getShareCampaignData = (id, callback) => {
  axios
    .get(`${premiumUrlBase()}/auction/${id}/share`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((response) => {
      if (response.data) callback(response.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const shareCampaign = (id, data, callback) => {
  axios
    .put(`${premiumUrlBase()}/auction/${id}/share`, data, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((response) => {
      if (callback && response.data) {
        callback(response.data);
      } else {
        callback(false);
      }
    })
    .catch(() => {
      callback(false);
    });
};

export const filterCities = (cityName, callback) => {
  axios
    .post(
      `${getBackendUri()}/moi/city_search`,
      { name: cityName },
      {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      },
    )
    .then((response) => {
      if (callback) {
        callback(response.data);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const archiveList = (id, toArchive, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${listsUrlBase()}/${id}/archive`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then(() => {
        dispatch({
          type: "ARCHIVE_LIST_FULLFILLED",
          payload: { toArchive, id },
        });
        dispatch(listsActions.removeFromList(id));
        if (callback) callback(true);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to archive list",
          "system_notifications_failure_archive_list",
        );
        toast.error(notificationOpts.message);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        if (callback) callback(false);
      });
  };
};

export const getSavedQueries = (callback = () => {}) => {
  axios
    .get(`${getBackendUri()}/moi/advertiser_saved_queries`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      callback(r.data);
    });
};

export const saveNewQuery = (data, callback = () => {}) => {
  axios
    .put(
      `${getBackendUri()}/moi/advertiser_saved_queries`,
      { data },
      {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      },
    )
    .then((r) => {
      callback(r.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const removeSavedQuery = (id, callback = () => {}) => {
  axios
    .delete(`${getBackendUri()}/moi/advertiser_saved_queries/${id}`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then(() => {
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

export const editListKpis = (id = -1, kpis = {}, callback = () => {}) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(`${listsUrlBase()}/${id}/edit_lists_tasks`, kpis, {
        withCredentials: true,
        headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      })
      .then(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
        dispatch(
          getListById(id, () => {
            callback();
            dispatch(getListFrequencies(id));
          }),
        );
      })
      .catch(() => {
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to edit list KPIS",
          "system_notifications_failure_archive_list",
        );
        toast.error(notificationOpts.message);
        callback(false);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const addListTag = (id, tagData, callback) => {
  axios
    .post(`${listsUrlBase()}/${id}/tag`, tagData, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((response) => {
      callback(response.data);
    })
    .catch(() => callback(false));
};

export const editTagInList = (listId, tagId, tagData, callback) => {
  axios
    .put(`${listsUrlBase()}/${listId}/tag/${tagId}`, tagData, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then(() => {
      callback(true);
    })
    .catch(() => callback(false));
};

export const deleteTagInList = (listId, tagId, callback) => {
  axios
    .delete(`${listsUrlBase()}/${listId}/tag/${tagId}`, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then(() => {
      callback(true);
    })
    .catch(() => callback(false));
};
export const toggleListFavorite = (listId, callback) => {
  return (dispatch) => {
    dispatch({ type: "ACTIVE_GENERAL_LOADER", payload: true });
    axios
      .post(
        `${listsUrlBase()}/${listId}/favorite`,
        {},
        {
          withCredentials: true,
          headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
        },
      )
      .then((payload) => {
        dispatch({
          type: "LIST_FAVORITE_TOGGLE",
          payload: {
            list_id: listId,
            is_favorite: payload.request.response === "true",
          },
        });
        callback(true);
      })
      .catch(() => callback(false))
      .finally(() => {
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getListsScrolling = (
  archive,
  search,
  favorites,
  lastSeenTime,
  tags,
  callback = () => {},
) => {
  let url = `${getBackendUri()}/moi/premium/lists_with_scrolling/?archived_lists=${!!archive}`;
  const childId = getSelectedChild();
  if (search) {
    url += `&filter=${search}`;
  }
  if (favorites) {
    url += `&only_favorite_lists=${!!favorites}`;
  }
  if (lastSeenTime) {
    url += `&last_seen_time=${lastSeenTime}`;
  }
  if (childId) {
    url += `&child_id=${childId}`;
  }
  const params = {};
  if (tags?.length) {
    params.tags = tags.map((x) => x.id).join(",");
  }
  axios
    .get(url, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
      params,
    })
    .then((response) => {
      callback(response.data);
    })
    .catch(() => {
      callback([]);
    });
};

export const getInfluencerRating = (infId, lastTime, callback) => {
  let url = `${getBackendUri()}/moi/reviews/${infId}`;
  if (lastTime) {
    url += `?last_seen_time=${lastTime}`;
  }
  axios
    .get(url, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      callback(r.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const searchContents = (listId, data, callback) => {
  axios
    .post(`${listsUrlBase()}/${listId}/search_content`, data, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then((r) => {
      callback(r.data);
    })
    .catch(() => {
      callback(false);
    });
};

export const attachContentToInfInList = (
  listId,
  influencerId,
  data,
  callback,
) => {
  axios
    .post(`${listsUrlBase()}/${listId}/posts_link/${influencerId}`, data, {
      withCredentials: true,
      headers: { "X-MoiBackendAuthorization": getSavedJwtToken() },
    })
    .then(() => {
      callback(true);
    })
    .catch(() => {
      callback(false);
    });
};

// todo: rename
export const getListShareNotes = (shareGuid, itemType, itemId, callback) => {
  return (dispatch) => {
    axios
      .get(
        `${getBackendUri()}/moi/shares/${shareGuid}/notes/${itemType}/notes/${itemId}`,
        getHeaders(),
      )
      .then((response) => {
        if (callback) callback(response.data);
        dispatch({ type: "GET_SHARE_NOTES_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (callback) callback(null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const addShareNotes = (
  shareGuid,
  itemType,
  itemId,
  message,
  callback,
) => {
  return (dispatch) => {
    axios
      .post(
        `${getBackendUri()}/moi/shares/${shareGuid}/notes/${itemType}/notes/${itemId}`,
        message,
        getHeaders(),
      )
      .then((response) => {
        if (callback) callback(response.data);
        dispatch({ type: "ADD_SHARE_NOTES_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (callback) callback(null);
        dispatch({ type: "DEACTIVE_GENERAL_LOADER", payload: true });
      });
  };
};

export const getShareNotesSummary = (shareGuid, callback) => {
  axios
    .get(`${getBackendUri()}/moi/shares/${shareGuid}/notes/`, getHeaders())
    .then((response) => {
      if (callback) callback(response.data);
    })
    .catch((err) => {
      console.log(err);
      if (callback) callback(null);
    });
};

export const changeListOrder = (listId, data, callback) => {
  return () => {
    axios
      .post(`${listsUrlBase()}/${listId}/influencers_order`, data, getHeaders())
      .then(() => {
        callback(true);
      })
      .catch(() => {
        callback(false);
        notificationOpts.title = window.translate(
          "Failed",
          "system_notifications_failure",
        );
        notificationOpts.message = window.translate(
          "Failed to edit list order",
          "system_notifications_failure_change_list_order",
        );
        toast.error(notificationOpts.message);
      });
  };
};
