import { Alert, Box, Text } from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import produce from "immer";
import { WritableDraft } from "immer/dist/internal";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DatePicker from "react-datepicker";
import {
  get_post_type_icon,
  get_post_type_label,
} from "src/components/feed_components/utilities/providers";
import { RequiredBy } from "src/declarations/UtilityTypes";
import { usePrevious } from "src/hooks/coreHooks";
import { useContinuePrompt } from "src/hooks/promt/useContinuePrompt";
import { PostSubTypes, PostTypes, Task } from "src/models/Api";
import HumanzRangePicker from "src/ui/humanz/HumanzRangePicker";
import { MessageDialog } from "../../../../dialogs/MessageDialog";
import AccordionUI from "../../FormUI/AccordionUI";
import FormCard from "../../FormUI/FormCard";
import Indicator from "../../FormUI/Indicator";
import AddTasks from "./AddTasks";
import { BriefTaskRow } from "./BriefTaskRow";
import BriefTasksPayments from "./BriefTasksPayments";

type TaskStage = React.ComponentProps<typeof BriefTaskRow>["data"];

type Props = {
  defaultData: {
    one_month_campaign?: boolean;
    campaign_dates: {
      start?: Moment;
      end?: Moment;
    };
    task_stages: TaskStage[];
    tasks_payments_instructions: {
      split_payments: boolean;
      tasks_renews: boolean;
      complete_at_end_of_month: boolean;
    };
  };
  onDataChange: (data: Props["defaultData"]) => void;
  activeBidsCount: number;
  isShare: boolean;
  isSurvey: boolean;
  editMode: boolean;
  showErrorsInitially: boolean;
  onSubmitForm: () => void;
  onNextClick: () => void;
  onBackClick: () => void;
  onValidChange?: (isValid: boolean) => void;
  showValidationErrorsRef?: React.MutableRefObject<(() => void) | undefined>;
  titleButton?: React.ReactNode;
  children?: any;
  pixelEnabled: boolean;
};

const validateTabTasksData = ({
  data,
  defaultData,
  isSurvey,
}: {
  data: Props["defaultData"];
  defaultData: Props["defaultData"];
  isSurvey: Props["isSurvey"];
}) => {
  const errors: RequiredBy<
    FormikErrors<typeof data>,
    "campaign_dates" | any
  > = {
    campaign_dates: {},
  };

  if (!data.campaign_dates.start) {
    errors.campaign_dates.start = "Must fill date";
  } else if (
    !data.campaign_dates.start.isSame(
      defaultData.campaign_dates.start?.startOf("day"),
    ) &&
    data.campaign_dates.start < moment().startOf("day")
  ) {
    errors.campaign_dates.start = "Invalid Date";
  }

  if (!data.campaign_dates.end) {
    errors.campaign_dates.end = "Must fill date";
  } else if (
    !data.campaign_dates.end.isSame(
      defaultData.campaign_dates.end?.endOf("day"),
    ) &&
    data.campaign_dates.end <
      (data.campaign_dates.start ?? moment().startOf("day"))
  ) {
    errors.campaign_dates.end = "Invalid Date";
  }

  const taskErrors = data.task_stages.map((task, i) => {
    const defaultTask: any = defaultData.task_stages[i] ?? {};
    const taskObjErrors = { content_due_date: "", due_date: "" };

    if (
      task.content_due_date &&
      !task.content_due_date.isSame(
        defaultTask.content_due_date?.startOf("day"),
      ) &&
      ((data.campaign_dates.start &&
        task.content_due_date < data.campaign_dates.start.startOf("day")) ||
        (data.campaign_dates.end &&
          task.content_due_date > data.campaign_dates.end) ||
        (task.due_date && task.content_due_date > task.due_date) ||
        task.content_due_date < moment().startOf("day"))
    ) {
      taskObjErrors.content_due_date = "Invalid date";
    }

    if (
      task.due_date &&
      !task.due_date.isSame(defaultTask.due_date?.endOf("day")) &&
      ((data.campaign_dates.end && task.due_date > data.campaign_dates.end) ||
        (data.campaign_dates.start &&
          task.due_date < data.campaign_dates.start) ||
        (task.content_due_date && task.content_due_date > task.due_date) ||
        task.due_date < moment().startOf("day"))
    ) {
      taskObjErrors.due_date = "Invalid date";
    }

    return taskObjErrors;
  });
  const notAllTaskHasDates = taskErrors.find(
    (t) => t.content_due_date || t.due_date,
  );
  if (notAllTaskHasDates) {
    errors.task_stages = taskErrors;
  }
  if (data.task_stages.length === 0 && !isSurvey) {
    errors.task_stages = "You have to add at least one task";
  }
  if (
    Object.keys(errors.campaign_dates).length === 0 &&
    errors.task_stages === undefined
  ) {
    return {};
  }
  return errors;
};

export const isTabTasksDataValid: (params: {
  data: Props["defaultData"];
  isSurvey: Props["isSurvey"];
}) => boolean = (params) =>
  Object.keys(
    validateTabTasksData({
      data: params.data,
      defaultData: params.data,
      isSurvey: params.isSurvey,
    }),
  ).length === 0;

export const BriefTasks: React.FC<Props> = ({
  defaultData: defaultDataProp,
  isSurvey,
  editMode,
  isShare,
  titleButton,
  activeBidsCount,
  showValidationErrorsRef,
  showErrorsInitially,
  onSubmitForm,
  onDataChange,
  onValidChange,
  children,
  pixelEnabled,
}) => {
  const defaultData = useRef(defaultDataProp).current;
  const continuePrompt = useContinuePrompt();
  const handleSubmitValid = useCallback(
    async (values: Props["defaultData"]) => {
      if (editMode) {
        const taskStagesToSave = values.task_stages.filter(
          (t) => !(t.new && t.deleted),
        );

        const numberOfAddedTasks = taskStagesToSave.filter((t) => t.new).length;
        const numberOfModifiedTasks = taskStagesToSave.filter(
          (t) => t.changed && !t.new && !t.deleted,
        ).length;
        const numberOfDeletedTasks = taskStagesToSave.filter(
          (t) => t.deleted,
        ).length;

        if (
          numberOfAddedTasks + numberOfModifiedTasks + numberOfDeletedTasks !==
          0
        ) {
          const answer = await continuePrompt.askToContinue({
            titleIconClassName: "fal fa-pencil-alt",
            yesText: "Save",
            text: (
              <>
                {numberOfDeletedTasks !== 0 ? (
                  <Text>You deleted {numberOfDeletedTasks} tasks</Text>
                ) : null}
                {numberOfAddedTasks !== 0 ? (
                  <Text>You added {numberOfAddedTasks} tasks</Text>
                ) : null}
                {numberOfModifiedTasks !== 0 ? (
                  <Text>You changed {numberOfModifiedTasks} tasks</Text>
                ) : null}
              </>
            ),
          });
          if (!answer) {
            return;
          }
        }
        onSubmitForm();
      }
    },
    [continuePrompt, editMode, onSubmitForm],
  );

  const handleValidate = useCallback(
    (values: Props["defaultData"]) =>
      validateTabTasksData({ data: values, defaultData, isSurvey }),
    [defaultData, isSurvey],
  );

  const formik = useFormik<typeof defaultData>({
    initialValues: defaultData,
    isInitialValid: isTabTasksDataValid({ data: defaultData, isSurvey }),
    initialTouched: {
      campaign_dates: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        start: !showErrorsInitially,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        end: !showErrorsInitially,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      task_stages: defaultData.task_stages.map(() => ({
        content_due_date: !showErrorsInitially,
        due_date: !showErrorsInitially,
      })),
    },
    initialErrors: validateTabTasksData({
      data: defaultData,
      defaultData,
      isSurvey,
    }),
    validate: handleValidate,
    onSubmit: handleSubmitValid,
  });

  const isValid = Object.keys(formik.errors).length === 0;
  const previousIsValid = usePrevious(isValid);
  useEffect(() => {
    if (
      onValidChange &&
      previousIsValid !== undefined &&
      isValid !== previousIsValid
    ) {
      onValidChange(isValid);
    }
  }, [isValid, onValidChange, previousIsValid]);

  useEffect(() => {
    if (showValidationErrorsRef) {
      // This is how you update a react ref
      // eslint-disable-next-line no-param-reassign
      showValidationErrorsRef.current = () => {
        formik.validateForm();
        formik.setTouched({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          campaign_dates: { start: false, end: false },
          task_stages: undefined,
        });
      };

      return () => {
        // This is how you update a react ref
        // eslint-disable-next-line no-param-reassign
        showValidationErrorsRef.current = undefined;
      };
    }
    return undefined;
  }, [formik, showValidationErrorsRef]);

  const setData = useCallback(
    (newData: Props["defaultData"]) => {
      formik.setValues(newData);
      onDataChange(newData);
    },
    [formik, onDataChange],
  );
  const [bidsWarningTaskToAdd, setBidsWarningTaskToAdd] = useState(
    null as Task["post_type"],
  );

  const [datepickerOpened, setDatepickerOpened] = useState(false as boolean);

  const handleAddTask = useCallback<
    (checkBids: boolean, type?: Task["post_type"]) => void
  >(
    (checkBids, type = PostTypes.IgPost) => {
      if (checkBids) {
        if (editMode && activeBidsCount > 0) {
          setBidsWarningTaskToAdd(type);
          return;
        }
      }
      const newTask: WritableDraft<TaskStage> = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        post_type: type,
        post_sub_type:
          type === PostTypes.IgPost ? PostSubTypes.Post : undefined,
        references: [],
        quantity: 1,
        tags: [],
        description: "",
        due_date: undefined,
        content_due_date: undefined,
        new: true,
      };
      const newData = produce(formik.values, (draftValues: any) => {
        draftValues.task_stages.push(newTask);
      });

      setData(newData);

      formik.setFieldTouched(
        `task_stages[${formik.values.task_stages.length}].due_date`,
        true,
        false,
      );
      formik.setFieldTouched(
        `task_stages[${formik.values.task_stages.length}].content_due_date`,
        true,
        false,
      );
    },
    [activeBidsCount, editMode, formik, setData, setBidsWarningTaskToAdd],
  );

  const handleDuplicateTask = useCallback<(task: TaskStage) => void>(
    (task) => {
      const newData = produce(
        formik.values,
        (draftValues: WritableDraft<any>) => {
          draftValues.task_stages.push({
            ...task,
            id: null,
            new: true,
            changed: false,
            deleted: false,
          });
        },
      );

      setData(newData);

      formik.setFieldTouched(
        `task_stages[${formik.values.task_stages.length}].due_date`,
        true,
        false,
      );
      formik.setFieldTouched(
        `task_stages[${formik.values.task_stages.length}].content_due_date`,
        true,
        false,
      );
    },
    [formik, setData],
  );

  const handleDeleteTask = useCallback<(index: number) => void>(
    (index) => {
      const newData = produce(formik.values, (draftValues: any) => {
        if (editMode) {
          draftValues.task_stages[index].deleted = true;
        } else {
          draftValues.task_stages.splice(index, 1);
        }
      });
      setData(newData);
    },
    [editMode, formik.values, setData],
  );

  const handleTaskChange = useCallback<
    (newTask: TaskStage, index: number) => void
  >(
    (newTask, index) => {
      const newData = produce(formik.values, (draftData: any) => {
        draftData.task_stages[index] = newTask;
      });
      setData(newData);
    },
    [formik, setData],
  );

  const handleCampaignStartChange = useCallback(
    (date: Moment) => {
      const newData = produce(formik.values, (draftData: any) => {
        draftData.campaign_dates.start = date?.startOf("day") ?? undefined;
      });
      setData(newData);
    },
    [formik.values, setData],
  );

  const handleCampaignTasksPaymentsInstructionsChange = useCallback(
    (field: string, val: boolean) => {
      const newData = produce(formik.values, (draftData: any) => {
        draftData.tasks_payments_instructions[field] = val;
      });
      setData(newData);
    },
    [formik.values, setData],
  );

  const handleCampaignEndChange = useCallback(
    (date: Moment) => {
      const newData = produce(formik.values, (draftData: any) => {
        draftData.campaign_dates.end = date?.endOf("day") ?? undefined;
      });
      setData(newData);
    },
    [formik.values, setData],
  );

  const handleCampaignStartEndChange = useCallback(
    (startDate: Moment, endDate: Moment) => {
      const newData = produce(formik.values, (draftData) => {
        draftData.campaign_dates.start = startDate?.startOf("day") ?? undefined;
        draftData.campaign_dates.end = endDate?.endOf("day") ?? undefined;
      });
      setData(newData);
    },
    [formik.values, setData],
  );

  return (
    <>
      <form
        data-testid="create-campaign-section-tab-tasks-form"
        onSubmit={(e) => {
          formik.handleSubmit(e);
          formik.setTouched({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            campaign_dates: { start: false, end: false },
            task_stages: undefined,
          });
        }}
      >
        <FormCard>
          <div className="taks-section">
            <div className="create-campaign-title">
              <span style={{ fontSize: 24 }}>Tasks</span>
              <div>{titleButton}</div>
            </div>
            <span
              style={{
                fontSize: "14px",
                color: "#95979D",
                fontWeight: 400,
                lineHeight: "24px",
                marginTop: "20px",
              }}
            >
              Add campaign social media tasks for the influencers.
            </span>

            <AccordionUI header="Campaign Dates">
              <div>
                <Indicator>
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      color: "#222325",
                      marginBottom: 10,
                    }}
                  >
                    Pick campaign dates
                  </span>
                  {pixelEnabled && (
                    <div className={"alert-info"} style={{ margin: "0px" }}>
                      <i className="fa fa-info-circle" />
                      &nbsp; The dates pulled from the sales dates under
                      &quot;Sales&quot; Tab
                    </div>
                  )}
                  <div
                    style={{ display: "flex", marginTop: 8 }}
                    className={pixelEnabled && "disabled"}
                  >
                    <div>
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            width: 210,
                          }}
                          onClick={() => {
                            setDatepickerOpened(true);
                          }}
                        >
                          <span className="datepicker-subtitle">Start</span>
                          <div className="datepicker-box">
                            <DatePicker
                              isClearable={false}
                              readOnly={true}
                              placeholderText="DD/MM/YY"
                              dateFormat={window.time.short_date}
                              selected={
                                formik.values.campaign_dates
                                  .start as React.ComponentProps<
                                  typeof DatePicker
                                >["selected"]
                              }
                              minDate={moment()}
                              maxDate={
                                formik.values.campaign_dates
                                  .end as React.ComponentProps<
                                  typeof DatePicker
                                >["maxDate"]
                              }
                              autoComplete="off"
                              onBlur={() => {
                                formik.setFieldTouched(
                                  "campaign_dates.start",
                                  true,
                                  false,
                                );
                              }}
                              onChange={handleCampaignStartChange}
                              className="borderless-datepicker"
                              disabled={isShare}
                            />
                            <i
                              className="fa-duotone fa-calendar-days fa-lg"
                              style={{ color: "grey" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      {!formik.touched?.campaign_dates?.start &&
                      formik.errors?.campaign_dates?.start ? (
                        <div>
                          <i className="error-mark" />
                          <span className="error-text">
                            {/* eslint-disable @typescript-eslint/ban-ts-comment */
                            /* @ts-ignore */}
                            {formik.errors.campaign_dates.start}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <i />
                          <span className="error-text">&nbsp;</span>
                        </div>
                      )}
                    </div>

                    <div style={{ marginLeft: 16, position: "relative" }}>
                      <div>
                        <div
                          onClick={() => {
                            setDatepickerOpened(true);
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              color: "#808080",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            End
                          </span>
                          <div
                            className="datepicker-box"
                            style={{
                              display: "flex",
                              background: "white",
                              padding: 5,
                              border: "1px solid rgb(234, 232, 232)",
                              borderRadius: "6px",
                              alignItems: "center",
                              width: "210px",
                            }}
                          >
                            <DatePicker
                              readOnly={true}
                              isClearable={false}
                              placeholderText="DD/MM/YY"
                              dateFormat={window.time.short_date}
                              selected={
                                formik.values.campaign_dates
                                  .end as React.ComponentProps<
                                  typeof DatePicker
                                >["selected"]
                              }
                              minDate={
                                formik.values.campaign_dates.start ?? moment()
                              }
                              autoComplete="off"
                              onChange={handleCampaignEndChange}
                              onBlur={() =>
                                formik.setFieldTouched(
                                  "campaign_dates.end",
                                  true,
                                  false,
                                )
                              }
                              className="borderless-datepicker"
                              disabled={isShare}
                            />

                            <i
                              className="fa-duotone fa-calendar-days fa-lg"
                              style={{ color: "grey" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      {!formik.touched?.campaign_dates?.end &&
                      formik.errors?.campaign_dates?.end ? (
                        <div>
                          <i className="error-mark" />
                          <span className="error-text">
                            {/* eslint-disable @typescript-eslint/ban-ts-comment */
                            /* @ts-ignore */}
                            {formik.errors.campaign_dates.end}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <i />
                          <span className="error-text">&nbsp;</span>
                        </div>
                      )}
                    </div>

                    <HumanzRangePicker
                      buttonStyle={{
                        placeSelf: "center",
                        marginLeft: 45,
                        marginTop: -11,
                      }}
                      open={datepickerOpened}
                      onClose={() => setDatepickerOpened(false)}
                      buttonText={"Select range"}
                      onChange={(res: any) => {
                        if (res && res.startDate && res.endDate) {
                          handleCampaignStartEndChange(
                            moment(res.startDate),
                            moment(res.endDate).endOf("day"),
                          );
                        }
                      }}
                      startDate={moment(
                        formik?.values?.campaign_dates?.start
                          ? formik.values.campaign_dates.start
                          : Date.now(),
                      ).toDate()}
                      endDate={moment(
                        formik?.values?.campaign_dates?.end
                          ? formik.values.campaign_dates.end
                          : moment().add(30, "days"),
                      ).toDate()}
                    />
                  </div>
                </Indicator>
                <span className="optional-title">
                  Important: These dates refer only to tasks and bids dates- No
                  relation to sales tracking
                </span>
              </div>
            </AccordionUI>
            {!isSurvey && (
              <Box className={"tasks-container"}>
                <BriefTasksPayments
                  oneMonthCampaign={defaultData.one_month_campaign}
                  repeatEveryMonth={
                    formik.values?.tasks_payments_instructions?.tasks_renews
                  }
                  splitPayment={
                    formik.values.tasks_payments_instructions.split_payments
                  }
                  repeatEveryMonthChange={(val: any) => {
                    handleCampaignTasksPaymentsInstructionsChange(
                      "tasks_renews",
                      val.value,
                    );
                  }}
                  splitPaymentChange={(val: any) => {
                    handleCampaignTasksPaymentsInstructionsChange(
                      "split_payments",
                      val.value,
                    );
                  }}
                  completeEndMonth={
                    formik.values.tasks_payments_instructions
                      .complete_at_end_of_month
                  }
                  completeEndMonthChange={(val: any) => {
                    handleCampaignTasksPaymentsInstructionsChange(
                      "complete_at_end_of_month",
                      val.value,
                    );
                  }}
                />
                {formik.values.task_stages
                  .map((taskStage, index) => ({
                    data: taskStage,
                    index,
                  }))
                  .filter((t) => !t.data.deleted)
                  .map((t) => (
                    <AccordionUI
                      header={
                        <>
                          <i
                            className={`${get_post_type_icon(
                              t.data.post_type,
                            )} fa-lg colored`}
                          />{" "}
                          {get_post_type_label(t.data.post_type).label}
                        </>
                      }
                      key={`tasks${t.index}`}
                      onDelete={() => handleDeleteTask(t.index)}
                      onDuplicate={() => handleDuplicateTask(t.data)}
                      open={t.index === formik.values.task_stages.length - 1}
                      isShare={isShare}
                    >
                      <BriefTaskRow
                        key={`tasks${t.index}`}
                        data={t.data}
                        onDataChange={(newTask) => {
                          handleTaskChange(newTask, t.index);
                        }}
                        onDelete={() => handleDeleteTask(t.index)}
                        onDuplicate={() => handleDuplicateTask(t.data)}
                        campaign_dates={formik.values.campaign_dates}
                        editMode={editMode}
                        isShare={isShare}
                        onDataTouched={(touchedKey) => {
                          formik.setFieldTouched(
                            `task_stages[${t.index}].${touchedKey}`,
                            true,
                            false,
                          );
                        }}
                        errors={
                          Array.isArray(formik.errors.task_stages)
                            ? (
                                formik.errors
                                  .task_stages as FormikErrors<TaskStage>[]
                              )[t.index]
                            : {}
                        }
                        touched={formik.touched.task_stages?.[t.index] ?? {}}
                      />
                    </AccordionUI>
                  ))}
                {!isShare && (
                  <AddTasks formik={formik} handleAddTask={handleAddTask} />
                )}
              </Box>
            )}
          </div>
          {editMode && !!formik.values.task_stages.length && (
            <Alert
              status="warning"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                padding: "25px 20px",
                borderRadius: "12px",
              }}
            >
              <Box
                className="fa-regular fa-circle-exclamation fa-xl"
                sx={{ color: "#FFC107" }}
              />
              <Text sx={{ maxWidth: "87%", color: "#856404" }}>
                Note: Changes made after saving will not be reflected on
                existing influencers' tasks. Editing existing influencers' tasks
                can be done from the campaign “manage” tab, individually or in
                bulk.
              </Text>
            </Alert>
          )}
          {children}
        </FormCard>
      </form>
      {bidsWarningTaskToAdd ? (
        <MessageDialog
          handleClose={() => setBidsWarningTaskToAdd(null)}
          icon="moi-trash"
        >
          <div style={{ textAlign: "center", padding: 20 }}>
            <strong style={{ fontSize: 16 }}>Notice</strong>
            <p style={{ marginTop: 15, fontSize: 16 }}>
              Adding a new task while your campaign is running will{" "}
              <span className="boldText">cancel</span> your current{" "}
              <span className="boldText">{activeBidsCount} </span> active bid
              {activeBidsCount > 1 ? "s" : ""}.<br></br> The influencer
              {activeBidsCount > 1 ? "s" : ""} will need to accept the changes.
            </p>
            <button
              onClick={() => {
                handleAddTask(false, bidsWarningTaskToAdd);
                setBidsWarningTaskToAdd(null);
              }}
              className="btn btn-primry"
              style={{
                background: "var(--main-baby-blue-color)",
                color: "white",
                borderRadius: "40px",
                width: 100,
                marginTop: 15,
              }}
            >
              OK
            </button>
          </div>
        </MessageDialog>
      ) : null}
    </>
  );
};
