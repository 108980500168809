import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FACEBOOK_CONFIG } from "src/actions/commonActionsConf";
import { searchIGUser } from "src/actions/shareActions";
export const FacebookButton = ({
  tokenCallback,
  initialUsername,
  agentId,
}: {
  tokenCallback(token: string, profileId: string): void;
  initialUsername?: string;
  agentId?: number;
}) => {
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [username, setUsername] = useState(initialUsername || "");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const [instagramTutorial] = useState(
    window.location.href.includes("ig_tutorial"),
  );
  const [userNameChanged, setUserNameChanged] = useState(true);

  const handleCallback = useCallback((success?: string) => {
    if (success) {
      tokenCallback(success, sessionStorage.getItem("ig_user_id") as string);
    }
  }, []);

  useEffect(() => {
    const url = new URL(
      window.location.href.replace("?#", "?").replace("#", "?"),
    );
    const urlParams = new URLSearchParams(url.search);

    const token = urlParams.get("access_token");
    if (token) {
      window.history.replaceState("", document.title, window.location.pathname);
      handleCallback(token);
    }
  }, []);

  const handleContinueWith = async () => {
    const scopes = FACEBOOK_CONFIG.scopes;
    const redirectUri = window.location.href.includes("talent-manager")
      ? FACEBOOK_CONFIG.agentsRedirectUri
      : FACEBOOK_CONFIG.redirectUri;
    const loginURL = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${
      FACEBOOK_CONFIG.appId
    }&redirect_uri=${redirectUri}&scope=${scopes}&response_type=token${
      instagramTutorial
        ? '&extras={"setup":{"channel":"IG_API_ONBOARDING"}}'
        : ""
    }`;
    // eslint-disable-next-line prefer-template

    window.location.href = loginURL;
  };

  const searchAndContinue = () => {
    if (!userNameChanged) {
      handleContinueWith();
    }
    if (loading) return;
    if (username?.trim()) {
      setLoading(true);
      setShowError(false);
      searchIGUser(username, agentId)
        .then((res) => {
          setLoading(false);
          if (res.data?.id) {
            sessionStorage.setItem("ig_user_id", res.data.id);
            setUserNameChanged(false);
            document.getElementById("hfbi_btn").click();
          }
        })
        .catch(() => {
          setLoading(false);
          setShowError(true);
        });
    }
  };

  return (
    <>
      <div style={{ textAlign: "center", marginTop: 40 }}>
        <Modal
          size="xl"
          isOpen={showUsernameModal}
          onClose={() => setShowUsernameModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Enter your Instagram username</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box mt={1}>
                <Text fontSize="lg">
                  In order to connect your Instagram account, please enter your
                  Instagram username.
                </Text>
              </Box>
              <Input
                onChange={(e) => {
                  setUsername(e.target.value);
                  setUserNameChanged(true);
                }}
                value={username}
                marginTop={"20px"}
                marginBottom={"20px"}
                placeholder="Instagram username"
                fontSize={"xl"}
              />
              {showError ? (
                <Text color={"red"}>
                  User not found or the user it not a business or a creator
                  account. Please verify your details and make sure to become a
                  business or creator account. Learn more{" "}
                  <a
                    href="https://www.facebook.com/help/instagram/502981923235522"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                </Text>
              ) : null}
              <Box textAlign={"center"} mb="15px" mt={"10px"}>
                <Button
                  fontSize={"large"}
                  padding={"15px"}
                  alignSelf={"center"}
                  className="fb-login-button"
                  background="#4267b2"
                  onClick={searchAndContinue}
                  isLoading={loading}
                  margin="auto"
                >
                  <i
                    className="fa fa-facebook-official"
                    style={{ marginRight: 15 }}
                  />
                  Continue with Facebook
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Button
          fontSize={"xl"}
          padding={"20px"}
          className="fb-login-button"
          background="#4267b2"
          onClick={() => setShowUsernameModal(true)}
        >
          <i className="fa fa-facebook-official" style={{ marginRight: 15 }} />
          Continue with Facebook
          <button
            id="hfbi_btn"
            style={{ background: "transparent", border: 0 }}
            onClick={handleContinueWith}
          />
        </Button>
      </div>
    </>
  );
};
